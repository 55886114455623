import { makeStyles } from "@material-ui/core"
import { Arrays } from "@rithe/utils"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import appConfig from "../../configs/appConfig"
import { applicationActions, useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { Scrollbar } from "../Scrollbar/Scrollbar"
import { NoticeHistoryItem } from "./NoticeHistoryItem"

export function NoticeHistory() {
    const notificationHistories = useApplicationSelector(state => state.ui.notificationHistories)
    const dispatch = useDispatch()
    useLayoutEffect(() => {
        if (notificationHistories.length > appConfig.maxNotificationHistorySize) {
            dispatch(applicationActions.pruningNotificationHistories(appConfig.maxNotificationHistorySize))
        }
    }, [dispatch, notificationHistories.length])

    const styles = useStyles()
    return <div className={styles.root}>
        <Scrollbar className={styles.body}>
            {Arrays.reverse(notificationHistories).map(history => {
                return <NoticeHistoryItem key={history.id!} notification={history} />
            })}
        </Scrollbar>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        height: 476,
        overflow: 'hidden',
    },
    body: {
        width: 480,
        height: 476,
        backgroundColor: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(10px)',
    },
}))