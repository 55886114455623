import { Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { Skeleton } from "@material-ui/lab"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Turnstile from "react-turnstile"
import appConfig from "../../configs/appConfig"
import { forgetPasswordActions } from "./forgetPasswordSlice"
import { SendButtonCF } from "./SendButtonCF"
import { UsernameInput } from "./UsernameInput"

export function ForgetFormCF() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const styles = useStyles()
  return <form className={styles.form} onSubmit={(e) => {
    dispatch(forgetPasswordActions.validate())
    setTimeout(() => {
      dispatch(forgetPasswordActions.send())
    }, 60)
    e.preventDefault()
  }}>
    <Grid container alignItems="center">
      <Grid item style={{ marginLeft: -16, marginRight: 16 }}>
        <IconButton onClick={() => navigate('/login')}>
          <ArrowBack color="primary" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="h5" style={{ textTransform: 'uppercase', lineHeight: '18px' }}>
          <FormattedMessage id="forgetPassword.forget" />
        </Typography>
      </Grid>
    </Grid>
    <Divider className={styles.divider} />
    <UsernameInput />
    <div className={styles.turnstile}>
      <Skeleton variant="rect" width={300} height={65} />
      <Turnstile
        autoResetOnExpire
        action="login"
        sitekey={appConfig.captchaSiteKey}
        onVerify={token => {
          dispatch(forgetPasswordActions.setRecaptchaToken(token))
        }} />
    </div>
    <SendButtonCF />
  </form>
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  turnstile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 65,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))