import { Button, CircularProgress, makeStyles } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { useLoginSelector } from "./loginSlice"

export function LoginButtonGG() {
    const processing = useLoginSelector(state => state.step === 'processing')
    const styles = useStyles()
    return <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={processing}
        fullWidth
        className={styles.button}
    >
        {processing && <CircularProgress size={16} style={{ marginRight: 8 }} />}
        <FormattedMessage id="login.signIn" />
    </Button>
}

const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(4),
        fontWeight: 'normal',
        fontSize: '0.75rem',
        borderWidth: 1.5,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            borderWidth: 1.5,
            borderColor: theme.palette.primary.main,
        },
    },
}))