import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function SupplierListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64214 0 0 .64212 -131.82 -29.478)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 157">
            <rect transform="translate(224.34 64.596)" width="14.608" height="12.417" data-name="矩形 151" />
            <line transform="translate(236.76 74.821)" x1="1.461" data-name="直线 62" />
            <line transform="translate(234.56 74.821)" x1="1.461" data-name="直线 63" />
            <rect transform="translate(209,64.596)" width="15.338" height="12.417" data-name="矩形 152" />
            <path transform="translate(-2.734,-6.494)" d="m221.61 71v2.633h-4.608v-2.633" data-name="路径 233" />
            <path transform="translate(-10.595,-6.494)" d="m244.61 71v2.633h-4.608v-2.633" data-name="路径 234" />
            <rect transform="translate(217.03 52.179)" width="15.338" height="12.417" data-name="矩形 153" />
            <line transform="translate(229.45 62.405)" x1="1.461" data-name="直线 64" />
            <line transform="translate(227.26,62.405)" x1="1.461" data-name="直线 65" />
            <path transform="translate(-6.836)" d="m233.61 52v2.633h-4.608v-2.633" data-name="路径 235" />
            <line transform="translate(221.42 74.821)" x1="1.461" data-name="直线 66" />
            <line transform="translate(219.23 74.821)" x1="1.461" data-name="直线 67" />
        </g>
    </SvgIcon>
}