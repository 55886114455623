import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core"
import { History, Notifications } from "@material-ui/icons"
import { useState } from "react"
import { AnnouncementCatalog } from "../../../components/Announcement/AnnouncementCatalog"
import { NoticeHistory } from "../../../components/NoticeStack/NoticeHistory"

export const NotificationRoot = () => {
    const [value, setValue] = useState(0)
    const classes = useStyles()
    return <div className={classes.root}>
        <Tabs
            value={value}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v) => setValue(v)}
        >
            <Tab icon={<History />} label="Operation History" />
            <Tab icon={<Notifications />} label="What's New" />
        </Tabs>
        <TabPanel value={value} index={0}>
            <NoticeHistory />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <AnnouncementCatalog />
        </TabPanel>
    </div>
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        height: 560,
    }
}))