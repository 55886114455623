import { ReactNode } from "react"
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'

export interface NoAccessProps {
    access?: string | string[],
    power?: boolean,
    children: ReactNode | ReactNode[]
}

export const NoAccess = ({ access, power, children }: NoAccessProps) => {
    const noAuthed = useApplicationSelector(state => {
        const accessResources = state.auth.accessResources ?? []
        const poweruser = state.auth.user?.powerUser ?? false
        return (access && !accessResources.some(acc => typeof access === 'string' ? access === acc : access.includes(acc)))
            || (power && !poweruser)
    })

    return <>{noAuthed ? children : null}</>
}