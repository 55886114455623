import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function OrderCalculationListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26254 0 0 .26254 -7.6909 -7.6902)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
            <rect x="47.4" y="48.73" width="7.21" height="7.21" />
            <line x1="60.84" x2="87.94" y1="49.64" y2="49.64" />
            <line x1="60.84" x2="90.46" y1="54.92" y2="54.92" />
            <rect x="47.4" y="64.06" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="64.97" y2="64.97" />
            <line x1="60.84" x2="73.52" y1="70.25" y2="70.25" />
            <rect x="47.4" y="79.39" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="80.3" y2="80.3" />
            <line x1="60.84" x2="73.52" y1="85.58" y2="85.58" />
            <rect x="47.4" y="94.72" width="7.21" height="7.21" />
            <line x1="60.84" x2="73.52" y1="95.63" y2="95.63" />
            <line x1="60.84" x2="73.52" y1="100.91" y2="100.91" />
            <polyline points="49.54 50.79 51.88 53.36 58.06 47.18" />
            <polyline points="49.96 66.37 52.31 68.95 58.49 62.77" />
            <polyline points="49.96 81.83 52.31 84.4 58.49 78.22" />
            <polyline points="49.96 97.28 52.31 99.86 58.49 93.68" />
            <path strokeWidth="2" d="m98.25 56.97h-21.52c-1.56 0-2.82 1.26-2.82 2.82v45.88c0 1.56 1.26 2.82 2.82 2.82h21.52" />
            <path strokeWidth="2" d="m98.25 56.97h9.42c1.56 0 2.82 1.26 2.82 2.82v45.88c0 1.56-1.26 2.82-2.82 2.82h-9.42" />
            <path d="m98.25 62.12h5.95c0.77 0 1.39 0.62 1.39 1.39v5.46c0 0.77-0.62 1.39-1.39 1.39h-5.95" />
            <path d="m98.25 62.12h-18.06c-0.77 0-1.39 0.62-1.39 1.39v5.46c0 0.77 0.62 1.39 1.39 1.39h18.06" />
            <rect x="79.37" y="74.48" width="5.67" height="5.67" />
            <rect x="89.52" y="74.48" width="5.67" height="5.67" />
            <rect x="99.85" y="74.48" width="5.67" height="5.67" />
            <rect x="79.37" y="85.1" width="5.67" height="5.67" />
            <rect x="89.52" y="85.1" width="5.67" height="5.67" />
            <rect x="99.85" y="84.79" width="5.67" height="16.49" />
            <rect x="79.37" y="95.72" width="5.67" height="5.67" />
            <rect x="89.52" y="95.72" width="5.67" height="5.67" />
            <polyline strokeWidth="2" points="78.8 37.91 98.25 37.91 98.25 45.12 98.25 56.45" />
            <polyline strokeWidth="2" points="79.7 37.91 39.52 37.91 39.52 112.09 98.25 112.09 98.25 109" />
        </g>
    </SvgIcon>
}