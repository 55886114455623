import { AxiosInstance, AxiosResponse } from 'axios'

interface RoleAPIResponse {
    id: string,
    name: string,
    description: string,
    active: boolean,
    resourceIds: string[],
}

const roleApi = {
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/roles/batch-get', ids) as Promise<AxiosResponse<RoleAPIResponse[]>>
    },
}

export default roleApi