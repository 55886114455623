import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function FobSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.54021 0 0 .5402 2.3571 -.78676)">
            <g>
                <g transform="translate(-465.82 -24.273)">
                    <path d="m492.2 39.2h-18c-0.3 0-0.6-0.3-0.6-0.6v-3.9c0-1.1 0.9-2 2-2.1h3.7c0.4-2.1 2.4-3.5 4.5-3.1 1.6 0.3 2.8 1.5 3.1 3.1h3.7c1.1 0 2 0.9 2 2.1v3.9c0.2 0.4 0 0.6-0.4 0.6zm-17.4-1.2h16.8v-3.3c0-0.5-0.4-0.9-0.9-0.9h-3.7c-0.6 0-1.1-0.4-1.2-0.9v-0.1c-0.3-1.5-1.7-2.4-3.1-2.1-1.1 0.2-2 1.1-2.1 2.1v0.1c-0.1 0.5-0.6 0.9-1.2 0.9h-3.7c-0.5 0-0.9 0.4-0.9 0.9z" />
                    <path d="m482 64.1h-11.4c-0.3 0-0.6-0.3-0.6-0.6v-28c0-0.3 0.3-0.6 0.6-0.6h1.7v1.2h-1.1v26.7h10.8z" />
                    <path d="m483.3 33.8h-0.1c-0.3 0-0.6-0.3-0.6-0.6s0.3-0.6 0.6-0.6h0.1c0.3 0 0.6 0.3 0.6 0.6s-0.3 0.6-0.6 0.6z" />
                    <path d="m496 64.1h-14.9v-1.2h14.3v-26.8h-1.1v-1.2h1.7c0.3 0 0.6 0.3 0.6 0.6v27.9c0 0.4-0.3 0.7-0.6 0.7z" />
                </g>
                <g transform="translate(-139.44 -129.52)" fill="#fff" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2">
                    <path d="m160.7 164.1 0.8-1.8 1.1 0.4c0.3-0.4 0.7-0.8 1.1-1.1l-0.4-1.1 1.8-0.8 0.5 1c0.5-0.1 1-0.1 1.6 0l0.5-1 1.8 0.8-0.4 1.1c0.4 0.3 0.8 0.7 1.1 1.1l1.1-0.4 0.8 1.8-1 0.5c0.1 0.5 0.1 1 0 1.6l1 0.5-0.8 1.8-1.1-0.4c-0.3 0.4-0.7 0.8-1.1 1.1l0.4 1.1-1.8 0.8-0.5-1c-0.5 0.1-1 0.1-1.6 0l-0.5 1-1.8-0.8 0.4-1.1c-0.4-0.3-0.8-0.7-1.1-1.1l-1.1 0.4-0.8-1.8 1-0.5c-0.1-0.5-0.1-1 0-1.6z" />
                    <circle cx="166.5" cy="165.4" r="3.1" />
                </g>
            </g>
        </switch>
    </SvgIcon>
}