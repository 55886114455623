import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ContractRouteListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61076 0 0 .61076 -75.775 -166.81)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 141">
            <circle transform="translate(139.94 281.12)" cx="3.772" cy="3.772" r="3.772" data-name="椭圆 56" />
            <path transform="translate(-1.571,-8.798)" d="m151.37 305h4.543a3.086 3.086 0 0 1 3.084 3.086 3.086 3.086 0 0 1-3.086 3.086h-17.83a3.086 3.086 0 0 0-3.084 3.086 3.086 3.086 0 0 0 3.086 3.086h5.143" data-name="路径 213" />
            <line transform="translate(130 296.02)" x2="6.951" data-name="直线 55" />
            <line transform="translate(145.64,308.18)" x2="3.475" data-name="直线 56" />
            <path transform="translate(-2.514)" d="m154.46 285.23a8.229 8.229 0 1 0-16.458 0c0 6.172 8.229 12.344 8.229 12.344s8.229-6.173 8.229-12.344z" data-name="路径 214" />
        </g>
    </SvgIcon>
}