import { Button, makeStyles, Typography } from "@material-ui/core"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import logo from './logo.svg'

export const Code403 = () => {
    const navigate = useNavigate()
    const handleBackToHome = useCallback(() => {
        navigate("/home")
    }, [navigate])

    const classes = useStyles()
    return <div className={classes.root}>
        <div className={classes.left}>
            <div className={classes.leftContent}>
                <img src={logo} alt="logo" style={{ width: 480, height: 280 }} />
            </div>
        </div>
        <div className={classes.right}>
            <div className={classes.rightContent}>
                {/* <Typography variant="h1" className={classes.title}>
                    403
                </Typography> */}
                <Typography variant="h4" className={classes.description}>
                    <FormattedMessage id="403.403forbidden" />
                </Typography>
                <Button variant="contained" className={classes.button} onClick={handleBackToHome}>
                    <FormattedMessage id="403.backToHome" />
                </Button>
            </div>
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'stretch',
    },
    left: {
        flex: '1 1 50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    right: {
        flex: '1 1 50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    leftContent: {
        position: 'relative',
        marginTop: '-5%',
        marginRight: theme.spacing(10),
    },
    rightContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: 'relative',
        marginTop: '-5%',
        marginLeft: theme.spacing(10),
    },
    title: {
        fontSize: '10rem',
        lineHeight: 1,
    },
    description: {
        marginTop: 30,
        marginBottom: 30,
    },
    button: {
        minWidth: 320,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
    },
}))