import { Divider, makeStyles, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { ConfirmNewPasswordInput } from "./ConfirmNewPasswordInput"
import { NewPasswordInput } from "./NewPasswordInput"
import { PasswordCheckList } from "./PasswordCheckList"
import { ResetButton } from "./ResetButton"
import { resetPasswordActions } from "./resetPasswordSlice"

export function ResetForm() {
  const dispatch = useDispatch()
  const styles = useStyles()
  return <form className={styles.form} onSubmit={(e) => {
    dispatch(resetPasswordActions.validate())
    setTimeout(() => {
      dispatch(resetPasswordActions.resetPassword())
    }, 60)
    e.preventDefault()
  }}>
    <Typography variant="h5" style={{ textTransform: 'uppercase', lineHeight: '18px' }}>
      <FormattedMessage id="resetPassword.reset" />
    </Typography>
    <Divider className={styles.divider} />
    <NewPasswordInput />
    <ConfirmNewPasswordInput />
    <PasswordCheckList />
    <ResetButton />
  </form>
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
}))