import { ClickAwayListener, Divider, Grow, InputBase, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import { ArrowDropDown, Clear, ExitToApp, ListAlt, Settings } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Access } from '../../../components/Access/Access'
import { applicationActions, useApplicationSelector } from '../../Application/applicationSlice'

export interface UserMenuProps {
    anchorEl: HTMLElement | null,
    open: boolean,
    onClose: () => void,
}

export function UserMenu(props: UserMenuProps) {
    return <Popup {...props}>
        <Content {...props} />
    </Popup>
}

function Popup(props: UserMenuProps & { children: ReactNode }) {
    const { anchorEl, open, onClose, children } = props

    const classes = useStyles()
    return <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        className={classes.popper}
    >
        {({ TransitionProps }) =>
            <ClickAwayListener onClickAway={onClose}>
                <Grow {...TransitionProps}>
                    <Paper className={classes.paper}>
                        {children}
                    </Paper>
                </Grow>
            </ClickAwayListener>
        }
    </Popper >
}

function Content(props: UserMenuProps) {
    const { onClose: closeMenu } = props
    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const userCompanies = useApplicationSelector(state => state.auth.userCompanies)
    const companyOptions = useMemo(() => {
        return userCompanies?.map(company => company.cbdsUid) ?? []
    }, [userCompanies])
    const getCompanyOptionLabel = useCallback((companyUid: string) => {
        const optionLabel = userCompanies?.find(company => company.cbdsUid === companyUid)?.cbdsCode ?? ''
        return optionLabel
    }, [userCompanies])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleCompanyChange = useCallback((_: React.ChangeEvent<{}>, value: string | null) => {
        dispatch(applicationActions.setCompanyUid(value))
        navigate('/home')
        closeMenu()
    }, [closeMenu, dispatch, navigate])

    const classes = useStyles()
    return <List className={classes.menuList}>
        <MenuLabel id="appbar.company" />
        <MenuAutocomplete
            value={companyUid}
            options={companyOptions}
            getOptionLabel={getCompanyOptionLabel}
            onChange={handleCompanyChange}
        />
        <MenuDivider />
        <Setting closeMenu={closeMenu} />
        <MenuDivider />
        <Access access='MARS.MSESF030.VIEW'>
            <AccessHistory closeMenu={closeMenu} />
            <MenuDivider />
        </Access>
        <Logout />
    </List>
}

function MenuLabel(props: { id: string }) {
    const { id } = props
    const classes = useStyles()
    return <ListItem className={classes.menuLabel}>
        <Typography variant="body2" className={classes.colorWhite}>
            <FormattedMessage id={id} />
        </Typography>
    </ListItem>
}

function MenuAutocomplete(props: {
    value: string | null,
    options: string[],
    getOptionLabel: (option: string) => string,
    onChange: (event: React.ChangeEvent<{}>, value: string | null) => void,
    clearable?: boolean,
}) {
    const { value, options, getOptionLabel, onChange, clearable } = props
    const classes = useStyles()
    return <ListItem className={classes.menuItem}>
        <Autocomplete
            fullWidth
            autoHighlight
            autoComplete
            clearOnBlur
            handleHomeEndKeys
            disableCloseOnSelect
            disableClearable={!clearable}
            value={value}
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            closeIcon={clearable ? <Clear className={classes.colorWhite} /> : undefined}
            popupIcon={<ArrowDropDown className={classes.colorWhite} />}
            renderInput={params => <InputBase
                id={params.id}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                {...params.InputProps}
                inputProps={params.inputProps}
                className={classes.colorWhite}
            />}
        />
    </ListItem>
}

function MenuDivider() {
    const classes = useStyles()
    return <Divider className={classes.divider} />
}

function Setting(props: { closeMenu: () => void }) {
    const { closeMenu } = props
    const navigate = useNavigate()
    const handleMailSettingClick = useCallback(() => {
        navigate('/user-setting')
        closeMenu()
    }, [closeMenu, navigate])
    const classes = useStyles()
    return <ListItem onClick={handleMailSettingClick} className={classes.menuItem}>
        <ListItemIcon className={classes.colorWhite}>
            <Settings />
        </ListItemIcon>
        <ListItemText
            primary={<FormattedMessage id="appbar.setting" />}
            className={classes.colorWhite}
        />
    </ListItem>
}

function AccessHistory(props: { closeMenu: () => void }) {
    const { closeMenu } = props
    const navigate = useNavigate()
    const handleAccessHistoryClick = useCallback(() => {
        navigate('/selfAccessHistory')
        closeMenu()
    }, [closeMenu, navigate])
    const classes = useStyles()
    return <ListItem onClick={handleAccessHistoryClick} className={classes.menuItem}>
        <ListItemIcon className={classes.colorWhite}>
            <ListAlt />
        </ListItemIcon>
        <ListItemText
            primary={<FormattedMessage id="appbar.selfAccessHistory" />}
            className={classes.colorWhite}
        />
    </ListItem>
}

function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClick = useCallback(() => {
        dispatch(applicationActions.logout())
        navigate('/login')
    }, [dispatch, navigate])
    const classes = useStyles()
    return <ListItem onClick={handleClick} className={classes.menuItem}>
        <ListItemIcon className={classes.colorWhite}>
            <ExitToApp />
        </ListItemIcon>
        <ListItemText
            primary={<FormattedMessage id="appbar.logout" />}
            className={classes.colorWhite}
        />
    </ListItem>
}

const useStyles = makeStyles(theme => ({
    popper: {
        marginTop: theme.spacing(3),
        zIndex: theme.zIndex.modal,
        backdropFilter: 'blur(10px)',
    },
    paper: {
        background: 'rgba(1, 38, 72, 0.9)',
        padding: theme.spacing(0, 1),
        transformOrigin: '0px 0px',
    },
    menuList: {
        padding: `${theme.spacing(1.5)}px 0`,
    },
    menuLabel: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px 0`,
    },
    menuItem: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    },
    colorWhite: {
        color: 'rgba(255,255,255,0.8)',
    },
    divider: {
        background: 'linear-gradient(90deg, rgba(152,155,158, 0.5) 1px, transparent 1px 6px)',
        backgroundSize: '6px 1px',
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
}))
