import fieldEnUs from './field.en-US.json'
import { Language } from './Language'
import noticeEnUs from './notice.en-US.json'
import routeEnUs from './route.en-US.json'
import uiEnUs from './ui.en-US.json'
import urlEnUs from './url.en-US.json'
import venderEnUs from './vender.en-US.json'

const defaultMessages = {
    ...uiEnUs,
    ...fieldEnUs,
    ...noticeEnUs,
    ...routeEnUs,
    ...urlEnUs,
    ...venderEnUs,
}

export const messages: Record<string, Record<string, string>> = {
    [Language.EN_US]: defaultMessages,
    [Language.ZH_CN]: defaultMessages,
}