import {Input, makeStyles} from "@material-ui/core"
import {useCallback} from "react"
import {useDispatch} from "react-redux"
import {loginActions, useLoginSelector} from "./loginSlice"

export function VerificationMfaRecoveryInput() {
  const dispatch = useDispatch()
  const verificationMfaRecoveryCode = useLoginSelector(state => state.verificationMfaRecoveryCode)
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(loginActions.setVerificationMfaRecoveryCode(e.target.value))
  }, [dispatch])
  const styles = useStyles()
  return <Input type="text"
    id="verificationMfaRecoveryCode"
    value={verificationMfaRecoveryCode}
    autoFocus
    placeholder={'xxxxx-xxxxx'}
    onChange={handleChange}
    fullWidth
    disableUnderline
    className={styles.input}
  />
}

const useStyles = makeStyles(theme => ({
  input: {
    height: 40,
    padding: theme.spacing(2),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
}))