import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function CustomerDiListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.54796 0 0 .54796 -189.92 -10.856)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4090">
            <path transform="translate(-11.623)" d="m390.64 30.256a6.187 6.187 0 1 0-6.187 6.187 6.188 6.188 0 0 0 6.187-6.187z" strokeLinejoin="round" data-name="路径 1958" />
            <g strokeMiterlimit="10">
                <line transform="translate(355.34 53.347)" x2="4.935" data-name="直线 923" />
                <line transform="translate(378.53 53.347)" x2="6.766" data-name="直线 924" />
                <path transform="translate(-4.332,-25.414)" d="m382.86 80.87h2.192a0.4 0.4 0 0 1 0.377 0.62l-2.312 3.01c-0.261 0.344-0.506 0.262-0.811 0.262h-17.15c-0.3 0-0.46 0.059-0.63-0.233l-2.491-3.04a0.4 0.4 0 0 1 0.376-0.62h2.19" data-name="路径 1959" />
                <path transform="translate(-6.459,-18.155)" d="m366.73 77.38v-12.18a0.554 0.554 0 0 1 0.553-0.553h17.15a0.554 0.554 0 0 1 0.553 0.553v12.261" data-name="路径 1960" />
            </g>
            <g strokeLinejoin="round">
                <path transform="translate(-3.779,-3.565)" d="m360.74 32.967 6.827-0.93 0.637 4.676-5.634 0.772" data-name="路径 1961" />
                <line transform="translate(358.64 30.404)" x2="5.415" y1=".737" data-name="直线 925" />
                <path transform="translate(0,-3.999)" d="m357.58 39.562a3.578 3.578 0 0 0 1.3-3.783 3.681 3.681 0 0 0-3.775-2.743 3.943 3.943 0 0 0-2.037 0.806c-1.033 0.873-0.907 1.826-0.357 3.761 0.355 1.244 0.669 2.448 1.406 2.9 0.913 0.561 2.27 0.043 3.463-0.941z" data-name="路径 1962" />
                <path transform="translate(-5.185,-8.157)" d="m385.57 61.5 1.325-6.695c0.6-5.313-3.2-7.818-9.3-8.168-5.437-0.312-10.748-0.488-13.713-4.341" data-name="路径 1963" />
                <path transform="translate(-1.865,-10.253)" d="m356.46 46.986a15.674 15.674 0 0 0 10.46 7.014l-0.376 2.746" data-name="路径 1964" />
            </g>
            <line transform="translate(369.4 52.876)" y2=".044" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 926" />
        </g>
    </SvgIcon>
}