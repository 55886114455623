import { AxiosInstance } from 'axios'

interface AnnouncementAPIResponse {
    id: string,
    type: AnnouncementType,
    title: string,
    summary: string,
    contentType: string,
    content: string,
    publishAt: number,
    expireAt: number,
}

enum AnnouncementType {
    NEW = 'NEW',
    IMPROVEMENT = 'IMPROVEMENT',
    MAINTENANCE = 'MAINTENANCE',
}

const announcementApi = {
    list: function (axiosInstance: AxiosInstance) {
        return axiosInstance.get<{data: AnnouncementAPIResponse[]}>('/common-system-api/announcements')
    },
}

export default announcementApi