import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export interface NavMenuItemProps {
    icon?: ReactNode,
    title: ReactNode,
    path: string,
    onMenuClose: () => void,
    category?: ReactNode,
    open?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
}

export function NavMenuItem(props: NavMenuItemProps) {
    const {
        icon,
        title,
        path,
        onMenuClose,
    } = props

    const classes = useStyles()
    return <Link to={path} onClick={onMenuClose} style={{ textDecoration: 'none' }}>
        <MenuItem className={classes.root}>
            {icon && <span className={classes.icon}>
                {icon}
            </span>}
            <Typography variant="body2" className={classes.label}>
                {title}
            </Typography>
        </MenuItem>
    </Link>
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        borderRadius: theme.spacing(1),
        minHeight: 42,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    icon: {
        height: 24,
        marginRight: theme.spacing(1),
    },
    label: {
        width: 180,
        whiteSpace: 'break-spaces',
    }
}))