export enum Language {
    DEFAULT = 'en-US',
    EN_US = 'en-US',
    ZH_CN = 'zh-CN',
};

const transferMap: { [lang: string]: Language } = {
    'az-AZ-Cyrl': Language.EN_US,
    'az-AZ-Latn': Language.EN_US,
    'eu': Language.EN_US,
    'eu-ES': Language.EN_US,
    'be': Language.EN_US,
    'be-BY': Language.EN_US,
    'bg': Language.EN_US,
    'bg-BG': Language.EN_US,
    'ca': Language.EN_US,
    'ca-ES': Language.EN_US,
    'zh-HK': Language.EN_US,
    'zh-MO': Language.EN_US,
    'zh-CN': Language.ZH_CN,
    'zh-CHS': Language.ZH_CN,
    'zh-SG': Language.ZH_CN,
    'zh-TW': Language.EN_US,
    'zh-CHT': Language.EN_US,
    'hr': Language.EN_US,
    'hr-HR': Language.EN_US,
    'cs': Language.EN_US,
    'cs-CZ': Language.EN_US,
    'da': Language.EN_US,
    'da-DK': Language.EN_US,
    'div': Language.EN_US,
    'div-MV': Language.EN_US,
    'nl': Language.EN_US,
    'nl-BE': Language.EN_US,
    'nl-NL': Language.EN_US,
    'en': Language.EN_US,
    'en-AU': Language.EN_US,
    'en-BZ': Language.EN_US,
    'en-CA': Language.EN_US,
    'en-CB': Language.EN_US,
    'en-IE': Language.EN_US,
    'en-JM': Language.EN_US,
    'en-NZ': Language.EN_US,
    'en-PH': Language.EN_US,
    'en-ZA': Language.EN_US,
    'en-TT': Language.EN_US,
    'en-GB': Language.EN_US,
    'en-US': Language.EN_US,
    'en-ZW': Language.EN_US,
    'et': Language.EN_US,
    'et-EE': Language.EN_US,
    'fo': Language.EN_US,
    'fo-FO': Language.EN_US,
    'fa': Language.EN_US,
    'fa-IR': Language.EN_US,
    'fi': Language.EN_US,
    'fi-FI': Language.EN_US,
    'fr': Language.EN_US,
    'fr-BE': Language.EN_US,
    'fr-CA': Language.EN_US,
    'fr-FR': Language.EN_US,
    'fr-LU': Language.EN_US,
    'fr-MC': Language.EN_US,
    'fr-CH': Language.EN_US,
    'gl': Language.EN_US,
    'gl-ES': Language.EN_US,
    'ka': Language.EN_US,
    'ka-GE': Language.EN_US,
    'de': Language.EN_US,
    'de-AT': Language.EN_US,
    'de-DE': Language.EN_US,
    'de-LI': Language.EN_US,
    'de-LU': Language.EN_US,
    'de-CH': Language.EN_US,
    'el': Language.EN_US,
    'el-GR': Language.EN_US,
    'gu': Language.EN_US,
    'gu-IN': Language.EN_US,
    'he': Language.EN_US,
    'he-IL': Language.EN_US,
    'hi': Language.EN_US,
    'hi-IN': Language.EN_US,
    'hu': Language.EN_US,
    'hu-HU': Language.EN_US,
    'is': Language.EN_US,
    'is-IS': Language.EN_US,
    'id': Language.EN_US,
    'id-ID': Language.EN_US,
    'it': Language.EN_US,
    'it-IT': Language.EN_US,
    'it-CH': Language.EN_US,
    'ja': Language.EN_US,
    'ja-JP': Language.EN_US,
    'kn': Language.EN_US,
    'kn-IN': Language.EN_US,
    'kk': Language.EN_US,
    'kk-KZ': Language.EN_US,
    'kok': Language.EN_US,
    'kok-IN': Language.EN_US,
    'ko': Language.EN_US,
    'ko-KR': Language.EN_US,
    'ky': Language.EN_US,
    'ky-KZ': Language.EN_US,
    'lv': Language.EN_US,
    'lv-LV': Language.EN_US,
    'lt': Language.EN_US,
    'lt-LT': Language.EN_US,
    'mk': Language.EN_US,
    'mk-MK': Language.EN_US,
    'ms': Language.EN_US,
    'ms-BN': Language.EN_US,
    'ms-MY': Language.EN_US,
    'mr': Language.EN_US,
    'mr-IN': Language.EN_US,
    'mn': Language.EN_US,
    'mn-MN': Language.EN_US,
    'no': Language.EN_US,
    'nb-NO': Language.EN_US,
    'nn-NO': Language.EN_US,
    'pl': Language.EN_US,
    'pl-PL': Language.EN_US,
    'pt': Language.EN_US,
    'pt-BR': Language.EN_US,
    'pt-PT': Language.EN_US,
    'pa': Language.EN_US,
    'pa-IN': Language.EN_US,
    'ro': Language.EN_US,
    'ro-RO': Language.EN_US,
    'ru': Language.EN_US,
    'ru-RU': Language.EN_US,
    'sa': Language.EN_US,
    'sa-IN': Language.EN_US,
    'sr-SP-Cyrl': Language.EN_US,
    'sr-SP-Latn': Language.EN_US,
    'sk': Language.EN_US,
    'sk-SK': Language.EN_US,
    'sl': Language.EN_US,
    'sl-SI': Language.EN_US,
    'es': Language.EN_US,
    'es-AR': Language.EN_US,
    'es-BO': Language.EN_US,
    'es-CL': Language.EN_US,
    'es-CO': Language.EN_US,
    'es-CR': Language.EN_US,
    'es-DO': Language.EN_US,
    'es-EC': Language.EN_US,
    'es-SV': Language.EN_US,
    'es-GT': Language.EN_US,
    'es-HN': Language.EN_US,
    'es-MX': Language.EN_US,
    'es-NI': Language.EN_US,
    'es-PA': Language.EN_US,
    'es-PY': Language.EN_US,
    'es-PE': Language.EN_US,
    'es-PR': Language.EN_US,
    'es-ES': Language.EN_US,
    'es-UY': Language.EN_US,
    'es-VE': Language.EN_US,
    'sw': Language.EN_US,
    'sw-KE': Language.EN_US,
    'sv': Language.EN_US,
    'sv-FI': Language.EN_US,
    'sv-SE': Language.EN_US,
    'syr': Language.EN_US,
    'syr-SY': Language.EN_US,
    'ta': Language.EN_US,
    'ta-IN': Language.EN_US,
    'tt': Language.EN_US,
    'tt-RU': Language.EN_US,
    'te': Language.EN_US,
    'te-IN': Language.EN_US,
    'th': Language.EN_US,
    'th-TH': Language.EN_US,
    'tr': Language.EN_US,
    'tr-TR': Language.EN_US,
    'uk': Language.EN_US,
    'uk-UA': Language.EN_US,
    'ur': Language.EN_US,
    'ur-PK': Language.EN_US,
    'uz': Language.EN_US,
    'uz-UZ-Cyrl': Language.EN_US,
    'uz-UZ-Latn': Language.EN_US,
    'vi': Language.EN_US,
    'vi-VN': Language.EN_US,

}

const standardize = (lang: string): string => {
    lang = lang.replace(/_/g, '-').replace(/\./g, '-')
    const group = lang.split('-')
    if (group.length === 1) {
        return group[0].toLowerCase()
    } else if (group.length === 2) {
        return group[0].toLowerCase() + '-' + group[1].toUpperCase()
    } else if (group.length === 3) {
        const a = group[0].toLowerCase()
        const b = group[1].toUpperCase()
        const c = group[2].substr(0, 1).toUpperCase() + group[2].substr(1).toLowerCase()
        return `${a}-${b}-${c}`
    } else {
        return 'en-US'
    }
}

export const effectiveLanguage = (lang: string): Language => {
    return transferMap[standardize(lang)]
}
