import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function AccountingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.88846 0 0 .89063 -138.01 -313.52)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.5738" data-name="组 64">
            <path transform="translate(-15.14)" d="m187 355v3.759a1.253 1.253 0 0 0 1.253 1.253h3.759" strokeLinecap="round" data-name="路径 12" />
            <path d="m162.67 375.88h12.529a1.671 1.671 0 0 0 1.671-1.671v-14.2l-5.011-5.013h-9.189a1.671 1.671 0 0 0-1.671 1.671v17.543a1.671 1.671 0 0 0 1.671 1.67z" strokeLinecap="round" data-name="路径 13" />
            <rect transform="translate(164.37 363.03)" width="9.363" height="5.346" rx="1.1255" ry="1.1228" data-name="矩形 68" />
            <line transform="translate(163.58 365.8)" x1="10.954" data-name="直线 8" />
            <line transform="translate(170.51 363.23)" y2="5.155" data-name="直线 10" />
            <g strokeLinecap="round">
                <line transform="translate(171.95 371.75)" x2="1.934" data-name="直线 11" />
                <line transform="translate(164.34 371.75)" x2="5.033" data-name="直线 12" />
                <line transform="translate(164.37 358.87)" x2="4.334" y2=".028" data-name="直线 13" />
            </g>
        </g>
    </SvgIcon>
}