import { Theme } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { PaletteOptions } from "@material-ui/core/styles/createPalette"
import { TypographyOptions } from "@material-ui/core/styles/createTypography"
import { Overrides } from "@material-ui/core/styles/overrides"
import { ComponentsProps } from "@material-ui/core/styles/props"
import { Shadows } from "@material-ui/core/styles/shadows"
import createTheme from './createTheme'
import { Layout } from './Layout'

const layout: Layout = {
    appbarHeight: 88,
    copyrightBarHeight: 40,
    actionBarHeight: 48,
    form: {
        lineHeight: 36,
        buttonHeight: 36,
    },
}
const lightPalette: PaletteOptions = {
    type: 'light',
    primary: { main: '#003362', contrastText: '#FFFFFF' },
    secondary: { main: '#00A6ED', contrastText: '#FFFFFF' },
    info: { main: '#1A74C0', contrastText: '#FFFFFF' },
    success: { main: '#00CBAD', contrastText: '#FFFFFF' },
    warning: { main: '#FCCA67', contrastText: '#FFFFFF' },
    error: { main: '#CB4647', contrastText: '#FFFFFF' },
    text: {
        primary: '#003362',
        secondary: '#768694',
        disabled: '#666666',
    },
    background: {
        default: 'rgba(250,250,250,0.95)',
        paper: '#FFFFFF',
        workbench: 'radial-gradient(ellipse at right bottom, #E6DCDD, rgba(255,255,255,0.6) 20%, rgba(255,255,255,0.8) 50%, transparent 65%),linear-gradient(61deg, #B6D1FE, #F3F3F9 30%, #F2EBF2 50%, #F2F1F9 80%, #BAD1FB)',
        appbar: {
            main: 'linear-gradient(15deg, #FFFFFF -178%, #F2F4F7 109%)',
            light: '#FFFFFF',
        },
        neumorphism: {
            main: '#EFF1F5',
            shadow: '3px 3px 6px #D9DFEA, -3px -3px 6px #FFFFFF',
        },
        card: {
            linear: 'linear-gradient(175deg, #FFFFFF -194%, #F2F4F7 116%)',
            main: '#F6F8FA',
            inactive: '#EBEFF2',
        },
        search: {
            main: '#EBEFF2',
            hover: '#E4E8EA',
            focused: '#CED2D5',
        },
        popover: {
            main: 'rgba(1, 38, 72, 0.9)',
        },
        readonly: {
            main: '#E0E5E8',
        },
        disabled: {
            main: '#E0E5E8',
        }
    },
}
const darkPalette: PaletteOptions = {
    type: 'dark',
    primary: { main: '#003362' },
    secondary: { main: '#00A6ED' },
    info: { main: '#1A74C0' },
    success: { main: '#00CBAD' },
    warning: { main: '#FCCA67' },
    error: { main: '#D94B00' },
    text: {
        primary: '#FFFFFF',
        secondary: '#B2B2B2',
        disabled: '#888888',
    },
    background: {
        default: '#303030',
        paper: '#424242',
        workbench: 'radial-gradient(ellipse at right bottom, #5f595a, rgb(0 0 0 / 8%) 20%, rgb(0 0 0 / 10%) 50%, transparent 65%),linear-gradient(61deg, #2b364a, #5e5e77 30%, #695869 50%, #43404c 80%, #203456)',
        appbar: {
            main: '#3d3d3d',
            light: '#33322e',
        },
        neumorphism: {
            main: '#3D3D3D',
            shadow: '2px 2px 4px #343434, 3px 3px 6px #464646',
        },
        card: {
            linear: 'rgba(60,60,60,0.75)',
            main: 'rgba(60,60,60,0.75)',
            inactive: '#EBEFF2',
        },
        search: {
            main: '#EBEFF2',
            hover: '#E4E8EA',
            focused: '#CED2D5',
        },
        popover: {
            main: 'rgba(1, 38, 72, 0.9)',
        },
    },
}
const spacing: number = 8
const typography: TypographyOptions = {
    fontFamily: 'Montserrat',
    ...Object.assign({}, ...Array(6).fill(0).map((_, i) => i + 1).map(n => ({
        [`h${n}`]: {
            fontSize: `${1 + (6 - n) / 8}rem`,
            fontWeight: 'bold',
            lineHeight: 1.5
        }
    }))),
    ...Object.assign({}, ...Array(2).fill(0).map((_, i) => i + 1).map(n => ({
        [`subtitle${n}`]: {
            fontSize: `${1 - n / 8}rem`,
            fontWeight: 'bold',
            lineHeight: 1.5
        }
    }))),
    ...Object.assign({}, ...Array(2).fill(0).map((_, i) => i + 1).map(n => ({
        [`body${n}`]: {
            fontSize: `${1 - n / 8}rem`,
            lineHeight: 1.5
        }
    }))),
    button: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        lineHeight: 1.5,
    },
}
const shape = {
    borderRadius: 8,
}
const zIndex = {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
}
const shadows: Shadows = ['none', ...Array(24).fill(0).map((_, i) => i + 1).map(elevation => {
    const shadows = [
        { x: 0, y: elevation, blur: elevation * 2, spread: 0, color: '#88888828' },
        { x: 0, y: elevation * 0.5, blur: elevation * 1, spread: 0, color: '#44444402' }
    ]
    return shadows.map(shadow => {
        return `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`
    }).join(',')
})] as Shadows
const overrides: Overrides = {
    MuiBackdrop: {
        root: {
            backgroundColor: 'rgba(37, 54, 70, 0.9)'
        },
    },
    MuiDialog: {
        paper: {
            borderRadius: spacing * 1.5,
        },
    },
    MuiDialogTitle: {
        root: {
            padding: `${spacing * 1.5}px ${spacing * 3}px`,
        },
    },
    MuiDialogActions: {
        root: {
            padding: `${spacing}px ${spacing * 3}px ${spacing * 2.5}px`,
        },
    },
    MuiIconButton: {
        root: {
            padding: spacing * 0.75,
        },
    },
    MuiButton: {
        root: {
            height: layout.form.buttonHeight,
            minWidth: 0,
        },
        text: {
            padding: `${spacing * 0.5}px ${spacing}px`,
        },
        ...Object.assign({}, ...[
            'outlined',
            'outlinedPrimary',
            'outlinedSecondary',
            'outlinedSizeLarge',
            'outlinedSizeSmall',
        ].map(classKey => ({
            [classKey]: {
                borderWidth: 1.5,
                '&:hover': {
                    borderWidth: 1.5,
                },
            }
        }))),
    },
    MuiCheckbox: {
        root: {
            padding: 0,
            minHeight: layout.form.lineHeight,
            backgroundColor: 'transparent',
        }
    },
    MuiInput: {
        root: {
            minHeight: layout.form.lineHeight,
            borderRadius: shape.borderRadius,
            fontSize: typography.body1?.fontSize,
            //padding: `${10}px ${spacing * 2}px ${9}px ${spacing * 2}px`,
        },
    },
    MuiOutlinedInput: {
        root: {
            minHeight: layout.form.lineHeight,
            borderRadius: shape.borderRadius,
            fontSize: typography.body1?.fontSize,
            // padding: `${10}px ${spacing * 2}px ${9}px ${spacing * 2}px`,
        },
    },
    MuiFilledInput: {
        root: {
            minHeight: layout.form.lineHeight,
            borderRadius: shape.borderRadius,
            fontSize: typography.body1?.fontSize,
            // padding: `${10}px ${spacing * 2}px ${9}px ${spacing * 2}px`,
        },
    },
    MuiTable: {
        root: {
            borderCollapse: 'separate',
            borderSpacing: `0 ${2}px`,
            marginTop: -2,
            '&>thead>tr': {
                backgroundColor: 'transparent',
            },
            '&>thead th': {
                backgroundColor: grey[100],
            },
        },
    },
    MuiTableRow: {
        root: {
            height: 40,
            '&>td:first-child': {
                borderRadius: `${8}px 0px 0px ${8}px`,
            },
            '&>th:first-child': {
                borderRadius: `${8}px 0px 0px ${8}px`,
            },
            '&>td:last-child': {
                borderRadius: `0px ${8}px ${8}px 0px`,
            },
            '&>th:last-child': {
                borderRadius: `0px ${8}px ${8}px 0px`,
            },
        },
    },
    MuiTableCell: {
        root: {
            padding: `${spacing * 2}px ${spacing * 2.5}px`,
            border: 'none',
            borderBottomStyle: 'none',

        },
        sizeSmall: {
            padding: `${spacing * 1.5}px ${spacing * 2}px`,
        },
    },
    MuiTabs: {
        indicator: {
            height: 4,
            borderRadius: 2,
        },
    },
    MuiTab: {
        root: {
            '@media(min-width: 600px)': {
                minWidth: 0,
            },
            padding: 0,
            marginTop: spacing * 0.75,
            marginBottom: spacing * 0.75,
            marginLeft: spacing * 1.5,
            marginRight: spacing * 1.5,
            minHeight: 32,
        },
    },
}
const props: ComponentsProps = {
    MuiInput: {
        disableUnderline: true,
    },
    MuiFilledInput: {
        disableUnderline: true,
    },
    MuiCard: {
        elevation: 3,
    },
}

const lightTheme = createTheme({
    layout,
    shape,
    overrides,
    palette: lightPalette,
    props,
    shadows,
    spacing,
    typography,
    zIndex,
})
const darkTheme = createTheme({
    layout,
    shape,
    overrides,
    palette: darkPalette,
    props,
    shadows,
    spacing,
    typography,
    zIndex,
})

export const themes: Record<string, Theme> = {
    'default': lightTheme,
    'light': lightTheme,
    'dark': darkTheme,
}