import { Divider, makeStyles, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import Turnstile from "react-turnstile"
import appConfig from "../../configs/appConfig"
import { LoginButtonCF } from "./LoginButtonCF"
import { loginActions } from "./loginSlice"
import { PasswordInput } from "./PasswordInput"
import { RememberMe } from "./RememberMe"
import { UsernameInput } from "./UsernameInput"

export function SignInFormCF() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loginActions.setRecaptchaToken(''))
  }, [dispatch])
  const styles = useStyles()
  return <form className={styles.form} onSubmit={(e) => {
    dispatch(loginActions.mfaState())
    e.preventDefault()
  }}>
    <Typography variant="h5" style={{ textTransform: 'uppercase', lineHeight: '18px' }}>
      <FormattedMessage id="login.signIn" />
    </Typography>
    <Divider className={styles.divider} />
    <Typography variant="body2" color="textSecondary" className={styles.label}>
      <FormattedMessage id="login.username" />
    </Typography>
    <UsernameInput />
    <Typography variant="body2" color="textSecondary" className={styles.label}>
      <FormattedMessage id="login.password" />
    </Typography>
    <PasswordInput />
    <div className={styles.helper}>
      <RememberMe />
      <Link className={styles.link} to="/forget-password">
        <Typography variant="body2" color="secondary">
          <FormattedMessage id="login.forgetPassword" />
        </Typography>
      </Link>
    </div>
    <div className={styles.turnstile}>
      <Skeleton variant="rect" width={300} height={65} />
      <Turnstile
        autoResetOnExpire
        action="login"
        sitekey={appConfig.captchaSiteKey}
        onVerify={token => {
          dispatch(loginActions.setRecaptchaToken(token))
        }} />
    </div>
    <LoginButtonCF />
  </form>
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  input: {
    height: 40,
    padding: theme.spacing(2),
    marginTop: 2,
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
  helper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    height: 18,
  },
  link: {
    textDecorationColor: theme.palette.secondary.main,
  },
  turnstile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 65,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    height: theme.spacing(4),
    fontWeight: 'normal',
    fontSize: '0.75rem',
    borderWidth: 1.5,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderWidth: 1.5,
      borderColor: theme.palette.primary.main,
    },
  },
}))
