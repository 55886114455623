import { Button, makeStyles } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

export function VerifyButton() {
    const styles = useStyles()
    return <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        className={styles.button}
    >
        <FormattedMessage id="login.verify" />
    </Button>
}
const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(4),
        fontWeight: 'normal',
        fontSize: '0.75rem',
        borderWidth: 1.5,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            borderWidth: 1.5,
            borderColor: theme.palette.primary.main,
        },
    },
}))