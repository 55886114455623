import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PlaceOrderIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53042 0 0 .53042 -10.985 -8.3692)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4082">
            <path transform="translate(-4.841,-13.643)" d="m57.969 57.246a1.569 1.569 0 0 0-1.426 0.909v-0.937a1.62 1.62 0 0 0-3.177-0.442c0-1.189-0.557-1.967-1.616-1.967a1.617 1.617 0 0 0-1.6 1.355v-6.64a1.62 1.62 0 0 0-3.239 0v10.294c-1-1.895-2.141-2.682-3.154-2.682h-0.027a2.258 2.258 0 0 0-1.858 0.938c-0.282 0.622 2.021 1.645 3.652 5.675 1.766 4.357 3.2 6.549 7.522 6.549 4.735 0 6.5-3.315 6.5-5.45v-6.026a1.575 1.575 0 0 0-1.577-1.576z" strokeLinejoin="round" data-name="路径 1910" />
            <g strokeMiterlimit="10">
                <path transform="translate(-16.1,-12.959)" d="m68.893 47.384a1.37 1.37 0 0 0-1.286-0.87 1.3 1.3 0 0 0-0.519 0.1l-1.485 0.649a1.444 1.444 0 0 0-0.742 1.866 1.373 1.373 0 0 0 1.287 0.87 1.3 1.3 0 0 0 0.519-0.105l1.484-0.648a1.442 1.442 0 0 0 0.742-1.862z" data-name="路径 1911" />
                <path transform="translate(-16.297,-16.436)" d="m66.884 56.648 1.343-0.586a1.3 1.3 0 0 0 0.67-1.686 1.239 1.239 0 0 0-1.162-0.788 1.188 1.188 0 0 0-0.47 0.094l-1.342 0.587a1.3 1.3 0 0 0-0.671 1.686 1.24 1.24 0 0 0 1.163 0.787 1.174 1.174 0 0 0 0.469-0.094z" data-name="路径 1912" />
                <path transform="translate(-16.106,-9.678)" d="m64.874 42.27a1.375 1.375 0 0 0 1.287 0.869 1.307 1.307 0 0 0 0.519-0.1l1.006-0.46a1.444 1.444 0 0 0 0.743-1.865 1.375 1.375 0 0 0-1.287-0.872 1.3 1.3 0 0 0-0.519 0.1l-1.007 0.46a1.442 1.442 0 0 0-0.742 1.868z" data-name="路径 1913" />
                <line transform="translate(43.258,21.869)" x2="1.048" strokeLinecap="round" data-name="直线 902" />
                <line transform="translate(37.442,23.465)" x2="12.681" data-name="直线 903" />
                <path transform="translate(-17.519,-4.341)" d="m67.642 33.2 2.711-1.16a1.63 1.63 0 0 0 0.838-2.107 1.393 1.393 0 0 0-1.835-0.867c-0.245 0.07-1.392 0.476-1.714 0.612" data-name="路径 1914" />
                <path transform="translate(-15.644,-19.48)" d="m63.828 61.282 0.373-0.035c0.806-0.123 1.682-0.672 1.682-1.465" data-name="路径 1915" />
                <path transform="translate(-5.261)" d="m55.384 30.445v-8.368a1.67 1.67 0 0 0-1.39-1.674c-1.786-0.168-3.364-0.254-4.946-0.254s-3.167 0.085-4.97 0.256a1.667 1.667 0 0 0-1.378 1.672v17.98a1.666 1.666 0 0 0 1.391 1.672c1.1 0.106 2.175 0.177 3.24 0.218" data-name="路径 1916" />
                <line transform="translate(45.31,38.13)" x2="3.897" data-name="直线 904" />
                <line transform="translate(37.442,38.13)" x2="4.63" data-name="直线 905" />
                <path transform="translate(0,-3.073)" d="m37.442 28.189a2.714 2.714 0 0 0-3.22-1.665c-0.675 0.4 0.822 2.965-0.787 7.718a24.308 24.308 0 0 0-1.408 5.5 7.389 7.389 0 0 0 4.985 7.828" data-name="路径 1917" />
            </g>
            <path transform="translate(-9.233,-6.936)" d="m50.785 36.318 2.1 2.1 3.1-4.157" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1918" />
        </g>
    </SvgIcon>
}