import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function MasterIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 259" transform="matrix(.87266 0 0 .8729 -386.591 -169.21)" fill="none" stroke="currentColor" strokeWidth="1.146">
            <path data-name="路径 175" d="M456.754 218.194h-8.017a.737.737 0 0 1-.737-.737v-18.8a.737.737 0 0 1 .737-.737h16.034a.737.737 0 0 1 .737.737v8.938" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" />
            <path data-name="直线 39" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M461.592 197v2.764" />
            <path data-name="直线 40" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M458.367 197v2.764" />
            <path data-name="直线 41" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M455.142 197v2.764" />
            <path data-name="直线 42" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M451.916 197v2.764" />
            <path data-name="直线 43" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M455.601 205.358h5.701" />
            <path data-name="直线 44" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M455.601 209.159h3.801" />
            <path data-name="直线 45" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.719" d="M455.601 212.959h.95" />
            <circle data-name="椭圆 11" cx=".691" cy=".691" r=".691" transform="translate(450.995 204.602)" />
            <circle data-name="椭圆 12" cx=".691" cy=".691" r=".691" transform="translate(450.995 208.749)" />
            <circle data-name="椭圆 13" cx=".691" cy=".691" r=".691" transform="translate(450.995 212.896)" />
            <path data-name="路径 176" d="M464.324 210.166l.978 1.977a.476.476 0 0 0 .361.26l2.2.311a.472.472 0 0 1 .264.808l-1.594 1.53a.471.471 0 0 0-.138.422l.382 2.165a.476.476 0 0 1-.693.5l-1.966-1.03a.482.482 0 0 0-.446 0l-1.966 1.03a.476.476 0 0 1-.692-.5l.382-2.166a.471.471 0 0 0-.138-.422l-1.594-1.53a.472.472 0 0 1 .264-.809l2.2-.311a.477.477 0 0 0 .361-.26l.979-1.977a.479.479 0 0 1 .856.002z" strokeLinejoin="round" strokeWidth="1.719" />
        </g>
    </SvgIcon>
}