import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import authApi from '../../services/authApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import responseToNotification from '../../utils/axios/responseToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import { IntlString } from "../../utils/IntlString"
import { applicationActions } from '../Application/applicationSlice'

type Step = 'reset' | 'reset-processing' | 'success'

const minPasswordLength = 8
const characterCategories = [
    '0123456789',
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '!"#$%&\'()*+,-./:;=?@[\\]^_`{|}~'
]

interface ResetPasswordState {
    username: string,
    resetPasswordCode: string,
    newPassword: string,
    newPasswordMessage: string | IntlString,
    confirmNewPassword: string,
    confirmNewPasswordMessage: string | IntlString,
    passwordCheckList: boolean[],
    step: Step,
}

const initialState: ResetPasswordState = {
    username: '',
    resetPasswordCode: '',
    newPassword: '',
    newPasswordMessage: '',
    confirmNewPassword: '',
    confirmNewPasswordMessage: '',
    passwordCheckList: [false, false, false, false, false, true],
    step: 'reset',
}

const resetPassword = createAsyncThunk<void>('resetPassword/resetPassword', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectResetPasswordState(thunk.getState())
    if (!state.newPasswordMessage
        && !state.confirmNewPasswordMessage
        && state.newPassword
        && state.confirmNewPassword
        && state.newPassword === state.confirmNewPassword) {
        thunk.dispatch(resetPasswordActions.setStep('reset-processing'))
        return authApi.resetPassword(axiosInstance, {
            username: state.username,
            newPassword: state.newPassword,
            resetPasswordCode: state.resetPasswordCode,
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(resetPasswordActions.setStep('success'))
        }).catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            thunk.dispatch(resetPasswordActions.setStep('reset'))
        })
    }
})

function validNewPassword(state: ResetPasswordState) {
    if (state.newPassword === '') {
        state.newPasswordMessage = { code: 'resetPassword.newPasswordIsEmpty' }
    } else {
        state.newPasswordMessage = ''
    }
    state.passwordCheckList[0] = state.newPassword.length >= minPasswordLength
    state.passwordCheckList[1] = state.newPassword.split('').some(c => characterCategories[0].includes(c))
    state.passwordCheckList[2] = state.newPassword.split('').some(c => characterCategories[1].includes(c))
    state.passwordCheckList[3] = state.newPassword.split('').some(c => characterCategories[2].includes(c))
    state.passwordCheckList[4] = state.newPassword.split('').some(c => characterCategories[3].includes(c))
    state.passwordCheckList[5] = state.newPassword.split('').every(c => characterCategories.join('').includes(c))
}

function validConfirmNewPassword(state: ResetPasswordState) {
    if (state.confirmNewPassword === '') {
        state.confirmNewPasswordMessage = { code: 'resetPassword.confirmNewPasswordIsEmpty' }
    } else if (state.newPassword !== state.confirmNewPassword) {
        state.confirmNewPasswordMessage = { code: 'resetPassword.newPasswordMismatch' }
    } else {
        state.confirmNewPasswordMessage = ''
    }
}

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<{ username: string, resetPasswordCode: string }>) => {
            const { username, resetPasswordCode } = action.payload
            state.username = username
            state.resetPasswordCode = resetPasswordCode
            state.newPassword = ''
            state.newPasswordMessage = ''
            state.confirmNewPassword = ''
            state.confirmNewPasswordMessage = ''
            state.passwordCheckList = [false, false, false, false, false, true]
            state.step = 'reset'
        },
        destroy: (state) => {
            state.username = ''
            state.resetPasswordCode = ''
            state.newPassword = ''
            state.newPasswordMessage = ''
            state.confirmNewPassword = ''
            state.confirmNewPasswordMessage = ''
            state.passwordCheckList = [false, false, false, false, false, true]
            state.step = 'reset'
        },
        setNewPassword: (state, { payload: newPassword }: PayloadAction<string>) => {
            state.newPassword = newPassword
            validNewPassword(state)
        },
        setConfirmNewPassword: (state, { payload: confirmNewPassword }: PayloadAction<string>) => {
            state.confirmNewPassword = confirmNewPassword
            validConfirmNewPassword(state)
        },
        validate: (state) => {
            validNewPassword(state)
            validConfirmNewPassword(state)
        },
        setStep: (state, { payload: step }: PayloadAction<Step>) => {
            state.step = step
        },
    },
    extraReducers: {
    }
})

export const resetPasswordActions = {
    ...resetPasswordSlice.actions,
    resetPassword,
}

export function selectResetPasswordState(state: any) {
    return state[appConfig.appFullName][resetPasswordSlice.name] as ResetPasswordState
}

export function useResetPasswordSelector<R>(selector: (state: ResetPasswordState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectResetPasswordState(state)), equalityFn)
}