import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function CargoTrackingDetailIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.19617 0 0 .19617 -5.0742 -3.6738)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <line x1="137.35" x2="133" y1="104.27" y2="104.27" />
            <line x1="122.12" x2="122.12" y1="101.87" y2="104.86" />
            <polygon points="99.09 87.51 100.67 85.35 100.67 79.62 88.04 79.62 88.04 85.08 89.62 87.51 91.2 85.35 92.69 87.51 94.36 85.35 95.65 87.51 97.51 85.35" />
            <line x1="102.25" x2="112.23" y1="105.65" y2="105.65" />
            <line x1="102.28" x2="112.26" y1="108.62" y2="108.62" />
            <polygon points="119.6 94.61 128.49 94.61 133.02 101.27 119.6 101.27" />
            <polyline points="91.06 114.05 115.66 114.05 115.66 79.33 73.84 79.33 73.84 114.05 79.24 114.05" />
            <path d="m91.06 114.05c-0.01 3.25-2.65 5.89-5.91 5.89s-5.9-2.63-5.91-5.89" />
            <path d="m91.06 114.05v-0.03c0-3.26-2.65-5.91-5.91-5.91s-5.91 2.65-5.91 5.91v0.03" />
            <path d="m88.18 114.05c-0.01 1.67-1.37 3.01-3.04 3.01s-3.02-1.35-3.04-3.01" />
            <path d="m88.18 114.05v-0.03c0-1.68-1.36-3.04-3.04-3.04s-3.04 1.36-3.04 3.04v0.03" />
            <polyline points="131.84 114.05 138.54 114.05 138.54 101.71 130.39 89.69 115.66 89.69 115.66 114.05 120.04 114.05" />
            <path d="m120.04 114.05v0.13c0 3.26 2.65 5.91 5.91 5.91s5.91-2.65 5.91-5.91v-0.13" />
            <path d="m120.04 114.05c0.07-3.2 2.68-5.78 5.9-5.78s5.84 2.58 5.9 5.78" />
            <path d="m122.91 114.05c0.07-1.62 1.39-2.91 3.03-2.91s2.96 1.29 3.03 2.91" />
            <path d="m122.91 114.05s-0.01 0.08-0.01 0.13c0 1.68 1.36 3.04 3.04 3.04s3.04-1.36 3.04-3.04c0-0.04-0.01-0.08-0.01-0.13" />
            <polyline points="107.28 114.05 107.28 123.5 38.59 123.5 38.59 36.08 50.1 36.08 95.21 36.08 107.28 36.08 107.28 79.33" />
            <line x1="51.12" x2="96.2" y1="54.15" y2="54.15" />
            <line x1="50.86" x2="95.94" y1="60.82" y2="60.82" />
            <line x1="50.07" x2="95.15" y1="76.52" y2="76.52" />
            <line x1="49.81" x2="73.2" y1="83.2" y2="83.2" />
            <line x1="49.94" x2="73.2" y1="98.89" y2="98.89" />
            <line x1="49.81" x2="73.07" y1="105.57" y2="105.57" />
        </g>
    </SvgIcon>
}