import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function CustomerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63359 0 0 .63359 -280.65 -223.81)" fill="none" stroke="currentColor" data-name="组 4108">
            <path d="m474.41 357h-21.125a5.281 5.281 0 0 0-5.281 5.281v24.425a0.66 0.66 0 0 0 0.66 0.66h2.013a1.974 1.974 0 0 1 3.895 0h3.367a1.974 1.974 0 0 1 3.895 0h3.37a1.974 1.974 0 0 1 3.895 0h2.005a0.66 0.66 0 0 0 0.66-0.66v-27.065a2.641 2.641 0 0 1 2.646-2.641 2.641 2.641 0 0 1 2.641 2.641v1.98a0.66 0.66 0 0 1-0.66 0.66h-3.961" strokeMiterlimit="10" strokeWidth="1.2" data-name="路径 2001" />
            <path transform="translate(-6.967,-2.549)" d="m472.7 372.19a1.065 1.065 0 0 0-0.275-0.77 2.487 2.487 0 0 0-0.93-0.527 11.189 11.189 0 0 1-1.131-0.481 3.6 3.6 0 0 1-0.816-0.549 2.207 2.207 0 0 1-0.529-0.729 2.4 2.4 0 0 1-0.19-1 2.191 2.191 0 0 1 0.65-1.635 2.768 2.768 0 0 1 1.728-0.743v-1.26h0.963v1.281a2.526 2.526 0 0 1 1.668 0.87 2.83 2.83 0 0 1 0.6 1.874h-1.738a1.6 1.6 0 0 0-0.3-1.06 0.992 0.992 0 0 0-0.8-0.351 0.945 0.945 0 0 0-1.035 1.032 0.984 0.984 0 0 0 0.265 0.72 2.926 2.926 0 0 0 0.984 0.555 11.094 11.094 0 0 1 1.183 0.534 3.38 3.38 0 0 1 0.782 0.567 2.2 2.2 0 0 1 0.488 0.72 2.432 2.432 0 0 1 0.168 0.941 2.179 2.179 0 0 1-0.638 1.629 2.823 2.823 0 0 1-1.757 0.737v1.174h-0.957v-1.168a2.92 2.92 0 0 1-1.911-0.858 2.734 2.734 0 0 1-0.677-1.938h1.74a1.549 1.549 0 0 0 0.34 1.077 1.252 1.252 0 0 0 0.978 0.374 1.208 1.208 0 0 0 0.837-0.275 0.941 0.941 0 0 0 0.31-0.741z" fill="#fff" data-name="路径 2002" />
            <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
                <line transform="translate(453,369)" x2="4" data-name="直线 952" />
                <line transform="translate(453,374)" x2="5" data-name="直线 953" />
                <line transform="translate(453,379)" x2="10" data-name="直线 954" />
            </g>
        </g>
    </SvgIcon>
}