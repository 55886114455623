import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.4914 0 0 .4914 -117.22 -62.052)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4086">
            <path transform="translate(-9.436,-11.897)" d="m290.53 164.04c-1.465-1.154-2.2-1.728-3.662-2.882a0.854 0.854 0 0 0-1.127-0.051 128.63 128.63 0 0 0-9.415 12.664c-3.344 5.15-2.8 6.6-2.8 6.6-0.256 0.485-0.7 1.489-0.221 1.865s1.335-0.277 1.748-0.643c0 0 1.568 0.162 5.865-4.4a119.06 119.06 0 0 0 9.92-12.078 0.854 0.854 0 0 0-0.308-1.075z" strokeLinejoin="round" data-name="路径 1935" />
            <path transform="translate(-12.377,-11.495)" d="m286.39 159.91a56.217 56.217 0 0 0-5.878 7.472" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1936" />
            <line transform="translate(266.89 161.87)" x2="4.597" y2="3.427" data-name="直线 912" />
            <path transform="translate(-18.673,-10.952)" d="m299.51 162.89 1.37-1.741c0.164-0.207 0.053-0.57-0.249-0.806l-2.046-1.609c-0.3-0.236-0.677-0.26-0.841-0.052l-1.37 1.74" data-name="路径 1937" />
            <g strokeLinejoin="round">
                <path transform="translate(-2.427)" d="m274.12 135.73a1.5 1.5 0 0 0-1.5-1.5h-3.86a0.655 0.655 0 0 1-0.648-0.5 3.385 3.385 0 0 0-6.661 0 0.66 0.66 0 0 1-0.656 0.5h-3.851a1.5 1.5 0 0 0-1.5 1.5v4.085h18.676z" data-name="路径 1938" />
                <rect transform="translate(252.9 143.58)" width="2.859" height="2.859" data-name="矩形 14411" />
                <line transform="translate(257.98 145.01)" x2="13.714" data-name="直线 913" />
                <rect transform="translate(252.9 150.69)" width="2.859" height="2.858" data-name="矩形 14412" />
                <line transform="translate(257.98 152.12)" x2="9.76" data-name="直线 914" />
                <rect transform="translate(252.9 157.8)" width="2.859" height="2.857" data-name="矩形 14413" />
                <line transform="translate(257.98 159.23)" x2="7.205" data-name="直线 915" />
                <path transform="translate(0,-3.729)" d="m261.15 169.27h-11.819v-28.926h1.788" data-name="路径 1939" />
                <path transform="translate(-16.054,-3.729)" d="m289.77 140.34h1.73v9.831" data-name="路径 1940" />
            </g>
            <line transform="translate(262.29 134.18)" x2=".124" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 916" />
        </g>
    </SvgIcon>
}