import { Records } from "@rithe/utils"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useApplicationSelector } from "../../Application/applicationSlice"

export function NotFoundDetector() {
    const notFound = useApplicationSelector(state => {
        const notFoundRecord = state.ui.notFound
        const notFound = Array.from(Records.values(notFoundRecord)).reduce((acc, notFound) => acc && notFound, true)
        return notFound
    })
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        const timer = setTimeout(() => {
            if (notFound) {
                navigate('/404', {
                    state: {
                        path: location.pathname
                    }
                })
            }
        }, 100)
        return () => {
            clearTimeout(timer)
        }
    }, [location.pathname, navigate, notFound])
    return null
}