import React, { ReactNode } from "react"
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'
import { NoAccess } from './NoAccess'

export interface AccessProps {
    access?: string | string[],
    power?: boolean,
    children: ReactNode | ReactNode[]
}

export const Access = ({ access, power, children }: AccessProps) => {
    const authed = useApplicationSelector(state => {
        const accessResources = state.auth.accessResources ?? []
        const poweruser = state.auth.user?.powerUser ?? false
        return (!access || accessResources.some(acc => typeof access === 'string' ? access === acc : access.includes(acc)))
            && (!power || poweruser)
    })

    return <>{React.Children.map(children, (child: any) => {
        if (!child || !child.type || (child.type !== Access && child.type !== NoAccess))
            return authed ? child : null
        return React.cloneElement(child, { access })
    })}</>
}
