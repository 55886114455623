import { ReactElement, useLayoutEffect, useState } from 'react'
import { Provider } from 'react-redux'
import appConfig from '../configs/appConfig'
import appReducer from './appReducer'
import dependencies from './dependencies'
import { SharedStore } from './SharedStore'

interface ReduxProviderProps {
    store: SharedStore,
    children: ReactElement,
}

export function ReduxProvider(props: ReduxProviderProps) {
    const { store, children } = props

    // wait for self and dependencies ready
    const [ready, setReady] = useState(false)
    useLayoutEffect(() => {
        return store.subscribe(() => {
            const state = store.getState()
            const waitlist = [appConfig.appFullName, ...dependencies]
            setReady(waitlist.every(name => state[name]))
        })
    }, [store])

    // mount the sub store when single-spa application mounted
    // unmount the sub store when single-spa application unmounted
    useLayoutEffect(() => {
        return store.mount(appConfig.appFullName, appReducer)
    }, [store])

    return <Provider store={store}>
        {ready ? children : null}
    </Provider>
}