import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { ManageExternalDataIcon } from "./assets/ManageExternalDataIcon"

export interface IntegrationNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function IntegrationNav(props: IntegrationNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<ManageExternalDataIcon />}
        iconId={'Integration_Nav'}
        title={<FormattedMessage id="appbar.integration" />}
        columns={[{
            items: [{
                access: "INT.INT010.VIEW",
                icon: <ManageExternalDataIcon />,
                title: <FormattedMessage id="INT010" />,
                path: '/manageExternalData',
            }, {
                access: "INT.INT020.VIEW",
                icon: <ManageExternalDataIcon />,
                title: <FormattedMessage id="INT020" />,
                path: '/myUpload',
            }, {
                access: "INT.INT030.VIEW",
                icon: <ManageExternalDataIcon />,
                title: <FormattedMessage id="INT030" />,
                path: '/integrationBatch',
            }, {
                access: "INT.INT040.VIEW",
                icon: <ManageExternalDataIcon />,
                title: <FormattedMessage id="INT040" />,
                path: '/externalOrderList',
            }
            ]
        }]}
    />
}
