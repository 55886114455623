import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function DownloadUploadCustomerUsageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.60241 0 0 .60241 -363.3 -213.35)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4080">
            <ellipse transform="translate(617 373.5)" cx="2" cy="1.5" rx="2" ry="1.5" data-name="椭圆 193" />
            <circle transform="translate(627 373.5)" cx="1.5" cy="1.5" r="1.5" data-name="椭圆 194" />
            <line transform="translate(621 374.5)" x2="6" data-name="直线 899" />
            <path transform="translate(-3.749)" d="m619 360h2.406l1.2 12.618" data-name="路径 1901" />
            <path transform="translate(-4.999 -.857)" d="m623 362.92 12.7-0.176a0.694 0.694 0 0 1 0.707 0.757l-0.569 5.586a0.688 0.688 0 0 1-0.581 0.611l-8.586 1.3" data-name="路径 1902" />
            <path transform="translate(-12.185,-2.499)" d="m646 368h1.375a1.4 1.4 0 0 1 1.375 1.428v17.136" data-name="路径 1903" />
            <path transform="translate(-.937 -2.499)" d="m610 386.56v-17.136a1.4 1.4 0 0 1 1.375-1.428h5.5" data-name="路径 1904" />
            <path transform="translate(607 384.5)" d="m0.5 0h31a0.5 0.5 0 0 1 0.5 0.5 2.5 2.5 0 0 1-2.5 2.5h-27a2.5 2.5 0 0 1-2.5-2.5 0.5 0.5 0 0 1 0.5-0.5z" data-name="矩形 14405" />
            <line transform="translate(621.78 385.44)" x2="2.063" data-name="直线 900" />
        </g>
    </SvgIcon>
}