import { Badge, Grid, makeStyles, Popover, Typography } from '@material-ui/core'
import { ChatBubbleOutline, InfoOutlined, StarBorder } from '@material-ui/icons'
import { Arrays } from '@rithe/utils'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { NeumorphismButton } from '../../../components/NeumorphismButton/NeumorphismButton'
import { applicationActions, useApplicationSelector } from '../../Application/applicationSlice'
import { AccountingNav } from '../Nav/AccountingNav/AccountingNav'
import { DashboardNav } from '../Nav/DashboardNav/DashboardNav'
import { IntegrationNav } from '../Nav/IntegrationNav/IntegrationNav'
import { LogisticsNav } from '../Nav/LogisticsNav/LogisticsNav'
import { MasterNav } from '../Nav/MasterNav/MasterNav'
import { MtNav } from '../Nav/MtNav/MtNav'
import { OrderNav } from '../Nav/OrderNav/OrderNav'
import { PrivilegeNav } from '../Nav/PrivilegeNav/PrivilegeNav'
import { SmtNav } from '../Nav/SmtNav/SmtNav'
import { NavBar } from '../NavBar/NavBar'
import { NotificationRoot } from '../Notification/NotificationRoot'
import { User } from '../User/User'
import logo from './logo.svg'
import { PageIcon } from './PageIcon'
import { pageInfoList } from './PageInfoList'

export function AppBar() {
    const classes = useStyles()
    return <div className={classes.container}>
        <div className={classes.root}>
            <Link to="/home" className={classes.logoContainer}>
                <Logo />
            </Link>
            <div className={classes.navContainer} >
                <Navigations />
            </div>
            <div className={classes.actionContainer}>
                <FavouirtePage />
            </div>
            <div className={classes.actionContainer}>
                <Actions />
            </div>
        </div>
    </div>
}

interface FavouritePage {
    pageId: number
    pageCode: string
    pageName?: string
    userId: number
    cbdsUid: string
    resourceId: number
    resourceCode: string
    url?: string
    createdDate: Date
    updatedDate: Date
}
const FavouirtePage = () => {
    const [notificationAnchorEl, setNotificationAnchorEl] = useState<HTMLButtonElement | null>(null)
    const favouritePages = useApplicationSelector(state => state.auth.favouritePages)
    const resources = useApplicationSelector(state => state.auth.accessResources)

    const navigate = useNavigate()
    const classes = useStyles()

    let list = [] as FavouritePage[]
    if (favouritePages === null) {
        list = []
    } else {
        list = favouritePages.filter(item => pageInfoList.map(item => item.pageCode).includes(item.pageCode)).map(item => ({
            ...item,
            url: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].url,
            pageName: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].pageName,
            access: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].access
        })).filter(item => item.access === '' || resources?.includes(item.access))
        list = list.length > 6 ? list.slice(0, 6) : list
    }

    return <>
        <NeumorphismButton onClick={e => setNotificationAnchorEl(e.currentTarget)} style={{ marginRight: 10 }}>
            <StarBorder />
        </NeumorphismButton>
        <Popover
            open={Boolean(notificationAnchorEl)}
            anchorEl={notificationAnchorEl}
            onClose={() => setNotificationAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <Grid container spacing={1} style={{ width: 360, marginTop: 1, marginBottom: 1, paddingLeft: 5, paddingRight: 5 }} className={classes.favouriteCard}>
                {list.map((item, index) => {
                    return <Grid item md={4} key={index}>
                        <div className={classes.icon} onClick={() => navigate(item.url!)}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PageIcon pageCode={item.pageCode} />
                            </div>
                            <Typography align="center" color="textPrimary" style={{ fontSize: 8 }}>{item.pageName}</Typography>
                        </div>
                    </Grid>
                })}
                {list.length < 3 && Arrays.range(0, 3 - list.length).map(item => {
                    return <Grid item md={4} key={item}>
                    </Grid>
                })}
            </Grid>
        </Popover>
    </>
}

function Logo() {
    return <img src={logo} alt="logo" style={{ width: 72, height: 42 }} />
}

function Navigations() {
    const [openId, setOpenId] = useState('')
    const handleOpen = useCallback((id: string) => {
        setOpenId(id)
    }, [])
    const handleClose = useCallback((id: string) => {
        setOpenId(openId => openId === id ? '' : openId)
    }, [])

    return <NavBar>
        <OrderNav
            id="order"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <LogisticsNav
            id="logistics"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <AccountingNav
            id="accounting"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <MtNav
            id="mt"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <DashboardNav
            id="dashboard"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <MasterNav
            id="master"
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <PrivilegeNav
            id='privilege'
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <SmtNav
            id='smt'
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
        <IntegrationNav
            id='integration'
            openId={openId}
            onOpen={handleOpen}
            onClose={handleClose}
        />
    </NavBar>
}

function Actions() {

    const activateChat = useApplicationSelector(state => state.auth.user?.activateChat)
    const unreadMessageCount = useApplicationSelector(state => state.onlineChat.unreadMessageCount)
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
        dispatch(applicationActions.toggleOnlineChat())
    }, [dispatch])

    const [notificationAnchorEl, setNotificationAnchorEl] = useState<HTMLButtonElement | null>(null)

    const classes = useStyles()
    return <>
        <NeumorphismButton className={classes.notification} onClick={e => setNotificationAnchorEl(e.currentTarget)} >
            <InfoOutlined />
        </NeumorphismButton>
        <Popover
            open={Boolean(notificationAnchorEl)}
            anchorEl={notificationAnchorEl}
            onClose={() => setNotificationAnchorEl(null)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <NotificationRoot />
        </Popover>
        {activateChat &&
            <Badge badgeContent={unreadMessageCount} classes={{ badge: classes.badge }}>
                <NeumorphismButton className={classes.notification} onClick={handleClick}>
                    <ChatBubbleOutline />
                </NeumorphismButton>
            </Badge>
        }
        <User />
    </>
}

const useStyles = makeStyles(theme => ({
    container: {
        position: 'fixed',
        width: '100%',
        height: theme.layout.appbarHeight,
        top: 0,
        left: 0,
        padding: `${theme.spacing(1.5)}px ${theme.spacing(12)}px`,
        zIndex: theme.zIndex.appBar,
    },
    root: {
        width: '100%',
        height: '100%',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(4.5)}px`,
        borderRadius: theme.spacing(1.5),
        boxSizing: 'border-box',
        borderWidth: 1.5,
        borderStyle: 'solid',
        borderColor: theme.palette.background.appbar.light,
        background: theme.palette.background.appbar.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        boxShadow: theme.shadows[3],
    },
    logoContainer: {
        flex: '0 0 auto',
        display: 'flex',
    },
    navContainer: {
        flex: '1 1 auto',
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    actionContainer: {
        flex: '0 0 auto',
    },
    navTrackNode: {
        flex: `0 0 ${theme.spacing(5)}px`,
    },
    navTrack: {
        flex: '1 1 auto',
        overflow: 'hidden',
        position: 'relative',
    },
    nav: {
        whiteSpace: 'nowrap',
        width: 'fit-content',
        position: 'relative',
        transition: `left ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
    },
    notification: {
        width: 32,
        marginRight: theme.spacing(1.5),
    },
    badge: {
        transform: 'scale(0.8) translate(-5px, -5px)',
        backgroundColor: theme.palette.success.main,
        color: 'white',
        width: 16,
        height: 16,
        minWidth: 16,
        borderRadius: 8,
    },
    icon: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },

    },
    favouriteCard: {
        background: theme.palette.background.appbar.main,
        border: theme.palette.background.appbar.main,
    }
}))