import { createContext, ReactNode, useEffect, useState } from "react"
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3"
import { useDispatch } from "react-redux"
import appConfig from "../../configs/appConfig"
import { forgetPasswordActions, useForgetPasswordSelector } from "./forgetPasswordSlice"

declare module 'react-recaptcha-v3' {
    function loadReCaptcha(siteKey: string, callback: () => void): void
}

export const RecaptchaContext = createContext<{ recaptcha: ReCaptcha | null }>(undefined as any)

export function RecaptchaProvider({ children }: { children: ReactNode }) {
    const dispatch = useDispatch()
    useEffect(() => {
        const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLElement | null
        if (recaptchaBadge) {
            recaptchaBadge.style.display = 'block'
        }

        loadReCaptcha(appConfig.reCaptchaSiteKey, () => {
            dispatch(forgetPasswordActions.setRecaptchaLoaded(true))
        })
        return () => {
            const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLElement | null
            if (recaptchaBadge) {
                recaptchaBadge.style.display = 'none'
            }
        }
    }, [dispatch])

    const recaptchaLoaded = useForgetPasswordSelector(state => state.recaptchaLoaded)
    const [recaptcha, setRecaptcha] = useState<ReCaptcha | null>(null)
    return <>
        {recaptchaLoaded ? <ReCaptcha
            ref={ref => setRecaptcha(ref)}
            sitekey={appConfig.reCaptchaSiteKey}
            action="reset-password"
            verifyCallback={(token) => dispatch(forgetPasswordActions.setRecaptchaToken(token))}
        /> : null}
        <RecaptchaContext.Provider value={{ recaptcha }}>
            {children}
        </RecaptchaContext.Provider>
    </>
}