import { ClickAwayListener, Divider, Grow, makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import { ReactNode } from 'react'
import { NavMenuColumn, NavMenuColumnProps } from '../NavMenuColumn/NavMenuColumn'

export interface NavMenuProps {
    title: ReactNode,
    columns: Omit<NavMenuColumnProps, 'onMenuClose'>[],
    anchorEl: HTMLElement | null,
    open: boolean,
    onClose: () => void,
    menuPlacement: 'bottom-start' | 'bottom' | 'bottom-end',
}

export function NavMenu(props: NavMenuProps) {
    const {
        title,
        columns,
        anchorEl,
        open,
        onClose,
        menuPlacement,
    } = props

    const classes = useStyles()
    return <Popper
        open={open}
        anchorEl={anchorEl}
        placement={menuPlacement}
        transition
        className={classes.popper}
    >
        {({ TransitionProps }) =>
            <ClickAwayListener onClickAway={onClose}>
                <Grow {...TransitionProps}>
                    <Paper className={classes.paper}>
                        <Typography
                            variant="h4"
                            className={classes.title}
                        >
                            {title}
                        </Typography>
                        <Divider className={classes.divider} />
                        <div className={classes.content}>
                            {columns.map((column, index) => <NavMenuColumn
                                key={index}
                                onMenuClose={onClose}
                                {...column} />)}
                        </div>
                    </Paper>
                </Grow>
            </ClickAwayListener>
        }
    </Popper >
}

const useStyles = makeStyles(theme => ({
    popper: {
        marginTop: theme.spacing(3),
        zIndex: theme.zIndex.modal,
        backdropFilter: 'blur(10px)',
    },
    paper: {
        background: 'rgba(1, 38, 72, 0.8)',
        padding: theme.spacing(2, 3),
        transformOrigin: '0px 0px',
    },
    title: {
        fontWeight: 300,
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    divider: {
        backgroundColor: 'transparent',
        borderTop: '1px dotted rgba(255,255,255,0.6)',
    },
    content: {
        width: `calc(100% + ${theme.spacing(4)}px)`,
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
}))