import { AxiosInstance, AxiosResponse } from 'axios'

interface GetCodeCategoryResult {
    codeCategoryId: number,
    codeCategory: string,
    language: string,
    codeValue: number,
    codeName: string,
}

const codeCategoryApi = {
    getCodeCategories: function (axiosInstance: AxiosInstance): Promise<AxiosResponse<GetCodeCategoryResult[]>> {
        return axiosInstance.post('/lcbm-system-master-api/api/getCategory')
    },
}

export default codeCategoryApi