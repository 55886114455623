import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function BusinessUnitListScreenIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6295 0 0 .6295 -79.458 -29.012)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 155">
            <path transform="translate(-5.189,-7.633)" d="m153.95 80.171v-2.084c0-0.792-1.911-2.08-3.473-2.08s-3.475 1.287-3.475 2.08v2.084" data-name="路径 218" />
            <circle transform="translate(137.64 60.032)" cx="7.643" cy="7.643" r="7.643" data-name="椭圆 57" />
            <path transform="translate(-5.799,-5.189)" d="m151.08 73.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 219" />
            <path transform="translate(-2.747,-2.444)" d="m145.95 61.087c0-0.792-1.911-2.08-3.473-2.08s-3.475 1.287-3.475 2.08v2.084" data-name="路径 220" />
            <path transform="translate(-3.357)" d="m143.08 56.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 221" />
            <path transform="translate(-7.63,-2.444)" d="m155 61.087c0-0.792 1.911-2.08 3.473-2.08s3.475 1.287 3.475 2.08v2.084" data-name="路径 222" />
            <path transform="translate(-8.241)" d="m159.08 56.529a2.176 2.176 0 0 0 2.084-2.37v-0.791a2.1 2.1 0 1 0-4.169 0v0.791a2.176 2.176 0 0 0 2.085 2.37z" data-name="路径 223" />
            <path transform="translate(-10.682,-7.328)" d="m171.25 79.171v-2.084c0-0.792-1.911-2.08-3.473-2.08a4.819 4.819 0 0 0-2.78 1.068" data-name="路径 224" />
            <path transform="translate(-10.988,-4.883)" d="m168.08 72.529a2.176 2.176 0 0 1-2.084-2.37v-0.791a2.1 2.1 0 1 1 4.169 0v0.791a2.176 2.176 0 0 1-2.085 2.37z" data-name="路径 225" />
            <path transform="translate(0,-7.328)" d="m130 79.171v-2.084c0-0.792 1.911-2.08 3.473-2.08a4.819 4.819 0 0 1 2.781 1.068" data-name="路径 226" />
            <path transform="translate(-.61 -4.883)" d="m134.08 72.529a2.176 2.176 0 0 0 2.084-2.37v-0.791a2.1 2.1 0 1 0-4.169 0v0.791a2.176 2.176 0 0 0 2.085 2.37z" data-name="路径 227" />
            <path transform="translate(-8.479,-9.157)" d="m160.24 81 5.9 5.9a1.39 1.39 0 0 1 0 1.966l-0.491 0.491a1.389 1.389 0 0 1-1.965 0l-5.9-5.9" data-name="路径 228" />
        </g>
    </SvgIcon>
}