import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function CompanyListScreenIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62949 0 0 .62948 -180.17 -81.252)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 154">
            <path transform="translate(-.305 -5.494)" d="m320.18 153v10.422a2.084 2.084 0 0 1-2.084 2.084h-25.013a2.084 2.084 0 0 1-2.084-2.084v-10.422" data-name="路径 215" />
            <rect transform="translate(298,149)" width="3" height="4" data-name="矩形 149" />
            <rect transform="translate(310,149)" width="3" height="4" data-name="矩形 150" />
            <path transform="translate(-4.578)" d="m314.03 138.47v-2.084a1.389 1.389 0 0 0-1.39-1.39h-6.252a1.39 1.39 0 0 0-1.39 1.39v2.084" data-name="路径 216" />
            <line transform="translate(301,150)" x1="9" data-name="直线 57" />
            <path transform="translate(0,-1.526)" d="m297.64 151.12h-1.39c-2.319 0-6.253-1.26-6.253-3.474v-6.253a1.389 1.389 0 0 1 1.39-1.39h27.792a1.389 1.389 0 0 1 1.39 1.39v6.253c0 2.214-3.934 3.474-6.253 3.474h-1.39" data-name="路径 217" />
        </g>
    </SvgIcon>
}