import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function CoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53042 0 0 .53042 -126.5 -67.551)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4084">
            <g strokeLinejoin="round">
                <line transform="translate(254.68 156.67)" x2="9.483" data-name="直线 906" />
                <path transform="translate(0,-8.969)" d="m255.38 158.62a1.8 1.8 0 0 0-2.286 0.813l-2.9 5.832-0.676-2.991 2.334-3.341v-4.622l-6.793 6.055a2.834 2.834 0 0 0-0.525 2.3l0.365 9.43c0.056 0.729 0.169 1.029 0.906 1.178l3.429 0.641c0.512 0.091 0.966 0.246 1.375-0.292 0.631-0.831 1.865-2.455 2.15-2.859a25.688 25.688 0 0 0 1.539-4.1c0.539-1.575 2.364-5.984 2.364-5.984a1.766 1.766 0 0 0-1.282-2.06z" data-name="路径 1925" />
                <line transform="translate(251.85 139.97)" y2="5.379" data-name="直线 907" />
                <path transform="translate(-11.891,-12.945)" d="m282.42 182.68c-2.131 0-3.671-1.053-5.683-3.884a17.187 17.187 0 0 0-3.3-3.471c-0.69-0.572-1.069-0.888-0.981-1.3a2.338 2.338 0 0 1 1.625-1.378 1.771 1.771 0 0 1 0.481-0.063 3.729 3.729 0 0 1 2.382 1.082l-1.755-7.922a1.709 1.709 0 0 1 1.295-2.04 1.644 1.644 0 0 1 0.38-0.045 1.744 1.744 0 0 1 1.666 1.338l1.053 4.754a1.672 1.672 0 0 1 1.417-0.715 1.594 1.594 0 0 1 1.435 0.882 1.785 1.785 0 0 1 1.457-0.8 1.723 1.723 0 0 1 1.67 1.34l0.014 0.068a1.719 1.719 0 0 1 1.269-0.563 1.664 1.664 0 0 1 1.632 1.31l1.06 4.785c0.422 1.907-0.507 5.305-4.8 6.308a10.227 10.227 0 0 1-2.317 0.314z" data-name="路径 1926" />
                <line transform="translate(253.18 160.77)" x2="7.522" data-name="直线 908" />
                <path transform="translate(-5.445)" d="m257.3 152.97v-19.195a0.543 0.543 0 0 1 0.542-0.542h20.369a0.543 0.543 0 0 1 0.542 0.542v23" data-name="路径 1927" />
            </g>
            <g strokeMiterlimit="10">
                <path transform="translate(-14.116,-7.271)" d="m280.53 151.75a1.424 1.424 0 1 1-1.424-1.424 1.423 1.423 0 0 1 1.424 1.424z" data-name="路径 1928" />
                <path transform="translate(-16.805,-4.102)" d="m286.85 144.3a1.424 1.424 0 1 1-1.424-1.423 1.423 1.423 0 0 1 1.424 1.423z" data-name="路径 1929" />
                <path transform="translate(-7.86,-8.325)" d="m265.82 154.22a1.424 1.424 0 1 1-1.421-1.425 1.424 1.424 0 0 1 1.421 1.425z" data-name="路径 1930" />
                <path transform="translate(-10.55,-5.155)" d="m272.14 146.78a1.424 1.424 0 1 1-1.424-1.424 1.423 1.423 0 0 1 1.424 1.424z" data-name="路径 1931" />
                <line transform="translate(257.47 142.72)" x2="1.77" y1="2.085" data-name="直线 909" />
                <line transform="translate(261.36 142.39)" x2="2.396" y2="1.366" data-name="直线 910" />
                <line transform="translate(265.9 141.32)" x2="1.851" y1="2.064" data-name="直线 911" />
            </g>
        </g>
    </SvgIcon>
}