import { makeStyles, Typography } from "@material-ui/core"
import { Check } from "@material-ui/icons"
import clsx from 'clsx'
import { useResetPasswordSelector } from "./resetPasswordSlice"

export function PasswordCheckList() {
    const styles = useStyles()
    return <div className={styles.root}>
        <PasswordCheckItem0 />
        <PasswordCheckItem1 />
        <PasswordCheckItem2 />
        <PasswordCheckItem3 />
        <PasswordCheckItem4 />
        <PasswordCheckItem5 />
    </div>
}

function PasswordCheckItem0() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[0])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ At least 8 characters."}
    </Typography>
}

function PasswordCheckItem1() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[1])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ Includes at least 1 number."}
    </Typography>
}

function PasswordCheckItem2() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[2])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ Includes at least 1 lowercase letter."}
    </Typography>
}

function PasswordCheckItem3() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[3])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ Includes at least 1 uppercase letter."}
    </Typography>
}

function PasswordCheckItem4() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[4])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ Includes at least 1 special character, e.g., ! @ # ?"}
    </Typography>
}

function PasswordCheckItem5() {
    const pass = useResetPasswordSelector(state => state.passwordCheckList[5])
    const styles = useStyles()
    return <Typography className={clsx(styles.item, pass || styles.itemFail)}>
        <Check fontSize="small" className={clsx(styles.icon, pass || styles.iconFail)} />
        {"▪ No '<', '>' and other forbidden characters."}
    </Typography>
}

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    item: {
        display: "flex",
        alignItems: "center",
    },
    itemFail: {
        color: theme.palette.grey[500],
    },
    icon: {
        height: 18,
        color: theme.palette.success.main,
    },
    iconFail: {
        opacity: 0,
    },
}))