import { ListItem, makeStyles, MenuList, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { ReactNode } from 'react'
import { Access } from '../../../components/Access/Access'
import { useApplicationSelector } from "../../Application/applicationSlice"
import { NavMenuItem, NavMenuItemProps } from '../NavMenuItem/NavMenuItem'

export interface NavMenuColumnProps {
    name?: ReactNode,
    onMenuClose: () => void,
    items: (Omit<NavMenuItemProps, 'onMenuClose'> & { access?: string | string[], power?: boolean })[],
}

export function NavMenuColumn(props: NavMenuColumnProps) {
    const { name, onMenuClose, items } = props
    const classes = useStyles()

    const accessResources = useApplicationSelector(state => state.auth.accessResources ?? [])
    const poweruser = useApplicationSelector(state => state.auth.user?.powerUser ?? false)
    const hidden = (category?: ReactNode) => {
        const list = category ? items.filter(i => i.category === category) : items
        return list.every(({ access, power }) => {
            return (access && !accessResources.some(acc => typeof access === 'string' ? access === acc : access.includes(acc)))
                || (power && !poweruser)
        })
    }

    const categorys = [] as ReactNode[]
    items.forEach(i => {
        if (i.category && !categorys.includes(i.category)) {
            categorys.push(i.category)
        }
    })
    const opens = [] as boolean[]
    const setOpens = [] as React.Dispatch<React.SetStateAction<boolean>>[]
    categorys.forEach(category => {
        const item = items.filter(i => i.category === category)[0]
        if (item) {
            opens.push(item.open!)
            setOpens.push(item.setOpen!)
        }
    })
    const displayCategoryCount = categorys.map(c => hidden(c)).filter(item => item === false).length

    return hidden()
        ? null
        : <MenuList className={classes.root}>
            {name && <ListItem className={classes.label}>
                <Typography variant="body1">
                    {name}
                </Typography>
            </ListItem>}
            {categorys.length > 0 ? categorys.map((category, index) => {
                return <>
                    {hidden(category) ? null : displayCategoryCount < 2 ? null :
                        < >
                            {index !== 0 && <div style={{ height: 5 }}></div>}
                            <div onClick={() => setOpens[index](!opens[index])} className={classes.title} >
                                <Typography variant="body2" className={classes.sublabel}>
                                    {category}
                                </Typography>
                                {opens[index] ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
                            </div>
                        </>}
                    {items.filter(i => i.category === category).map(item => {
                        const navMenuItem = item.open ? <NavMenuItem
                            key={item.path}
                            icon={item.icon}
                            title={item.title}
                            path={item.path}
                            onMenuClose={onMenuClose}
                        /> : <></>
                        return item.access
                            ? <Access key={item.path} access={item.access} power={item.power}>{navMenuItem}</Access>
                            : navMenuItem
                    })
                    }
                </>
            }) : items.map(item => {
                const navMenuItem = <NavMenuItem
                    key={item.path}
                    icon={item.icon}
                    title={item.title}
                    path={item.path}
                    onMenuClose={onMenuClose}
                />
                return item.access
                    ? <Access key={item.path} access={item.access} power={item.power}>{navMenuItem}</Access>
                    : navMenuItem
            })}
        </MenuList>
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: `0px ${theme.spacing(1)}px`
    },
    label: {
        color: theme.palette.common.white,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(51, 183, 240, 0.7)',
        borderRadius: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.main,
        },
    },
    icon: {
        height: 17,
        color: theme.palette.common.white,
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    sublabel: {
        width: 220,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}))