import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ShippingRouteListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6295 0 0 .62952 -28.468 -131.09)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 162">
            <line transform="translate(52,233)" x1="2" data-name="直线 84" />
            <path transform="translate(0,-4.36)" d="m51.658 232.32-2.658-0.665v-2.658" data-name="路径 258" />
            <path transform="translate(-3.019,-7.379)" d="m63.981 241.32v-3.323h-5.981v3.323" data-name="路径 259" />
            <line transform="translate(62,233)" x2="2" data-name="直线 85" />
            <path transform="translate(-7.715,-4.36)" d="m72 232.32 2.658-0.665v-2.658" data-name="路径 260" />
            <path transform="translate(-.671 -10.063)" d="m51 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 261" />
            <path transform="translate(-6.708,-10.063)" d="m69 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 262" />
            <path transform="translate(-13.081,-10.063)" d="m88 246v1.994a0.665 0.665 0 0 0 0.665 0.665h1.994a0.665 0.665 0 0 0 0.665-0.665v-1.994" data-name="路径 263" />
            <rect transform="translate(54,225)" width="8" height="3" data-name="矩形 158" />
            <path transform="translate(0,-1.342)" d="m64.285 220v9.969l0.665 0.665a1.834 1.834 0 0 1 0.665 1.329v2.658h1.329v2.658h-17.944v-2.658h1.329v-2.658a1.834 1.834 0 0 1 0.665-1.329l0.665-0.665v-9.969" data-name="路径 264" />
            <path transform="translate(-1.342,-2.683)" d="m65.627 225.33-0.817-0.759a2.123 2.123 0 0 0-1.841-0.57h-7.31a2.123 2.123 0 0 0-1.841 0.57l-0.817 0.759" data-name="路径 265" />
            <path d="m49 223.31v-7.31h17.944v7.31" data-name="路径 266" />
            <path transform="translate(-9.056)" d="m76 216h0.665l11.962 7.975v11.962h-11.3" data-name="路径 267" />
        </g>
    </SvgIcon>
}