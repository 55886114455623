import { Navigate, useLocation } from "react-router"
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'

export interface AuthNavigateProps {
    access?: string | string[],
    power?: boolean,
    children: React.ReactNode,
}

export function AuthNavigate(props: AuthNavigateProps): JSX.Element {
    const { access, power, children } = props
    const loading = useApplicationSelector(state => Boolean(!state.auth.token || !state.auth.accessResources || !state.auth.user || !state.auth.roles || !state.auth.resources || !state.auth.userCompanies))
    const authenticated = useApplicationSelector(state => Boolean(state.auth.token))
    const authorized = useApplicationSelector(state => Boolean(state.auth.token
        && !(power && !state.auth.user?.powerUser)
        && !(access && !state.auth.accessResources?.some(acc => typeof access === 'string' ? access === acc : access.includes(acc)))))
    const location = useLocation()

    if (loading) {
        return <></>
    } else {
        if (!authenticated) {
            return <Navigate to="/login" state={{ from: location }} replace />
        } else if (!authorized) {
            return <Navigate to="/403" replace />
        } else {
            return <>{children}</>
        }
    }
}