import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ChangeOrderForecastIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.53255 0 0 .53255 -184 -184.63)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4083">
            <path transform="translate(-5.326)" d="m384.74 360.59a14.257 14.257 0 0 0-23.482 0" data-name="路径 1919" />
            <path transform="translate(-22.536,-1.976)" d="m402.2 358.65-0.228 3.921-3.92-0.228" data-name="路径 1920" />
            <path transform="translate(-5.547,-20.592)" d="m361.73 398.45a14.257 14.257 0 0 0 23.482 0" data-name="路径 1921" />
            <path transform="translate(-5.326,-20.592)" d="m361.25 402.37 0.228-3.92 3.92 0.228" data-name="路径 1922" />
            <path transform="translate(-25.159,-8.163)" d="m406.98 371.88v2.883l-0.5-0.247-0.572 0.281-0.572-0.281-0.576 0.282-0.577-0.282-0.525 0.256v-2.892" data-name="路径 1923" />
            <rect transform="translate(374.1 363.71)" width="12.122" height="11.562" data-name="矩形 14406" />
            <path transform="translate(-3.865,-8.259)" d="m361.45 372.08v2.883l-0.5-0.247-0.572 0.281-0.572-0.281-0.576 0.282-0.577-0.282-0.525 0.256v-2.892" data-name="路径 1924" />
            <rect transform="translate(349.87 363.82)" width="12.122" height="11.562" data-name="矩形 14407" />
        </g>
    </SvgIcon>
}