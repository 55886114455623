import { makeStyles, Typography } from "@material-ui/core"
import { condition } from "@rithe/utils"
import { FormattedDate } from "react-intl"

interface AnnouncementItemProps {
    id: string,
    type: string,
    title: string,
    content: string,
    publishAt: number,
}

export const AnnouncementItem = (props: AnnouncementItemProps) => {
    const { id, type, title, content, publishAt } = props

    const classes = useStyles()
    return <div className={classes.root}>
        <div id={'a-' + id} className={classes.titleLine}>
            <Typography variant="body1" component="span" className={
                condition(type)
                    .case('NEW').then(() => classes.tagNew)
                    .case('IMPROVEMENT').then(() => classes.tagImprovement)
                    .case('MAINTENANCE').then(() => classes.tagMaintenance)
                    .default(() => classes.tagUnknown)
            }>{type}</Typography>
            <Typography variant="h4" component="a" href={`#a-${id}`} className={classes.title}>
                {title}
            </Typography>
            <Typography variant="body2" component="span" className={classes.date}>
                <FormattedDate value={publishAt} dateStyle="medium" />
            </Typography>
        </div>
        <div className={classes.contentLine}>
            <Typography variant="body2">
                {content}
            </Typography>
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    titleLine: {
        paddingBottom: theme.spacing(2),
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    tagNew: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#16cad3',
        color: theme.palette.common.white,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    tagImprovement: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#2d9cef',
        color: theme.palette.common.white,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    tagMaintenance: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#df5988',
        color: theme.palette.common.white,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    tagUnknown: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.common.white,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    title: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    date: {
        whiteSpace: 'nowrap',
        lineHeight: 3,
        float: 'right',
    },
    contentLine: {
        paddingBottom: theme.spacing(2),
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
}))