import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function DownloadOutboundInstructionIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.59549 0 0 .59549 -578.44 -319.31)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4107">
            <path transform="translate(-53.076,-81.66)" d="m1046.5 650.82" data-name="路径 1993" />
            <path transform="translate(-65.445,-6.845)" d="m1062.8 551.96h5.52v6.343" data-name="路径 1994" />
            <line transform="translate(983.5 545.11)" x2="12.244" data-name="直线 948" />
            <path transform="translate(0,-6.845)" d="m987.67 576h-11.326v-24.043h5.52" data-name="路径 1995" />
            <g transform="translate(981.86 542.91)" data-name="组 4105">
                <path transform="translate(-1042.2 -542.91)" d="m1056.9 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1996" />
                <path transform="translate(-999.04 -542.91)" d="m999.85 547.31a0.817 0.817 0 0 1-0.817-0.817v-2.766a0.817 0.817 0 0 1 0.817-0.817 0.817 0.817 0 0 1 0.817 0.817v2.766a0.817 0.817 0 0 1-0.817 0.817z" data-name="路径 1997" />
            </g>
            <line transform="translate(976.34 549.74)" x2="26.552" data-name="直线 949" />
            <rect transform="translate(984.4 553.49)" width="2.697" height="2.697" data-name="矩形 14416" />
            <rect transform="translate(979.92 558.1)" width="2.697" height="2.697" data-name="矩形 14417" />
            <rect transform="translate(984.4 558.1)" width="2.697" height="2.697" data-name="矩形 14418" />
            <rect transform="translate(984.4 562.7)" width="2.697" height="2.697" data-name="矩形 14419" />
            <rect transform="translate(979.92 562.7)" width="2.697" height="2.697" data-name="矩形 14420" />
            <g transform="translate(990.84 553.42)" data-name="组 4106">
                <line transform="translate(10.909,11.698)" x2="4.894" data-name="直线 950" />
                <line transform="translate(10.909,13.57)" x2="4.894" data-name="直线 951" />
                <path transform="translate(-1035.9 -628.35)" d="m1035.9 641.92" data-name="路径 1998" />
                <path transform="translate(-1035.9 -586.13)" d="m1053.8 588.29v12.88a0.69 0.69 0 0 1-0.69 0.69h-16.5a0.69 0.69 0 0 1-0.69-0.69v-14.348a0.7 0.7 0 0 1 0.7-0.7h16.5a0.69 0.69 0 0 1 0.69 0.69v12.888" data-name="路径 1999" />
                <path transform="translate(-1055.4 -586.13)" d="m1067.1 586.13h-5.346v2.848l0.784 0.688 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.63-0.63 0.63 0.63 0.784-0.688z" data-name="路径 2000" />
            </g>
        </g>
    </SvgIcon>
}