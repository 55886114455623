import { makeStyles } from "@material-ui/core"
import { AppBar } from "./AppBar/AppBar"
import { NotFoundDetector } from "./NotFoundDetector/NotFoundDetector"

export function Workbench() {
    const classes = useStyles()
    return <div className={classes.root}>
        <AppBar />
        <NotFoundDetector />
    </div>
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
})