import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function ShippingDetailListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.19144 0 0 .19144 -3.4992 -2.3581)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polyline points="87.39 36.35 99.46 36.35 99.46 57.06" />
            <polyline points="99.46 114.33 99.46 123.77 30.77 123.77 30.77 36.35 42.28 36.35" />
            <path d="m85.27 32.67h-16.9c0.44-0.66 0.7-1.45 0.7-2.3 0-2.29-1.85-4.14-4.14-4.14s-4.14 1.85-4.14 4.14c0 0.85 0.26 1.64 0.7 2.3h-16.9c-0.71 0-1.28 0.57-1.28 1.28v8.48c0 0.71 0.57 1.28 1.28 1.28h40.69c0.71 0 1.28-0.57 1.28-1.28v-8.48c0-0.71-0.57-1.28-1.28-1.28z" />
            <circle cx="64.92" cy="30.37" r=".92" />
            <line x1="43.3" x2="88.38" y1="54.42" y2="54.42" />
            <line x1="43.04" x2="82.73" y1="61.1" y2="61.1" />
            <line x1="42.25" x2="82.73" y1="76.8" y2="76.8" />
            <line x1="41.99" x2="81.73" y1="83.47" y2="83.56" />
            <line x1="42.12" x2="65.38" y1="99.17" y2="99.17" />
            <line x1="41.99" x2="65.25" y1="105.85" y2="105.85" />
            <polygon points="85.72 94.03 86.99 92.29 86.99 87.67 76.81 87.67 76.81 92.07 78.08 94.03 79.35 92.29 80.56 94.03 81.9 92.29 82.94 94.03 84.45 92.29" />
            <line x1="88.26" x2="96.32" y1="108.67" y2="108.67" />
            <line x1="88.29" x2="96.34" y1="111.07" y2="111.07" />
            <rect x="65.35" y="87.43" width="33.74" height="28.02" />
            <polygon points="102.95 64.32 104.22 62.59 104.22 57.96 94.04 57.96 94.04 62.36 95.31 64.32 96.58 62.59 97.79 64.32 99.13 62.59 100.17 64.32 101.67 62.59" />
            <line x1="105.49" x2="113.55" y1="78.96" y2="78.96" />
            <line x1="105.52" x2="113.57" y1="81.36" y2="81.36" />
            <rect x="82.58" y="57.72" width="33.74" height="28.02" />
            <polygon points="119.86 93.93 121.14 92.19 121.14 87.56 110.95 87.56 110.95 91.97 112.23 93.93 113.5 92.19 114.7 93.93 116.05 92.19 117.09 93.93 118.59 92.19" />
            <line x1="122.41" x2="130.46" y1="108.57" y2="108.57" />
            <line x1="122.44" x2="130.49" y1="110.97" y2="110.97" />
            <rect x="99.5" y="87.33" width="33.74" height="28.02" />
        </g>
    </SvgIcon>
}