import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function OutboundMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.57534 0 0 .57534 -433.93 -308.88)" fill="none" stroke="currentColor" data-name="组 4094">
            <g strokeMiterlimit="10" strokeWidth="1.2">
                <line transform="translate(780.12 563.58)" x2="7.503" data-name="直线 927" />
                <line transform="translate(780.12 566.45)" x2="7.503" data-name="直线 928" />
                <path transform="translate(-14.195,-67.538)" d="m777.59 633.99" data-name="路径 1965" />
                <path transform="translate(-14.195)" d="m777.59 554.21v-7.489a1.066 1.066 0 0 1 1.066-1.066h25.29a1.058 1.058 0 0 1 1.058 1.058v19.744" data-name="路径 1966" />
                <path transform="translate(-14.195,-10.767)" d="m805.01 559.74v19.745a1.057 1.057 0 0 1-1.057 1.057h-25.3a1.058 1.058 0 0 1-1.058-1.059v-2.257" data-name="路径 1967" />
                <path transform="translate(-45.392)" d="m826.59 545.65h-8.2v4.367l1.2 1.054 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 0.965-0.965 0.965 0.965 1.2-1.054z" data-name="路径 1968" />
            </g>
            <g transform="translate(759.03 554.6)" data-name="组 4093">
                <g data-name="组 4092">
                    <g data-name="组 4091">
                        <path transform="translate(-759.03 -583.64)" d="m757.25 590.46h7.8l-0.861 3.213 6.963-5.014-4.276-5.014-0.861 3.213h-6.988" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="路径 1969" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
}