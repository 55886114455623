import {Location, Navigate, useLocation} from "react-router-dom";
import {useLoginSelector} from "./loginSlice";

export function RedirectToMfaSetting() {
  const {state} = useLocation()
  const from = (state as {from?: Location})?.from

  const step = useLoginSelector(state => state.step)
  if (step === 'recoverySuccess') {
    const toPathname = from?.pathname
      && from.pathname !== '/login'
      && from.pathname !== '/'
      ? from.pathname
      : '/user-setting/authenticatorApp'
    const toState = from?.state
    return <Navigate to={toPathname} state={toState} />
  } else {
    return null
  }
}