import { useLocation } from "react-router"
import { Navigate } from "react-router-dom"
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'

export function AuthWatcher(): JSX.Element {
    const loading = useApplicationSelector(state => Boolean(state.auth.token) && !Boolean(state.auth.accessResources))
    const authenticated = useApplicationSelector(state => Boolean(state.auth.token))
    const location = useLocation()

    if (location.pathname !== '/login'
        && location.pathname !== '/forget-password'
        && location.pathname !== '/reset-password'
        && !loading
        && !authenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />
    } else {
        return <></>
    }
}