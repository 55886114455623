import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function DownloadDeliveryPlanIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.50707 0 0 .50841 -251.88 -155.15)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4096">
            <path transform="translate(-51.269)" d="m571.22 317.35h-6.073v3.236l0.891 0.781 0.715-0.715 0.715 0.715 0.715-0.715 0.715 0.715 0.715-0.715 0.715 0.715 0.891-0.781z" data-name="路径 1970" />
            <line transform="translate(519.96 330.89)" x2="5.016" data-name="直线 929" />
            <line transform="translate(519.96 332.68)" x2="5.016" data-name="直线 930" />
            <g transform="translate(500.28 324.32)" data-name="组 4095">
                <line transform="translate(2.186)" x2="6.661" data-name="直线 931" />
                <line transform="translate(2.186,4.974)" x2="6.661" data-name="直线 932" />
                <line transform="translate(0,2.5)" x2="6.661" data-name="直线 933" />
            </g>
            <path transform="translate(-109.76 -27.274)" d="m643.69 351.86h-3.99a0.537 0.537 0 0 0-0.537 0.537v2.585a0.537 0.537 0 0 0 0.537 0.537h5.58a0.537 0.537 0 0 0 0.457-0.818l-1.59-2.584a0.537 0.537 0 0 0-0.457-0.257z" data-name="路径 1971" />
            <line transform="translate(516.68 336.27)" x1="12.897" data-name="直线 934" />
            <path transform="translate(-101.18 -21.203)" d="m638.28 357.47h1.574a0.441 0.441 0 0 0 0.441-0.441v-7.242a0.534 0.534 0 0 0-0.093-0.3l-3.442-5.039a0.619 0.619 0 0 0-0.511-0.27h-7.952" data-name="路径 1972" />
            <path transform="translate(-19.511,-54.511)" d="m524.97 386.32v4.457h3.7" data-name="路径 1973" />
            <path transform="translate(-19.511)" d="m546.64 336.27v-18.481a0.438 0.438 0 0 0-0.438-0.438h-20.79a0.438 0.438 0 0 0-0.438 0.438v4.018" data-name="路径 1974" />
            <path transform="translate(-41.073,-65.397)" d="m555.74 401.84a1.744 1.744 0 1 1-1.744-1.744 1.744 1.744 0 0 1 1.744 1.744z" data-name="路径 1975" />
            <path transform="translate(-33.45,-57.775)" d="m550.14 394.22a3.766 3.766 0 1 1-3.766-3.766 3.766 3.766 0 0 1 3.766 3.766z" data-name="路径 1976" />
            <path transform="translate(-118.04 -65.397)" d="m653.12 401.84a1.744 1.744 0 1 1-1.744-1.744 1.744 1.744 0 0 1 1.744 1.744z" data-name="路径 1977" />
            <path transform="translate(-110.42 -57.775)" d="m647.52 394.22a3.766 3.766 0 1 1-3.766-3.766 3.766 3.766 0 0 1 3.766 3.766z" data-name="路径 1978" />
            <line transform="translate(529.77 329.35)" x2="1.354" data-name="直线 935" />
            <line transform="translate(535.28 330.89)" x2="3.845" data-name="直线 936" />
        </g>
    </SvgIcon>
}