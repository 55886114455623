import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function StockAdjustmentIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.21971 0 0 .21971 -5.2762 -4.5826)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="51.4" y="75.89" width="44.82" height="38.5" />
            <rect x="58.15" y="99.64" width="15.05" height="15.05" />
            <rect x="63.79" y="99.64" width="3.76" height="5.02" />
            <rect x="73.28" y="99.64" width="15.05" height="15.05" />
            <rect x="78.92" y="99.64" width="3.76" height="5.02" />
            <rect x="66.12" y="83.84" width="15.05" height="15.05" />
            <rect x="71.76" y="83.84" width="3.76" height="5.02" />
            <line x1="40.49" x2="83.67" y1="64.25" y2="64.25" />
            <polyline points="107.14 75.18 107.14 114.82 40.49 114.82 40.49 64.25" />
            <polyline points="83.67 46.04 73.82 38.39 40.49 64.25" />
            <path d="m120 50.54v19.74c0 2.69-2.18 4.88-4.88 4.88h-3.94" />
            <path d="m111.18 75.15h-22.64c-2.69 0-4.88-2.18-4.88-4.88v-7.1" />
            <line x1="83.67" x2="83.67" y1="46.04" y2="64.25" />
            <path d="m117.66 39.99c-0.86-0.74-1.31-1.17-2.53-1.17h-26.58c-2.69 0-4.88 2.18-4.88 4.88v1.76" />
            <line x1="120" x2="126.14" y1="48.85" y2="44.01" />
            <line x1="118.31" x2="121.22" y1="40.03" y2="37.75" />
            <line x1="104.12" x2="120" y1="61.33" y2="48.85" />
            <line x1="104.12" x2="103.71" y1="61.33" y2="61.66" />
            <line x1="118.31" x2="98.78" y1="40.03" y2="55.39" />
            <line x1="98.78" x2="98.3" y1="55.39" y2="56.81" />
            <polyline points="103.71 61.66 96.43 62.31 98.3 56.81" />
            <line x1="98.78" x2="102.41" y1="55.39" y2="60" />
            <line x1="103.71" x2="102.41" y1="61.66" y2="60" />
            <path d="m121.22 37.75 3-2.36c0.43-0.33 1.04-0.26 1.38 0.16l3.72 4.72c0.33 0.43 0.26 1.04-0.16 1.38l-3 2.36" />
            <line x1="121.22" x2="126.14" y1="37.75" y2="44.01" />
            <line x1="119.2" x2="123.84" y1="39.33" y2="45.22" />
        </g>
    </SvgIcon>
}