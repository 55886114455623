import { AxiosInstance, AxiosResponse } from 'axios'

interface AuthAPIResponse {
    token: string,
    username: string,
    issuedAt: number,
    refreshedAt: number,
    expiredAt: number,
}

interface UserAPIResponse {
    id: string,
    name: String,
    powerUser: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAPIResponseUserAccount,
    cbdss: UserAPIResponseUserCbds[],
}

interface GetMfaStateAPIResponse {
    state: number
}

interface UserAPIResponseUserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserAPIResponseUserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface LoginAPIRequest {
    username: string,
    password: string,
    verificationCode?: string,
    captchaToken?: string,
    verificationMfaCode?: string,
    verificationMfaRecoveryCode?: string
}

interface ChangePasswordAPIRequest {
    oldPassword: string,
    newPassword: string,
}

interface ForgetPasswordAPIRequest {
    username: string,
}

interface ResetPasswordAPIRequest {
    username: string,
    newPassword: string,
    resetPasswordCode: string,
}

interface RandomPasswordAPIRequest {
    username: string,
}

interface RefreshVerificationCodeAPIRequest {
    username: string,
}

interface GetMfaStateAPIRequest {
    username: string,
}

const authApi = {
    login: function (axiosInstance: AxiosInstance, data: LoginAPIRequest) {
        return axiosInstance.post('/common-system-api/login', data) as Promise<AxiosResponse<AuthAPIResponse>>
    },
    changePassword: function (axiosInstance: AxiosInstance, data: ChangePasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/change-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    forgetPassword: function (axiosInstance: AxiosInstance, data: ForgetPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/forget-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    resetPassword: function (axiosInstance: AxiosInstance, data: ResetPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/reset-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    randomPassword: function (axiosInstance: AxiosInstance, data: RandomPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/random-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    refreshVerificationCode: function (axiosInstance: AxiosInstance, data: RefreshVerificationCodeAPIRequest) {
        return axiosInstance.post('/common-system-api/refresh-verification-code', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    getMfaStateNoAuth: function (axiosInstance: AxiosInstance, data: GetMfaStateAPIRequest) {
        return axiosInstance.post('/common-system-api/user/getMfaStateNoAuth', data) as Promise<AxiosResponse<GetMfaStateAPIResponse>>
    },
}

export default authApi
