import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ContractListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64639 0 0 .64639 -28.931 -185.15)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 161">
            <path d="m71.343 297v-6h-21.343v28.012h21.343v-10" data-name="路径 255" />
            <path transform="translate(-5.262,-2.175)" d="m83.833 297.86c-0.782-0.781-2.737-0.093-4.3 1.469l-8.426 8.524c-2.741 2.741-5.969 7.236-5.188 8.018s5.276-2.446 8.018-5.188l8.426-8.524c1.563-1.562 2.251-3.517 1.47-4.299z" data-name="路径 256" />
            <line transform="translate(59.205 313.69)" x2="1.453" y1="1.55" strokeLinecap="round" data-name="直线 75" />
            <path transform="translate(-11.649,-3.071)" d="m85.92 300.22 3.773 3.773a1.334 1.334 0 0 1 0 1.887l-4.716 4.72" data-name="路径 257" />
            <line transform="translate(65.374 306.14)" x2="2.83" y2="2.83" data-name="直线 76" />
            <line transform="translate(78.571 294.34)" x2="1.346" y1="1.346" data-name="直线 77" />
            <line transform="translate(53,315)" x1="4" data-name="直线 78" />
            <line transform="translate(57,296)" x2="7" data-name="直线 79" />
            <line transform="translate(54,299)" x2="13" data-name="直线 80" />
            <line transform="translate(54,302)" x2="13" data-name="直线 81" />
            <line transform="translate(54,304)" x2="10" data-name="直线 82" />
            <line transform="translate(54,307)" x2="7" data-name="直线 83" />
        </g>
    </SvgIcon>
}