import { Divider, makeStyles } from "@material-ui/core"
import { Fragment } from "react"
import { useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { Scrollbar } from "../Scrollbar/Scrollbar"
import { AnnouncementOutline } from "./AnnouncementOutline"

export const AnnouncementCatalog = () => {
    const announcements = useApplicationSelector(state => state.ui.announcements)

    const styles = useStyles()
    return <div className={styles.root}>
        <Scrollbar className={styles.body}>
            {[...announcements].reverse().map((announcement, index) => {
                return <Fragment key={announcement.id}>
                    {index > 0 && <Divider />}
                    <AnnouncementOutline
                        id={announcement.id}
                        type={announcement.type}
                        title={announcement.title}
                        summary={announcement.summary}
                        publishAt={announcement.publishAt}
                    />
                </Fragment>
            })}
        </Scrollbar>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        height: 476,
        overflow: 'hidden',
    },
    body: {
        width: 480,
        height: 476,
        backgroundColor: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(10px)',
    },
}))