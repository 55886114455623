import { debounce, makeStyles, ownerDocument, ownerWindow, useTheme } from '@material-ui/core'
import { detectScrollType, getNormalizedScrollLeft } from 'normalize-scroll-left'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import animate from '../../../utils/animate'
import { NavScrollButton } from './NavScrollButton'

export interface NavBarProps {
    children?: ReactNode | ReactNode[],
}

export function NavBar(props: NavBarProps) {
    const { children } = props

    const scrollerRef = useRef<HTMLDivElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const isRtl = useTheme().direction === 'rtl'

    const [displayScrollButton, setDisplayScrollButton] = useState({ start: false, end: false })
    const updateScrollButtonState = useCallback(() => {
        if (scrollerRef.current) {
            const { scrollWidth, clientWidth } = scrollerRef.current
            const scrollLeft = getNormalizedScrollLeft(scrollerRef.current, isRtl ? 'rtl' : 'ltr')
            setDisplayScrollButton({
                start: isRtl ? scrollLeft < scrollWidth - clientWidth - 1 : scrollLeft > 1,
                end: !isRtl ? scrollLeft < scrollWidth - clientWidth - 1 : scrollLeft > 1,
            })
        }
    }, [isRtl])

    useEffect(() => {
        if (scrollerRef.current && containerRef.current) {
            const handleResize = debounce(updateScrollButtonState)
            const win = ownerWindow(scrollerRef.current)
            win.addEventListener('resize', handleResize)
            let resizeObserver: ResizeObserver | null = null
            if (typeof ResizeObserver !== 'undefined') {
                resizeObserver = new ResizeObserver(handleResize)
                Array.from(containerRef.current.children).forEach(child => {
                    resizeObserver!.observe(child)
                })
            }
            handleResize()
            return () => {
                handleResize.clear()
                win.removeEventListener('resize', handleResize)
                resizeObserver && resizeObserver.disconnect()
            }
        }
    }, [updateScrollButtonState])

    const scrollTo = useCallback((scrollValue: number) => {
        if (scrollerRef.current) {
            animate(scrollerRef.current, 'scrollLeft', scrollValue)
        }
    }, [])

    const scroll = useCallback((delta: number) => {
        if (scrollerRef.current) {
            let scrollValue = scrollerRef.current.scrollLeft
            scrollValue += delta * (isRtl ? -1 : 1)
            scrollValue *= isRtl && detectScrollType() === 'reverse' ? -1 : 1
            scrollTo(scrollValue)
        }
    }, [isRtl, scrollTo])

    const getScrollStep = useCallback(() => {
        if (scrollerRef.current && containerRef.current) {
            const scrollerWidth = scrollerRef.current.clientWidth
            const step = Array.from(containerRef.current!.children)
                .map(child => child.clientWidth)
                .reduce((total, curr) => total + curr <= scrollerWidth ? total + curr : total, 0)
            return step
        }
        return 0
    }, [])

    const handleStartScrollClick = useCallback(() => {
        scroll(-1 * getScrollStep())
    }, [getScrollStep, scroll])

    const handleEndScrollClick = useCallback(() => {
        scroll(getScrollStep())
    }, [getScrollStep, scroll])

    const handleScroll = useCallback(() => {
        debounce(updateScrollButtonState)()
    }, [updateScrollButtonState])

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        const container = containerRef.current!
        const currentFocus = ownerDocument(container).activeElement as HTMLButtonElement
        if (currentFocus.getAttribute('role') !== 'button') {
            return
        }
        const [previousKey, nextKey] = isRtl ? ['ArrowRight', 'ArrowLeft'] : ['ArrowLeft', 'ArrowRight']

        switch (event.key) {
            case previousKey:
                event.preventDefault()
                moveFocus(container, currentFocus, previousNavButton)
                break
            case nextKey:
                event.preventDefault()
                moveFocus(container, currentFocus, nextNavButton)
                break
            case 'Home':
                event.preventDefault()
                moveFocus(container, null, nextNavButton)
                break
            case 'End':
                event.preventDefault()
                moveFocus(container, null, previousNavButton)
                break
            default:
                break
        }
    }, [isRtl])


    const classes = useStyles()
    return <nav className={classes.root}>
        <NavScrollButton
            direction={isRtl ? 'right' : 'left'}
            disabled={!displayScrollButton.start}
            onClick={handleStartScrollClick}
        />
        <div
            ref={scrollerRef}
            onScroll={handleScroll}
            className={classes.scroller}
        >
            <div
                ref={containerRef}
                onKeyDown={handleKeyDown}
                className={classes.container}
            >
                {children}
            </div>
        </div>
        <NavScrollButton
            direction={isRtl ? 'left' : 'right'}
            disabled={!displayScrollButton.end}
            onClick={handleEndScrollClick}
        />
    </nav>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        // Add iOS momentum scrolling for iOS < 13.0
        WebkitOverflowScrolling: 'touch',
    },
    scroller: {
        display: 'inline-block',
        position: 'relative',
        flex: '1 1 auto',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    container: {
        display: 'flex',
    }
}))

function previousNavButton(container: HTMLDivElement, current: HTMLButtonElement | null) {
    if (current && current.previousElementSibling) {
        return current.previousElementSibling as HTMLButtonElement
    }
    return container.lastChild as HTMLButtonElement
}

function nextNavButton(container: HTMLDivElement, current: HTMLButtonElement | null) {
    if (current && current.nextElementSibling) {
        return current.nextElementSibling as HTMLButtonElement
    }
    return container.firstChild as HTMLButtonElement
}

function moveFocus(
    container: HTMLDivElement,
    currentFocus: HTMLButtonElement | null,
    traversalFunction: (container: HTMLDivElement, currentFocus: HTMLButtonElement | null) => HTMLButtonElement
) {
    let loopOnce = false
    let nextFocus = traversalFunction(container, currentFocus)
    while (nextFocus) {
        // prevent infinite loop.
        if (nextFocus === currentFocus) {
            if (loopOnce) {
                return
            }
            loopOnce = true
        }

        const nextFocusDisabled = nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true'
        if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
            // move to next element
            nextFocus = traversalFunction(container, nextFocus)
        } else {
            nextFocus.focus()
            return
        }
    }
}