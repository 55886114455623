import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function GlobalPartsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61035 0 0 .61034 -28.075 -28.462)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 160">
            <g strokeLinecap="round">
                <path transform="translate(-1.177,-7.702)" d="m57.574 88.292v-1.792a1.073 1.073 0 0 1 1.072-1.072h5a1.072 1.072 0 0 0 1.072-1.072v-0.715a1.072 1.072 0 0 1 1.072-1.072h0.715a1.073 1.073 0 0 0 1.075-1.069v-1.428a1.073 1.073 0 0 0-1.072-1.072h-5a1.073 1.073 0 0 0-1.072 1.072 1.073 1.073 0 0 1-1.072 1.072h-5.364" data-name="路径 247" />
                <path transform="translate(-6.882,-7.987)" d="m74 88.577v-1.43a1.429 1.429 0 0 1 1.429-1.429h3.571a1.429 1.429 0 0 0 1.429-1.429 1.429 1.429 0 0 0-1.429-1.43h-0.715a1.429 1.429 0 0 1-1.429-1.429 1.429 1.429 0 0 1 1.432-1.43h5" data-name="路径 248" />
                <path transform="translate(-.321 -5.99)" d="m51 86.58c0-7.895 6.56-13.58 14.652-13.58s14.648 5.685 14.648 13.58" data-name="路径 249" />
            </g>
            <rect transform="translate(58.541,52)" width="13.58" height="12.151" data-name="矩形 157" />
            <path transform="translate(-5.17)" d="m73 52v2.859h-5v-2.859" data-name="路径 250" />
            <line transform="translate(68.548,61.292)" x1="1.429" data-name="直线 73" />
            <line transform="translate(66.403,61.292)" x1="1.429" data-name="直线 74" />
            <g strokeLinecap="round">
                <path transform="translate(-1.608,-4.279)" d="m55.513 68.429a20.739 20.739 0 0 1 2.492-1.429" data-name="路径 251" />
                <path transform="translate(0,-5.42)" d="m49.875 73.083a21.208 21.208 0 0 1 2.135-2.083" data-name="路径 252" />
                <path transform="translate(-11.732,-5.638)" d="m91 71.763a21.215 21.215 0 0 1 2.175 2.314" data-name="路径 253" />
                <path transform="translate(-9.734,-4.279)" d="m84 67a20.72 20.72 0 0 1 2.859 1.68" data-name="路径 254" />
            </g>
        </g>
    </SvgIcon>
}