import { AxiosInstance, AxiosResponse } from "axios"

interface UserSettingAPIResponse {
    daSetting: DaSetting,
}

interface DaSetting {
    ruleBasedRecommendation: boolean,
    aiPageRecommendation: boolean,
}

const userSettingApi = {
    get: function(axiosInstance: AxiosInstance): Promise<
        AxiosResponse<UserSettingAPIResponse>
    > {
        return axiosInstance.get('/common-system-api/user/setting')
    },
}

export default userSettingApi