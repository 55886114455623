import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function ProjectIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.28531 0 0 .28531 -9.257 -9.3303)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="m109.03 105.77c-0.06 1.69-1.43 3.04-3.13 3.04h-0.48" />
            <path d="m109.04 105.66s0 0.07-0.01 0.11" />
            <path d="m97.54 56.06h8.37c1.74 0 3.14 1.41 3.14 3.14v7.18" />
            <path d="m40.97 105.77s-0.01-0.07-0.01-0.11" />
            <path d="m52.46 56.06h-8.37c-1.74 0-3.14 1.41-3.14 3.14v19.29" />
            <path d="m44.58 108.8h-0.48c-1.7 0-3.07-1.35-3.13-3.04" />
            <polyline points="97.54 56.06 97.54 41.2 52.46 41.2 52.46 56.06" />
            <line x1="97.54" x2="97.54" y1="63.25" y2="56.06" />
            <line x1="52.46" x2="52.46" y1="56.06" y2="75.37" />
            <line x1="82.41" x2="59.42" y1="65.65" y2="65.65" />
            <path d="m82.53 65.65-6.45 8.33c-0.67 0.87-1.8 1.39-3.01 1.39h-20.6" />
            <path d="m82.53 65.65 0.78-1.01c0.67-0.87 1.8-1.39 3.01-1.39h11.22" />
            <path d="m52.46 75.37h-7.89c-2 0-3.62 1.4-3.62 3.13" />
            <path d="m109.03 105.77c-0.06 1.68-1.65 3.04-3.61 3.04" />
            <path d="m109.04 105.66s0 0.07-0.01 0.11" />
            <path d="m97.54 63.25h7.89c2 0 3.62 1.4 3.62 3.13" />
            <path d="m44.58 108.8c-1.96 0-3.55-1.35-3.61-3.04" />
            <path d="m40.96 105.66s0 0.07 0.01 0.11" />
            <line x1="105.42" x2="44.58" y1="108.8" y2="108.8" />
            <line x1="40.96" x2="40.96" y1="78.5" y2="105.66" />
            <line x1="109.04" x2="109.04" y1="66.38" y2="105.66" />
            <line x1="59.42" x2="91.06" y1="52.22" y2="52.22" />
            <line x1="59.42" x2="91.06" y1="58.94" y2="58.94" />
        </g>
    </SvgIcon>
}