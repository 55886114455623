import { Location, Navigate, useLocation } from "react-router-dom"
import { useLoginSelector } from "./loginSlice"

export function RedirectToHome() {
    const { state } = useLocation()
    const from = (state as { from?: Location })?.from

    const step = useLoginSelector(state => state.step)
    if (step === 'success') {
        const toPathname = from?.pathname
            && from.pathname !== '/login'
            && from.pathname !== '/'
            ? from.pathname
            : '/home'
        const toState = from?.state
        return <Navigate to={toPathname} state={toState} />
    } else {
        return null
    }
}