import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function StockHistoryIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.24119 0 0 .24119 -6.4955 -6.0892)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="m90.03 71.51c3.95 3.15 9.16 4.7 14.54 3.86 1.42-0.22 2.77-0.61 4.05-1.12" />
            <path d="m86.14 46.39c2.82-4.19 7.31-7.24 12.7-8.08 10.23-1.58 19.81 5.43 21.4 15.66 1.36 8.81-3.65 17.13-11.61 20.28" />
            <line x1="90.27" x2="85.79" y1="46.92" y2="47.71" />
            <line x1="84.74" x2="84.99" y1="42.46" y2="47.08" />
            <polyline points="85.79 47.71 85.03 47.84 84.99 47.08" />
            <polyline points="101.53 49.7 101.53 56.87 107.63 59.72" />
            <path d="m94.45 65.42c1.98 1.68 4.54 2.7 7.34 2.7 2.57 0 4.94-0.87 6.84-2.31" />
            <path d="m91.44 52.09c-0.65 1.43-1.01 3-1.01 4.67 0 3.47 1.56 6.58 4.02 8.66" />
            <path d="m91.44 52.09c1.78-3.94 5.74-6.68 10.34-6.68 6.27 0 11.36 5.08 11.36 11.36 0 3.7-1.78 6.97-4.51 9.05" />
            <line x1="91.87" x2="39.54" y1="62.65" y2="62.65" />
            <line x1="57.21" x2="72.26" y1="112.79" y2="112.79" />
            <line x1="72.34" x2="87.39" y1="112.79" y2="112.79" />
            <polyline points="87.39 112.79 95.28 112.79 95.28 74.29 50.46 74.29 50.46 112.79 57.21 112.79" />
            <line x1="72.34" x2="72.26" y1="112.79" y2="112.79" />
            <polyline points="72.26 112.79 72.26 98.04 66.61 98.04" />
            <polyline points="57.21 112.79 57.21 113.08 72.26 113.08 72.26 112.79" />
            <polyline points="57.21 112.79 57.21 98.04 62.85 98.04" />
            <polyline points="62.85 98.04 62.85 103.05 66.61 103.05 66.61 98.04" />
            <line x1="66.61" x2="62.85" y1="98.04" y2="98.04" />
            <polyline points="72.34 112.79 72.34 113.08 87.39 113.08 87.39 112.79" />
            <polyline points="87.39 112.79 87.39 98.04 81.74 98.04" />
            <polyline points="72.34 112.79 72.34 98.04 77.98 98.04" />
            <polyline points="77.98 98.04 77.98 103.05 81.74 103.05 81.74 98.04" />
            <line x1="81.74" x2="77.98" y1="98.04" y2="98.04" />
            <polyline points="70.82 82.23 65.17 82.23 65.17 97.28 80.22 97.28 80.22 82.23 74.58 82.23" />
            <polyline points="70.82 82.23 70.82 87.25 74.58 87.25 74.58 82.23" />
            <line x1="70.82" x2="74.58" y1="82.23" y2="82.23" />
            <polyline points="106.2 75.6 106.2 113.21 39.54 113.21 39.54 62.65" />
            <line x1="85.69" x2="84.97" y1="46.73" y2="46.17" />
            <polyline points="84.97 46.17 72.87 36.79 39.54 62.65" />
        </g>
    </SvgIcon>
}