import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { useForgetPasswordSelector } from "./forgetPasswordSlice"

export function SendButtonCF() {
    const verified = useForgetPasswordSelector(state => !!state.captchaToken)
    const processing = useForgetPasswordSelector(state => state.step === 'send-processing')
    const disabled = useForgetPasswordSelector(state => Boolean(state.usernameMessage))
    const success = useForgetPasswordSelector(state => state.step === 'success')
    const styles = useStyles()
    return <>
        <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!verified || processing || success || disabled}
            fullWidth
            className={styles.button}
        >
            {processing && <CircularProgress size={16} style={{ marginRight: 8 }} />}
            <FormattedMessage id="forgetPassword.send" />
        </Button>
        {success && <Typography variant="body1">
            <FormattedMessage id="forgetPassword.success" />
        </Typography>}
    </>
}

const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(4),
        fontWeight: 'normal',
        fontSize: '0.75rem',
        borderWidth: 1.5,
        borderColor: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
        '&:hover': {
            borderWidth: 1.5,
            borderColor: theme.palette.primary.main,
        },
    },
}))