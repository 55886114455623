import { useEffect } from "react"
import { useDispatch } from "react-redux"
import appConfig from '../../configs/appConfig'
import { applicationActions, useApplicationSelector } from '../../layouts/Application/applicationSlice'

const TOKEN_KEY = `${appConfig.appFullName}:token`
const COMPANY_UID_KEY = `${appConfig.appFullName}:companyUid`
const LANGUAGE_KEY = `${appConfig.appFullName}:language`
const THEME_KEY = `${appConfig.appFullName}:theme`

export const StorageExecutor = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        function clearListener() {
            dispatch(applicationActions.setToken(null))
            dispatch(applicationActions.setCompanyUid(null))
        }
        function setItemListener(e: SharedSessionStorageEvent) {
            const { key, value } = e.data
            if (key === TOKEN_KEY) {
                dispatch(applicationActions.setToken(value))
            } else if (key === COMPANY_UID_KEY) {
                dispatch(applicationActions.setCompanyUid(value))
            }
        }
        function removeItemListener(e: SharedSessionStorageEvent) {
            const { key } = e.data
            if (key === TOKEN_KEY) {
                dispatch(applicationActions.setToken(null))
            } else if (key === COMPANY_UID_KEY) {
                dispatch(applicationActions.setCompanyUid(null))
            }
        }
        window.sharedSessionStorage.addEventListener('clear', clearListener)
        window.sharedSessionStorage.addEventListener('setItem', setItemListener)
        window.sharedSessionStorage.addEventListener('removeItem', removeItemListener)
        return () => {
            window.sharedSessionStorage.removeEventListener('clear', clearListener)
            window.sharedSessionStorage.removeEventListener('setItem', setItemListener)
            window.sharedSessionStorage.removeEventListener('removeItem', removeItemListener)
        }
    }, [dispatch])

    // store token in session storage
    const token = useApplicationSelector(state => state.auth.token)
    useEffect(() => {
        window.sharedSessionStorage.setItem(TOKEN_KEY, token ?? '')
    }, [token])

    // store companyUid in session storage
    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    useEffect(() => {
        window.sharedSessionStorage.setItem(COMPANY_UID_KEY, companyUid ?? '')
    }, [companyUid])

    // store language in local storage
    const language = useApplicationSelector(state => state.i18n.language)
    useEffect(() => {
        localStorage.setItem(LANGUAGE_KEY, language ?? '')
    }, [language])

    // store theme in local storage
    const theme = useApplicationSelector(state => state.ui.theme)
    useEffect(() => {
        localStorage.setItem(THEME_KEY, theme ?? '')
    }, [theme])

    return null
}