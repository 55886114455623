import { RootProps } from "../../Root"

interface ErrorBoundaryProps {
    err: Error,
    info: React.ErrorInfo,
    props: RootProps
}

export default function ErrorBoundary(props: ErrorBoundaryProps) {
    return <div style={{ padding: 16 }}>
        <h1 style={{ color: 'red' }}>Uncaught Error</h1>
        {props.err.stack
            ? <p style={{ color: 'red', whiteSpace: 'pre-wrap' }}>Uncaught {props.err.stack}</p>
            : <p style={{ color: 'red', whiteSpace: 'pre-wrap' }}>Uncaught {props.err.name}: {props.err.message}</p>}
        <p style={{ color: 'red', whiteSpace: 'pre-wrap' }}>The above error occurred:{props.info.componentStack}</p>
    </div>
}