import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { CustomerStockIcon } from "./assets/CustomerStockIcon"
import { DailyRundownIcon } from "./assets/DailyRundownIcon"
import { DownloadUploadCustomerUsageIcon } from "./assets/DownloadUploadCustomerUsageIcon"
import { OrderCalculationGroupingIcon } from "./assets/OrderCalculationGroupingIcon"
import { OrderCalculationListIcon } from "./assets/OrderCalculationListIcon"
import { OrderCalculationSettingIcon } from "./assets/OrderCalculationSettingIcon"
import { ProjectIcon } from "./assets/ProjectIcon"
import { StockAdjustmentIcon } from "./assets/StockAdjustmentIcon"
import { StockHistoryIcon } from "./assets/StockHistoryIcon"
import { WeeklyStockIcon } from "./assets/WeeklyStockIcon"

export interface SmtNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function SmtNav(props: SmtNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])

    const category1 = <FormattedMessage id="forImtModule" />
    const [open1, setOpen1] = useState<boolean>(true)
    const category2 = <FormattedMessage id="forImtIndependentModule" />
    const [open2, setOpen2] = useState<boolean>(true)

    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<WeeklyStockIcon />}
        iconId={'StockMangementTool_Nav'}
        title={<FormattedMessage id="appbar.smt" />}
        columns={[{
            name: <FormattedMessage id="UsageManagement" />,
            items: [
                {
                    access: "STCK.CSUGS130.VIEW",
                    icon: <ProjectIcon />,
                    title: <FormattedMessage id="CSUGS130" />,
                    path: '/project',
                }, {
                    access: "STCK.CSUHS120.VIEW",
                    icon: <StockHistoryIcon />,
                    title: <FormattedMessage id="CSUHS120" />,
                    path: '/usageHistory',
                }, {
                    access: "STCK.CSUHS140.VIEW",
                    icon: <DownloadUploadCustomerUsageIcon />,
                    title: <FormattedMessage id="CSUHS140" />,
                    path: '/actualUsage',
                }, {
                    access: "STCK.CSUHS150.VIEW",
                    icon: <DownloadUploadCustomerUsageIcon />,
                    title: <FormattedMessage id="CSUHS150" />,
                    path: '/customerActualUsage',
                }, {
                    access: "STCK.CSUGS120.VIEW",
                    icon: <DownloadUploadCustomerUsageIcon />,
                    title: <FormattedMessage id="CSUGS120" />,
                    path: '/cuDownloadUpload',
                }
            ]
        }, {
            name: <FormattedMessage id="inventoryManagement" />,
            items: [
                {
                    access: "STCK.SMGTS110.VIEW",
                    icon: <WeeklyStockIcon />,
                    title: <FormattedMessage id="SMGTS110" />,
                    path: '/weeklyRundown',
                }, {
                    access: "STCK.SMGTS210.VIEW",
                    icon: <DailyRundownIcon />,
                    title: <FormattedMessage id="SMGTS210" />,
                    path: '/dailyRundownList',
                }, {
                    access: "STCK.VSRPS010.VIEW",
                    icon: <StockHistoryIcon />,
                    title: <FormattedMessage id="VSRPS010" />,
                    path: '/pokayoke',
                }
            ]
        }, {
            name: <FormattedMessage id="nav.transactionData" />,
            items: [
                {
                    access: "STCK.SMGTS310.VIEW",
                    icon: <CustomerStockIcon />,
                    title: <FormattedMessage id="SMGTS310" />,
                    path: '/customerStockList',
                }, {
                    access: "STCK.SMGTS410.VIEW",
                    icon: <StockAdjustmentIcon />,
                    title: <FormattedMessage id="SMGTS410" />,
                    path: '/stockAdjustmentList',
                }, {
                    access: "STCK.SMGTS610.VIEW",
                    icon: <StockHistoryIcon />,
                    title: <FormattedMessage id="SMGTS610" />,
                    path: '/impInventoryHistoryList',
                }, {
                    access: "STCK.SMGTS710.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="SMGTS710" />,
                    path: '/incoming',
                }, {
                    access: "STCK.SMGTS810.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="SMGTS810" />,
                    path: '/outgoing',
                }, {
                    access: "STCK.SMGTS710MAPA.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="SMGTS710MAPA" />,
                    path: '/incomingMapa',
                }, {
                    access: "STCK.SMGTS810MAPA.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="SMGTS810MAPA" />,
                    path: '/outgoingMapa',
                }, {
                    access: "INT.INT050.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="INT050" />,
                    path: '/bcro',
                }, {
                    access: "INT.INT060.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="INT060" />,
                    path: '/bccHistory',
                }
            ]
        }, {
            name: <FormattedMessage id="orderManagement" />,
            items: [
                {
                    category: category1,
                    open: open1,
                    setOpen: setOpen1,
                    access: "STCK.MSOGS010.VIEW",
                    icon: <OrderCalculationGroupingIcon />,
                    title: <FormattedMessage id="MSOGS010" />,
                    path: '/orderCalculationGrouping',
                }, {
                    category: category1,
                    open: open1,
                    setOpen: setOpen1,
                    access: "STCK.MSAUF010.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="MSAUF010" />,
                    path: '/alarmUsagePattern',
                }, {
                    category: category1,
                    open: open1,
                    setOpen: setOpen1,
                    access: "STCK.OCCLS010.VIEW",
                    icon: <OrderCalculationListIcon />,
                    title: <FormattedMessage id="OCCLS010" />,
                    path: '/orderCalculation',
                }, {
                    category: category1,
                    open: open1,
                    setOpen: setOpen1,
                    access: "STCK.OCCLS010PNA.VIEW",
                    icon: <OrderCalculationListIcon />,
                    title: <FormattedMessage id="OCCLS010" />,
                    path: '/orderCalculationPNA',
                }, {
                    category: category2,
                    open: open2,
                    setOpen: setOpen2,
                    access: "STCK.MSOGS020.VIEW",
                    icon: <OrderCalculationGroupingIcon />,
                    title: <FormattedMessage id="MSOGS020" />,
                    path: '/orderCalculationGroupingEhm',
                }, {
                    category: category2,
                    open: open2,
                    setOpen: setOpen2,
                    access: "STCK.MSAUF020SM.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="MSAUF020SM" />,
                    path: '/stockManagementMaster',
                }, {
                    category: category2,
                    open: open2,
                    setOpen: setOpen2,
                    access: "STCK.MSAUF020OC.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="MSAUF020OC" />,
                    path: '/orderCalculationMaster',
                }, {
                    category: category2,
                    open: open2,
                    setOpen: setOpen2,
                    access: "STCK.MSAUF020SMOC.VIEW",
                    icon: <OrderCalculationSettingIcon />,
                    title: <FormattedMessage id="MSAUF020SMOC" />,
                    path: '/stockManagementOrderCalcMaster',
                }, {
                    category: category2,
                    open: open2,
                    setOpen: setOpen2,
                    access: "STCK.OCCLS010SMT.VIEW",
                    icon: <OrderCalculationListIcon />,
                    title: <FormattedMessage id="OCCLS010" />,
                    path: '/orderCalculationEhm',
                }
            ]
        }]}
    />
}