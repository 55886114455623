import { useCallback } from 'react'
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { CargoTrackingDetailIcon } from "./assets/CargoTrackingDetailIcon"
import { CargoTrackingIcon } from "./assets/CargoTrackingIcon"
import { CustomerDiListIcon } from "./assets/CustomerDiListIcon"
import { CustomerInvoiceListIcon } from "./assets/CustomerInvoiceListIcon"
import { DownloadDeliveryPlanIcon } from "./assets/DownloadDeliveryPlanIcon"
import { DownloadOutboundInstructionIcon } from "./assets/DownloadOutboundInstructionIcon"
import { InboundMonitoringListIcon } from "./assets/InboundMonitoringListIcon"
import { LogisticsIcon } from "./assets/LogisticsIcon"
import { OutboundMonitoringListIcon } from "./assets/OutboundMonitoringListIcon"
import { ShippingDetailListIcon } from "./assets/ShippingDetailListIcon"

export interface LogisticsNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function LogisticsNav(props: LogisticsNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<LogisticsIcon />}
        iconId={'Logistics_Nav'}
        title={<FormattedMessage id="appbar.logistics" />}
        columns={[{
            name: <FormattedMessage id="nav.operations" />,
            items: [{
                access: "LOGI.LIS010.VIEW",
                icon: <InboundMonitoringListIcon />,
                title: <FormattedMessage id="LIS010" />,
                path: '/inbound',
            }, {
                access: "LOGI.LOS011.VIEW",
                icon: <DownloadDeliveryPlanIcon />,
                title: <FormattedMessage id="LOS011" />,
                path: '/deliveryplan',
            }, {
                access: "LOGI.LOS020.VIEW",
                icon: <DownloadOutboundInstructionIcon />,
                title: <FormattedMessage id="LOS020" />,
                path: '/outboundPrioritization',
            }, {
                access: "LOGI.LOS030.VIEW",
                icon: <OutboundMonitoringListIcon />,
                title: <FormattedMessage id="LOS030" />,
                path: '/outbound',
            }, {
                access: "LOGI.LOS040.VIEW",
                icon: <OutboundMonitoringListIcon />,
                title: <FormattedMessage id="LOS040" />,
                path: '/outboundToWIP',
            }, {
                access: "LOGI.CIS010.VIEW",
                icon: <CargoTrackingDetailIcon />,
                title: <FormattedMessage id="CIS010" />,
                path: '/containerJourney',
            }, {
                access: "LOGI.CIS011.VIEW",
                icon: <CargoTrackingIcon />,
                title: <FormattedMessage id="CIS011" />,
                path: '/cargotrackingDetail',
            }, {
                access: "LOGI.LSS010.VIEW",
                icon: <ShippingDetailListIcon />,
                title: <FormattedMessage id="LSS010" />,
                path: '/shippingdetail',
            },]
        }, {
            name: <FormattedMessage id="nav.history" />,
            items: [{
                access: "LOGI.LDS010.VIEW",
                icon: <CustomerDiListIcon />,
                title: <FormattedMessage id="LDS010" />,
                path: '/di',
            },
            {
                access: "LOGI.LDS010BU.VIEW",
                icon: <CustomerDiListIcon />,
                title: <FormattedMessage id="LDS010BU" />,
                path: '/diForBu',
            },
            // {
            //     access: "LOGI.MTS010.VIEW",
            //     icon: <ShippingStatusListIcon />,
            //     title: <FormattedMessage id="MTS010" />,
            //     path: '/shippingstatus',
            // }, 
            {
                access: "LOGI.AIS030.VIEW",
                icon: <CustomerInvoiceListIcon />,
                title: <FormattedMessage id="AIS030" />,
                path: '/expcustomsinvoice',
            }, {
                access: "LOGI.AIS040.VIEW",
                icon: <CustomerInvoiceListIcon />,
                title: <FormattedMessage id="AIS040" />,
                path: '/impcustomsinvoice',
            }]
        }]}
    />
}