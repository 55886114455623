interface Form {
    lineHeight: number,
    buttonHeight: number,
}

interface FormOptions {
    lineHeight?: number,
    buttonHeight?: number,
}

export interface Layout {
    appbarHeight: number,
    actionBarHeight: number,
    copyrightBarHeight: number,
    form: Form
}

export interface LayoutOptions {
    appbarHeight?: number,
    actionBarHeight?: number,
    copyrightBarHeight?: number,
    form?: FormOptions
}

const defaultLayout: Layout = {
    appbarHeight: 88,
    copyrightBarHeight: 40,
    actionBarHeight: 48,
    form: {
        lineHeight: 36,
        buttonHeight: 32,
    }
}

export function mergeLayout(layout?: LayoutOptions): Layout {
    return {
        ...defaultLayout,
        ...layout,
        form: {
            ...defaultLayout.form,
            ...layout?.form,
        }
    }
}