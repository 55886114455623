import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function LogisticsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 258" transform="matrix(.6933 0 0 .68877 -196.182 -79.055)" strokeWidth="2.171" fill="none" stroke="currentColor" strokeLinecap="round">
            <circle data-name="椭圆 9" cx="2.345" cy="2.345" r="2.345" transform="translate(305.76 138.194)" strokeMiterlimit="10" />
            <circle data-name="椭圆 10" cx="2.345" cy="2.345" r="2.345" transform="translate(293.566 138.194)" strokeMiterlimit="10" />
            <path data-name="路径 172" d="M311.365 133.603h-3.713l-.928-3.481" strokeLinejoin="round" />
            <path data-name="路径 173" d="M310.784 140.564h1.508a1.378 1.378 0 0 0 1.392-1.367v-5.3a.919.919 0 0 0-.139-.483l-3.3-5.182a.943.943 0 0 0-.789-.429h-5.053" strokeMiterlimit="10" />
            <path data-name="直线 33" strokeMiterlimit="10" d="M298.256 141.008h7.504" />
            <path data-name="直线 34" strokeLinejoin="round" d="M291.69 132.566h8.442" />
            <path data-name="直线 35" strokeLinejoin="round" d="M296.38 126.938h-8.442" />
            <path data-name="直线 36" strokeLinejoin="round" d="M293.566 129.752H287" />
            <path data-name="直线 37" strokeLinejoin="round" d="M289.03 133.022h-1.74" />
            <path data-name="直线 38" strokeLinejoin="round" d="M296.38 129.752h.938" />
            <path data-name="路径 174" d="M289.32 125.332v-2.38a.95.95 0 0 1 .943-.952h13.2a.95.95 0 0 1 .943.952v11.424a.95.95 0 0 1-.943.952h-13.672a.476.476 0 0 0-.471.476v3.332a1.419 1.419 0 0 0 1.414 1.428h2.647" strokeMiterlimit="10" />
        </g>
    </SvgIcon>
}