import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { loginActions } from "./loginSlice"

export function ClearOnMountAndUnmount() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loginActions.init())
        return () => {
            dispatch(loginActions.destroy())
        }
    }, [dispatch])
    return null
}