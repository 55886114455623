const appConfig = {
    baseFullName: process.env.REACT_APP_BASE_FULL_NAME!,
    appFullName: process.env.REACT_APP_APP_FULL_NAME!,
    routerBasePath: process.env.REACT_APP_ROUTER_BASE_PATH!,
    orgName: process.env.REACT_APP_ORG_NAME!,
    projectName: process.env.REACT_APP_PROJECT_NAME!,
    apiBaseURL: '/',
    requestTimeout: 30000,
    maxNotificationHistorySize: 50,
    captchaSite: process.env.REACT_APP_CAPTCHA_SITE!,
    captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY!,
    reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY!,
}

export default appConfig