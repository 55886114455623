import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function RequestReceiveListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.5402 0 0 .5402 2.3571 -.78676)">
            <g transform="translate(-139.44 -129.52)" fill="none" stroke="currentColor" strokeWidth="2">
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinejoin="round">
                    <path d="m38.08 8.93a2.48 2.48 0 0 0-2.48-2.49h-6.4a1.11 1.11 0 0 1-1.08-0.83 5.61 5.61 0 0 0-11 0 1.11 1.11 0 0 1-1.12 0.83h-6.4a2.48 2.48 0 0 0-2.48 2.49v6.77h31z" />
                    <rect x="6.92" y="21.95" width="4.74" height="4.74" />
                    <line x1="15.34" x2="38.08" y1="24.32" y2="24.32" />
                    <rect x="6.92" y="33.74" width="4.74" height="4.74" />
                    <line x1="15.34" x2="31.53" y1="36.11" y2="36.11" />
                    <rect x="6.92" y="45.53" width="4.74" height="4.74" />
                    <line x1="15.34" x2="27.29" y1="47.9" y2="47.9" />
                    <polyline points="29.22 58.36 1 58.36 1 10.39 5.27 10.39" />
                    <polyline points="41.45 10.39 44.31 10.39 44.31 33.74" />
                </g>
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinecap="round" strokeMiterlimit="10">
                    <line x1="22.5" x2="22.7" y1="6.36" y2="6.36" />
                </g>
                <g id="Icons" transform="matrix(.5929 0 0 .5929 141.74 134.61)" strokeLinejoin="round" strokeLinecap="round">
                    <polyline points="36.26 50.52 38.94 53.34 45.65 46.3" />
                    <circle cx="40.96" cy="49.82" r="11.73" />
                </g>
            </g>
        </switch>
    </SvgIcon>
}