import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function RequestModifyPackageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.63573 0 0 .63573 1.9364 2.5809)">
            <g transform="translate(-399.22 -25.383)">
                <g transform="translate(399.82 25.984)" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <g id="icons" transform="matrix(.68398 0 0 .68398 -.499 .53559)">
                        <path d="m23.25 20a12 12 0 1 1-2.25 7v-3" />
                        <polyline points="25 28 21 24 17 28" />
                    </g>
                    <g id="icons" transform="matrix(.68398 0 0 .68398 -.499 .53559)" strokeLinecap="round" strokeMiterlimit="10">
                        <polyline points="23 39 1 39 1 1 40 1 40 13" />
                        <rect x="15" y="1" width="11" height="5" />
                        <line x1="5" x2="7" y1="35" y2="35" />
                        <line x1="9" x2="11" y1="35" y2="35" />
                    </g>
                </g>
            </g>
        </switch>
    </SvgIcon>
}