import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function DownloadOverallVisualisationIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62411 0 0 .6241 -227.93 -28.623)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4079">
            <path transform="translate(-13.17)" d="m409 54.7h4.024v-2.017a2.683 2.683 0 0 0-2.683-2.683" strokeMiterlimit="10" data-name="路径 1899" />
            <path d="m394.49 52.683a2.683 2.683 0 0 1 2.683-2.683h-22.805a2.683 2.683 0 0 0-2.683 2.683v24.817a2.683 2.683 0 0 1-2.683 2.683h22.805a2.683 2.683 0 0 0 2.683-2.683z" strokeLinecap="round" strokeLinejoin="round" data-name="路径 1900" />
            <g strokeMiterlimit="10">
                <line transform="translate(376,59)" x2="14" data-name="直线 894" />
                <line transform="translate(376,56)" x2="14" data-name="直线 895" />
                <line transform="translate(376,65)" x2="14" data-name="直线 896" />
                <line transform="translate(376,68)" x2="14" data-name="直线 897" />
                <line transform="translate(376,71)" x2="9" data-name="直线 898" />
            </g>
        </g>
    </SvgIcon>
}