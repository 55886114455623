import { Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { loginActions, useLoginSelector } from "./loginSlice"
import { RefreshButton } from "./RefreshButton"
import { VerificationCodeInput } from "./VerificationCodeInput"
import { VerifyButton } from "./VerifyButton"

export function VerifyForm() {
  const dispatch = useDispatch()
  const styles = useStyles()
  return <form className={styles.form} onSubmit={(e) => {
    dispatch(loginActions.login())
    e.preventDefault()
  }}>
    <Grid container alignItems="center">
      <Grid item style={{ marginLeft: -16, marginRight: 16 }}>
        <IconButton onClick={() => dispatch(loginActions.setStep('sign-in'))}>
          <ArrowBack color="primary" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="h5" style={{ textTransform: 'uppercase', lineHeight: '18px' }}>
          <FormattedMessage id="login.verify" />
        </Typography>
      </Grid>
      <Grid item style={{ marginLeft: 8 }}>
        <Countdown />
      </Grid>
    </Grid>
    <Divider className={styles.divider} />
    <Typography variant="body2" color="textSecondary" className={styles.label}>
      <FormattedMessage id="login.verificationCode" />
    </Typography>
    <Grid container alignItems="center" style={{ marginTop: 2, marginBottom: 16 }}>
      <Grid item xs={8}>
        <VerificationCodeInput />
      </Grid>
      <Grid item xs={4}>
        <RefreshButton />
      </Grid>
    </Grid>
    <VerifyButton />
  </form >
}

function Countdown() {

  const expireAt = useLoginSelector(state => {
    if (state.verifyToken) {
      return JSON.parse(atob(state.verifyToken.split('.')[1])).exp as number
    } else {
      return (new Date().getTime() / 1000) + 300
    }
  })
  const [timeout, setTimeout] = useState(() => parseInt(`${expireAt - (new Date().getTime() / 1000)}`))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(count => --count)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    if (timeout <= 1) {
      dispatch(loginActions.setPassword(''))
      dispatch(loginActions.setVerificationCode(''))
      dispatch(loginActions.setStep('sign-in'))
    }
  }, [timeout, dispatch])

  return <Typography variant="body1" color="textSecondary">
    {` (${timeout})`}
  </Typography>
}


const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  input: {
    height: 40,
    padding: theme.spacing(2),
    marginTop: 2,
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
  helper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
    height: 18,
  },
  link: {
    textDecorationColor: theme.palette.secondary.main,
  },
  button: {
    height: theme.spacing(4),
    fontWeight: 'normal',
    fontSize: '0.75rem',
    borderWidth: 1.5,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderWidth: 1.5,
      borderColor: theme.palette.primary.main,
    },
  },
  or: {
    margin: `${theme.spacing(4)}px 0`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))