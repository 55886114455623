import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function OrderCalculationSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.20627 0 0 .20627 -4.2618 -2.4842)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
            <rect x="46.33" y="53.36" width="7.21" height="7.21" />
            <line x1="59.77" x2="86.88" y1="54.27" y2="54.27" />
            <line x1="59.77" x2="89.39" y1="59.55" y2="59.55" />
            <rect x="46.33" y="68.69" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="69.6" y2="69.6" />
            <line x1="59.77" x2="72.45" y1="74.89" y2="74.89" />
            <rect x="46.33" y="84.02" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="84.93" y2="84.93" />
            <line x1="59.77" x2="72.45" y1="90.22" y2="90.22" />
            <rect x="46.33" y="99.35" width="7.21" height="7.21" />
            <line x1="59.77" x2="72.45" y1="100.26" y2="100.26" />
            <line x1="59.77" x2="72.45" y1="105.55" y2="105.55" />
            <polyline points="48.47 55.42 50.81 58 57 51.82" />
            <polyline points="48.9 71.01 51.24 73.58 57.42 67.4" />
            <polyline points="48.9 86.46 51.24 89.04 57.42 82.86" />
            <polyline points="48.9 101.92 51.24 104.49 57.42 98.31" />
            <path strokeWidth="2" d="m97.18 61.6h-21.52c-1.56 0-2.82 1.26-2.82 2.82v45.88c0 1.56 1.26 2.82 2.82 2.82h21.52" />
            <path strokeWidth="2" d="m97.18 61.6h9.42c1.56 0 2.82 1.26 2.82 2.82v45.88c0 1.56-1.26 2.82-2.82 2.82h-9.42" />
            <path d="m97.18 66.76h5.95c0.77 0 1.39 0.62 1.39 1.39v5.46c0 0.77-0.62 1.39-1.39 1.39h-5.95" />
            <path d="m97.18 66.76h-18.06c-0.77 0-1.39 0.62-1.39 1.39v5.46c0 0.77 0.62 1.39 1.39 1.39h18.06" />
            <rect x="78.3" y="79.12" width="5.67" height="5.67" />
            <rect x="88.46" y="79.12" width="5.67" height="5.67" />
            <rect x="98.79" y="79.12" width="5.67" height="5.67" />
            <rect x="78.3" y="89.74" width="5.67" height="5.67" />
            <rect x="88.46" y="89.74" width="5.67" height="5.67" />
            <rect x="98.79" y="89.42" width="5.67" height="16.49" />
            <rect x="78.3" y="100.36" width="5.67" height="5.67" />
            <rect x="88.46" y="100.36" width="5.67" height="5.67" />
            <line strokeWidth="2" x1="96.15" x2="85.85" y1="42.54" y2="42.54" />
            <line strokeWidth="2" x1="97.18" x2="97.18" y1="49.76" y2="61.09" />
            <polyline strokeWidth="2" points="78.63 42.54 38.45 42.54 38.45 116.73 97.18 116.73 97.18 113.64" />
            <path d="m97.18 42.54c0.93-1.24 1.49-2.78 1.49-4.45 0-4.12-3.34-7.45-7.45-7.45s-7.45 3.34-7.45 7.45c0 1.67 0.56 3.21 1.49 4.45" />
            <path d="m85.25 42.54c1.36 1.82 3.52 3 5.97 3s4.61-1.18 5.97-3" />
            <path d="m79.34 42.54c-0.12-0.31-0.24-0.62-0.33-0.94h-3.02c-0.88 0-1.59-0.71-1.59-1.59v-4.23c0-0.88 0.71-1.59 1.59-1.59h3.02c0.26-0.86 0.62-1.68 1.05-2.45l-2.12-2.12c-0.62-0.62-0.62-1.63 0-2.25l2.99-2.99c0.62-0.62 1.63-0.62 2.25 0l2.23 2.23c0.64-0.32 1.31-0.6 2-0.81v-2.94c0-0.88 0.71-1.59 1.59-1.59h4.23c0.88 0 1.59 0.71 1.59 1.59v2.94c0.73 0.22 1.44 0.52 2.11 0.86l2.49-2.49c0.62-0.62 1.63-0.62 2.25 0l2.99 2.99c0.62 0.62 0.62 1.63 0 2.25l-2.43 2.43c0.41 0.74 0.74 1.53 0.99 2.35h3" />
            <path d="m79.34 42.54c0.16 0.4 0.33 0.79 0.53 1.17l-2 2c-0.62 0.62-0.62 1.63 0 2.25l2.99 2.99c0.62 0.62 1.63 0.62 2.25 0l1.94-1.94c0.74 0.41 1.53 0.74 2.35 0.99v3.32c0 0.88 0.71 1.59 1.59 1.59h4.23c0.88 0 1.59-0.71 1.59-1.59v-3.32c0.83-0.25 1.62-0.6 2.37-1.01" />
            <path d="m98.81 50.5-1.55-1.55-0.08 0.04" />
            <path d="m105.99 47.87c0.81 3.24 4.1 5.21 7.34 4.4s5.21-4.1 4.4-7.34-4.1-5.21-7.34-4.4-5.21 4.1-4.4 7.34z" />
            <path d="m106.2 34.2c0.16-0.32 0.45-0.57 0.82-0.67l3.33-0.84c0.69-0.17 1.39 0.25 1.57 0.94l0.6 2.38c0.73 0.04 1.45 0.15 2.14 0.34l1.25-2.09c0.37-0.61 1.16-0.81 1.77-0.44l2.95 1.76c0.61 0.37 0.81 1.16 0.44 1.77l-1.32 2.2c0.38 0.44 0.73 0.91 1.03 1.42l2.32-0.58c0.69-0.17 1.39 0.25 1.57 0.94l0.84 3.33c0.17 0.69-0.25 1.39-0.94 1.57l-2.32 0.58c-0.03 0.62-0.12 1.23-0.26 1.83l2.46 1.47c0.61 0.37 0.81 1.16 0.44 1.77l-1.76 2.95c-0.37 0.61-1.16 0.81-1.77 0.44l-2.4-1.44c-0.5 0.47-1.06 0.88-1.66 1.24l0.64 2.55c0.17 0.69-0.25 1.39-0.94 1.57l-3.33 0.84c-0.69 0.17-1.39-0.25-1.57-0.94l-0.64-2.55c-0.59-0.03-1.17-0.11-1.74-0.24l-1.36 2.28c-0.37 0.61-1.16 0.81-1.77 0.44l-2.95-1.76c-0.61-0.37-0.81-1.16-0.44-1.77l1.3-2.17c-0.49-0.52-0.93-1.1-1.31-1.72l-2.61 0.66c-0.69 0.17-1.39-0.25-1.57-0.94l-0.2-0.8" />
            <path d="m106.2 34.2c-0.14 0.27-0.2 0.58-0.12 0.9l0.6 2.38c-0.53 0.32-1.03 0.69-1.49 1.1l-1.97-1.18c-0.61-0.37-1.41-0.17-1.77 0.44l-1.76 2.95c-0.37 0.61-0.17 1.41 0.44 1.77l1.91 1.14c-0.17 0.66-0.28 1.35-0.31 2.05l-2.61 0.66c-0.69 0.17-1.11 0.88-0.94 1.57l0.63 2.53" />
        </g>
    </SvgIcon>
}