import { useCallback } from 'react'
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { DashboardIcon } from "./assets/DashboardIcon"

export interface DashboardNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function DashboardNav(props: DashboardNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<DashboardIcon />}
        iconId={'Dashboard_Nav'}
        title={<FormattedMessage id="appbar.dashboard" />}
        columns={[{
            items: [{
                access: ["DASH.DAS010.VIEW", "DASH.DAS012.VIEW", "DASH.DAS013.VIEW", "DASH.DAS014.VIEW", "DASH.DAS015.VIEW"],
                icon: <DashboardIcon />,
                title: <FormattedMessage id="DAS000" />,
                path: '/dashboard',
            },
            ]
        }]}
    />

}