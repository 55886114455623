import { AxiosError } from 'axios'
import { IntlString } from '../IntlString'
import { Notification } from '../Notification'

export default function errorToNotification(error: AxiosError, options?: Partial<Notification>): Notification {
    let title: string | IntlString = { code: 'notice.unknown' }
    // title: first feature, then url, then unknown
    if (error.config && error.config.feature) {
        title = { code: error.config.feature }
    } else if (error.config && error.config.url) {
        title = { code: error.config.url }
    } else if (error.request && error.request.config.feature) {
        title = { code: error.request.config.feature }
    } else if (error.request && error.request.config.url) {
        title = { code: error.request.config.url }
    }
    let messages: string | IntlString | (string | IntlString)[] = error.message
    // has response
    if (error.response && error.response.status) {
        const status = error.response.status
        if (status === 401 && (!error.response.data || !error.response.data.messages)) {
            messages = { code: 'notice.unauthorized' }
        } else if (status === 403 && (!error.response.data || !error.response.data.messages)) {
            messages = { code: 'notice.forbidden' }
        } else if (status >= 500 && (!error.response.data || !error.response.data.messages)) {
            messages = { code: 'notice.internalServerError' }
        } else if (error.response.data && error.response.data.messages) {
            messages = error.response.data.messages
        }
    }
    return {
        type: 'error',
        title,
        messages,
        ...options
    }
}