import { Navigate } from "react-router-dom"
import { useResetPasswordSelector } from "./resetPasswordSlice"

export function RedirectToLogin() {
    const step = useResetPasswordSelector(state => state.step)
    if (step === 'success') {
        return <Navigate to="/login" />
    } else {
        return null
    }
}