import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import authApi from '../../services/authApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import responseToNotification from '../../utils/axios/responseToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import { IntlString } from "../../utils/IntlString"
import { applicationActions } from '../Application/applicationSlice'

type Step = 'send' | 'send-processing' | 'success'

interface ForgetPasswordState {
    username: string,
    usernameMessage: string | IntlString,
    captchaToken: string,
    recaptchaLoaded: boolean,
    step: Step,
}

const initialState: ForgetPasswordState = {
    username: '',
    usernameMessage: '',
    captchaToken: '',
    recaptchaLoaded: false,
    step: 'send',
}

const send = createAsyncThunk<void>('forgetPassword/send', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectForgetPasswordState(thunk.getState())
    if (!state.usernameMessage
        && state.username) {
        thunk.dispatch(forgetPasswordActions.setStep('send-processing'))
        return authApi.forgetPassword(axiosInstance, {
            username: state.username,
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(forgetPasswordActions.setStep('success'))
        }).catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            thunk.dispatch(forgetPasswordActions.setStep('send'))
        })
    }
})

function validateUsername(state: ForgetPasswordState) {
    if (state.username === '') {
        state.usernameMessage = { code: 'forgetPassword.usernameIsEmpty' }
    } else {
        state.usernameMessage = ''
    }
}

export const forgetPasswordSlice = createSlice({
    name: 'forgetPassword',
    initialState,
    reducers: {
        init: (state) => {
            state.username = ''
            state.captchaToken = ''
            state.recaptchaLoaded = false
            state.step = 'send'
        },
        destroy: (state) => {
            state.username = ''
            state.captchaToken = ''
            state.recaptchaLoaded = false
            state.step = 'send'
        },
        setUsername: (state, { payload: username }: PayloadAction<string>) => {
            state.username = username
            validateUsername(state)
        },
        setRecaptchaLoaded: (state, { payload: recaptchaLoaded }: PayloadAction<boolean>) => {
            state.recaptchaLoaded = recaptchaLoaded
        },
        setRecaptchaToken: (state, { payload: captchaToken }: PayloadAction<string>) => {
            state.captchaToken = captchaToken
        },
        setStep: (state, { payload: step }: PayloadAction<Step>) => {
            state.step = step
        },
        validate: (state) => {
            validateUsername(state)
        },
    },
    extraReducers: {
    }
})

export const forgetPasswordActions = {
    ...forgetPasswordSlice.actions,
    send,
}

export function selectForgetPasswordState(state: any) {
    return state[appConfig.appFullName][forgetPasswordSlice.name] as ForgetPasswordState
}

export function useForgetPasswordSelector<R>(selector: (state: ForgetPasswordState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectForgetPasswordState(state)), equalityFn)
}