import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import appConfig from '../../configs/appConfig'
import { applicationSlice } from '../../layouts/Application/applicationSlice'
import shareAxiosInstance from './shareAxiosInstance'

export default function thunkAxiosInstance(thunk: BaseThunkAPI<any, any, any, any, any, any>) {
    const dispatch = thunk.dispatch
    const state = thunk.getState()[appConfig.baseFullName][applicationSlice.name]
    const options = {
        token: state.auth.token,
        companyUid: state.auth.companyUid,
        language: state.i18n.language,
    }
    return shareAxiosInstance(dispatch, options)
}