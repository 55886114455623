import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function UnitPartsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6102 0 0 .61021 -76.036 -76.213)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 165">
            <path transform="translate(-4.933)" d="m144.97 138.1 1.366-3.3 2.005 0.668a8.5 8.5 0 0 1 1.987-1.984l-0.669-2.008 3.3-1.365 0.947 1.892a8.522 8.522 0 0 1 2.808 0l0.947-1.89 3.3 1.366-0.668 2.005a8.506 8.506 0 0 1 1.984 1.987l2.008-0.668 1.365 3.3-1.892 0.947a8.5 8.5 0 0 1 0 2.808l1.89 0.946-1.365 3.3-2.006-0.668a8.532 8.532 0 0 1-1.987 1.984l0.668 2.007-3.3 1.365-0.947-1.892a8.5 8.5 0 0 1-2.808 0l-0.946 1.89-3.3-1.365 0.668-2.006a8.505 8.505 0 0 1-1.984-1.987l-2.007 0.668-1.366-3.3 1.892-0.947a8.5 8.5 0 0 1 0-2.807z" data-name="路径 279" />
            <path transform="translate(-.143 -7.479)" d="m129.6 159.41 0.9-2.183 1.687 0.7a5.2 5.2 0 0 1 1.213-1.212l-0.7-1.689 2.182-0.9 0.7 1.689a5.19 5.19 0 0 1 1.714 0l0.7-1.687 2.183 0.9-0.7 1.687a5.2 5.2 0 0 1 1.212 1.213l1.688-0.7 0.9 2.182-1.689 0.7a5.19 5.19 0 0 1 0 1.714l1.687 0.7-0.9 2.182-1.687-0.7a5.209 5.209 0 0 1-1.213 1.212l0.7 1.688-2.182 0.9-0.7-1.689a5.19 5.19 0 0 1-1.714 0l-0.7 1.687-2.182-0.9 0.7-1.687a5.18 5.18 0 0 1-1.21-1.213l-1.689 0.7-0.9-2.182 1.689-0.7a5.189 5.189 0 0 1 0-1.714z" data-name="路径 280" />
            <path transform="translate(0,-1.558)" d="m132.46 145.7-1.51 0.974-1.259-1.952 1.51-0.974a5.119 5.119 0 0 1-0.3-1.658l-1.755-0.378 0.489-2.271 1.755 0.379a5.1 5.1 0 0 1 0.96-1.385l-0.974-1.51 1.952-1.259 0.974 1.51a5.1 5.1 0 0 1 1.658-0.3l0.379-1.755 2.27 0.489-0.379 1.755" data-name="路径 281" />
            <path transform="translate(-2.202,-9.537)" d="m136.38 162.14a2.295 2.295 0 1 0 3-1.241 2.3 2.3 0 0 0-3 1.241z" data-name="路径 282" />
            <circle transform="translate(144.9 134.94)" cx="5.507" cy="5.507" r="5.507" data-name="椭圆 59" />
            <path transform="translate(-2.148,-3.739)" d="m138.31 146.24a2.065 2.065 0 1 1 0.973-3.741" data-name="路径 284" />
        </g>
    </SvgIcon>
}