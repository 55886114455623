import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function ManageExternalDataIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.64324 0 0 .64324 -234.64 -131.85)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 164">
            <path transform="translate(-2.817,-5.947)" d="m388.99 234.18v-2.748c0-2.269-3.587-3.435-5.5-3.435s-5.5 1.166-5.5 3.435v2.748" data-name="路径 276" />
            <path transform="translate(-4.069,-1.878)" d="m384.75 221.87a2.634 2.634 0 0 0 2.748-2.748v-1.374a2.75 2.75 0 1 0-5.5 0v1.374a2.634 2.634 0 0 0 2.752 2.748z" data-name="路径 277" />
            <circle transform="translate(369,209)" cx="11.679" cy="11.679" r="11.679" data-name="椭圆 58" />
            <path transform="translate(-8.451,-8.682)" d="m398.43 236.74 8.018 7.9a1.718 1.718 0 0 1 9e-3 2.438 1.717 1.717 0 0 1-2.42 9e-3l-8.035-7.918" data-name="路径 278" />
        </g>
    </SvgIcon>
}