import { makeStyles } from "@material-ui/core"
import clsx from 'clsx'
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { applicationActions, useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { Notice } from "./Notice"

export interface NoticeStackProps {
    autoHideDuration?: number,
    maxStackSize?: number,
    anchorOrigin?: {
        vertical: 'top' | 'bottom',
        horizontal: 'left' | 'center' | 'right',
    },

}

export function NoticeStack(props: NoticeStackProps) {
    const {
        autoHideDuration = 10000,
        maxStackSize = 3,
        anchorOrigin = { vertical: 'top', horizontal: 'center' }
    } = props
    const dispatch = useDispatch()

    const notifications = useApplicationSelector(state => state.ui.notifications)
    const overflow = notifications.length > maxStackSize

    // close the first notification if the stack is full
    useEffect(() => {
        if (overflow) {
            dispatch(applicationActions.closeNotification(notifications[0].id))
        }
    }, [dispatch, notifications, overflow])

    const styles = useStyles()
    return <div className={clsx(
        styles.root,
        anchorOrigin.vertical === 'top' && styles.top,
        anchorOrigin.vertical === 'bottom' && styles.bottom,
        anchorOrigin.horizontal === 'left' && styles.left,
        anchorOrigin.horizontal === 'right' && styles.right,
        anchorOrigin.horizontal === 'center' && styles.center,
    )}>
        {notifications.slice(0, 3).map(n => <Notice key={n.id} notification={n} autoHideDuration={autoHideDuration} />)}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
    },
    top: {
        top: theme.spacing(1),
    },
    bottom: {
        bottom: theme.spacing(1),
    },
    left: {
        left: theme.spacing(2),
    },
    right: {
        right: theme.spacing(2),
    },
    center: {
        width: 500,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))