import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function CustomerStockIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.255 0 0 .255 -7.125 -7.2266)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="41.67" y="62.65" width="66.66" height="50.57" />
            <rect x="52.59" y="74.29" width="44.82" height="38.5" />
            <rect x="59.34" y="98.04" width="15.05" height="15.05" />
            <rect x="64.98" y="98.04" width="3.76" height="5.02" />
            <rect x="74.47" y="98.04" width="15.05" height="15.05" />
            <rect x="80.11" y="98.04" width="3.76" height="5.02" />
            <rect x="67.3" y="82.23" width="15.05" height="15.05" />
            <rect x="72.94" y="82.23" width="3.76" height="5.02" />
            <polygon points="75 36.79 108.33 62.65 108.33 113.21 41.67 113.21 41.67 62.65" />
        </g>
    </SvgIcon>
}