import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PaymentTermsListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61999 0 0 .61999 -276 -153.24)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 170">
            <path transform="translate(-2.273,-12.124)" d="m455 290.45h0.3a6.209 6.209 0 0 1 1.841 0.279l9.415 3.306a3.727 3.727 0 0 0 2.985-0.42l11.9-7.145a1.889 1.889 0 0 0 0.422-2.85 1.941 1.941 0 0 0-2.345-0.4l-4.686 2.5" data-name="路径 306" />
            <path transform="translate(-2.273,-11.366)" d="m463.7 287.21h9.211a1.934 1.934 0 0 0 1.967-1.763 1.864 1.864 0 0 0-1.861-1.963h-6.325a2.425 2.425 0 0 1-1.7-0.675 7.233 7.233 0 0 0-5.021-1.81 7.824 7.824 0 0 0-3.922 1 1.928 1.928 0 0 1-0.971 0.243h-0.078" data-name="路径 307" />
            <path transform="translate(0,-11.366)" d="m449 281h3.727v11.18h-2.485" data-name="路径 308" />
            <ellipse transform="translate(457 251)" cx="10" cy="9" rx="10" ry="9" data-name="椭圆 62" />
            <path transform="translate(-9.85,-4.168)" d="m478.73 262h-2.485a1.2425 1.2425 0 1 0 0 2.485h1.242a1.2425 1.2425 0 1 1 0 2.485h-2.484" data-name="路径 309" />
            <line transform="translate(467 256)" y2="2" data-name="直线 102" />
            <line transform="translate(467 263)" y2="1" data-name="直线 103" />
        </g>
    </SvgIcon>
}