import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function CustomerListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6383 0 0 .6383 -183.72 -29.532)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 156">
            <g strokeLinejoin="round">
                <line transform="translate(299.24 78.951)" x2="20.495" data-name="直线 58" />
                <path transform="translate(0,-9.649)" d="m293 90.382v-5.588a2.648 2.648 0 0 1 2.617-2.794h2.794l3.492 4.191h4.889s0.4 2.794-2.794 2.794h-4.191l-2.707-2.794" data-name="路径 229" />
                <path transform="translate(-.302 -5.729)" d="m297.14 75.985a3.059 3.059 0 0 0 3.143-2.993v-1a3.147 3.147 0 0 0-6.286 0v1a3.059 3.059 0 0 0 3.143 2.993z" data-name="路径 230" />
                <path transform="translate(-5.329,-8.141)" d="m310.67 82.588 2.146-5.588h12.573l-3.353 8.382h-7.646" data-name="路径 231" />
                <line transform="translate(306.37 56.674)" x2="8.02" data-name="直线 59" />
                <line transform="translate(306.37 54.001)" x2="8.02" data-name="直线 60" />
                <line transform="translate(306.37 59.347)" x2="4.455" data-name="直线 61" />
            </g>
            <path transform="translate(-3.618)" d="m314.43 50c-5.207 0-9.429 3.127-9.429 6.985a5.921 5.921 0 0 0 1.99 4.282 26.317 26.317 0 0 1-0.982 3.879c-0.159 0.5 0.191 1.028 0.606 0.9a10.819 10.819 0 0 0 4.456-2.54 12.346 12.346 0 0 0 3.36 0.464c5.208 0 9.429-3.127 9.429-6.985s-4.222-6.985-9.43-6.985z" strokeMiterlimit="10" data-name="路径 232" />
        </g>
    </SvgIcon>
}