import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function OrderIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 10" transform="matrix(.77142 0 0 .7761 -159.108 -150.327)" fill="none" stroke="currentColor" strokeWidth="1.292">
            <path data-name="路径 15" d="M227.508 200.109h2.28a.829.829 0 0 1 .829.829v19.071a.829.829 0 0 1-.829.829h-15.959a.829.829 0 0 1-.829-.829v-19.071a.829.829 0 0 1 .829-.829h2.28" strokeMiterlimit="10" strokeWidth="1.939" />
            <path data-name="路径 16" d="M224.347 199.073a2.591 2.591 0 0 0-5.077 0h-2.125a1.036 1.036 0 0 0-1.036 1.036v1.554a.518.518 0 0 0 .518.518h10.363a.518.518 0 0 0 .518-.518v-1.554a1.036 1.036 0 0 0-1.036-1.036z" strokeMiterlimit="10" strokeWidth="1.939" />
            <path data-name="直线 14" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.939" d="M227.535 208.721h-6.46" />
            <path data-name="路径 17" d="M216.109 208.404l.415.777 1.658-2.073" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.939" />
            <path data-name="直线 15" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.939" d="M227.535 214.374h-6.46" />
            <path data-name="路径 18" d="M216.109 214.103l.415.777 1.658-2.073" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.939" />
            <circle data-name="椭圆 3" cx=".777" cy=".777" r=".777" transform="translate(221.031 198.813)" />
        </g>
    </SvgIcon>
}