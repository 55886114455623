import { Input, makeStyles, Typography } from "@material-ui/core"
import { useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { forgetPasswordActions, useForgetPasswordSelector } from "./forgetPasswordSlice"

export function UsernameInput() {
    const dispatch = useDispatch()
    const username = useForgetPasswordSelector(state => state.username)
    const autoFocus = useForgetPasswordSelector(state => state.username === '')
    const valid = useForgetPasswordSelector(state => !state.usernameMessage)
    const message = useForgetPasswordSelector(state => state.usernameMessage)
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(forgetPasswordActions.setUsername(e.target.value))
    }, [dispatch])
    const intl = useIntl()
    const styles = useStyles()
    return <div className={styles.root}>
        <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="forgetPassword.username" />
        </Typography>
        <Input type="text"
            id="username"
            autoComplete="username"
            value={username}
            autoFocus={autoFocus}
            placeholder={intl.formatMessage({ id: 'forgetPassword.placeholder.username' })}
            onChange={handleChange}
            fullWidth
            disableUnderline
            className={styles.input}
        />
        {!valid && <Typography variant="body2" color="error">
            {typeof message === 'string'
                ? message
                : <FormattedMessage id={message.code} values={message.args as any} />}
        </Typography>}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1.5),
    },
    input: {
        height: 40,
        padding: theme.spacing(2),
        marginTop: 2,
        marginBottom: theme.spacing(1.5),
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.background.paper,
    },
}))