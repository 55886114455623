import { Button, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { loginActions } from "./loginSlice"

export function RefreshButton() {
  const dispatch = useDispatch()

  const [disabled, setDisabled] = useState(false)
  const [count, setCount] = useState(60)

  useEffect(() => {
    if (disabled) {
      const interval = setInterval(() => {
        setCount(count => --count)
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    } else {
      setCount(60)
    }
  }, [disabled])

  useEffect(() => {
    if (count === 0) {
      setDisabled(false)
    }
  }, [count])

  const styles = useStyles()
  return <Button
    variant="text"
    color="secondary"
    disabled={disabled}
    onClick={() => {
      dispatch(loginActions.refresh())
      setDisabled(true)
    }}
    fullWidth
    className={styles.button}
  >
    <FormattedMessage id="login.refresh" />
    {disabled && <span>{` (${count})`}</span>}
  </Button>
}

const useStyles = makeStyles(theme => ({
  button: {
    height: theme.spacing(4),
    fontWeight: 'normal',
    fontSize: '0.75rem',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
}))