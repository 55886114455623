import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function DailyRundownIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26254 0 0 .26254 -7.6244 -7.6592)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="40.68" y="43.01" width="62.16" height="62.73" />
            <line x1="40.68" x2="102.85" y1="56.3" y2="56.3" />
            <line x1="53.55" x2="53.55" y1="47.83" y2="37.91" />
            <line x1="66.05" x2="66.05" y1="47.83" y2="37.91" />
            <line x1="78.56" x2="78.56" y1="47.83" y2="37.91" />
            <line x1="91.07" x2="91.07" y1="47.83" y2="37.91" />
            <polyline points="102.85 49.37 109.32 49.37 109.32 112.09 47.15 112.09 47.15 105.74" />
            <path fill="currentColor" d="m61.52 91.9h-3.85v-23.23l-3.9 2.39v-3.98l3.9-2.6h3.85v27.41z" />
            <path fill="currentColor" d="m73.05 90.13c-1.33-1.37-1.99-3.28-1.99-5.73v-12.46c0-2.46 0.67-4.38 2-5.74s3.27-2.05 5.83-2.05 4.51 0.68 5.83 2.04 1.99 3.27 1.99 5.75v12.46c0 2.46-0.67 4.38-2 5.74s-3.27 2.05-5.83 2.05-4.51-0.69-5.83-2.06zm8.84-2.67c0.65-0.71 0.97-1.73 0.97-3.06v-12.46c0-1.33-0.32-2.35-0.96-3.06s-1.65-1.07-3.02-1.07-2.38 0.36-3.02 1.07-0.96 1.73-0.96 3.06v12.46c0 1.33 0.32 2.35 0.97 3.06s1.65 1.07 3.01 1.07 2.36-0.35 3.01-1.07z" />
        </g>
    </SvgIcon>
}