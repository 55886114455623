import { AxiosInstance } from 'axios'

interface UserAPIResponse {
    id: string,
    name: String,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAPIResponseUserAccount,
    cbdss: UserAPIResponseUserCbds[],
}

interface UserAPIResponseUserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserAPIResponseUserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface ListUsersAPIRequest {
    nameContains?: string,
    nameIn?: string[],
    powerUser?: boolean,
    activateChat?: boolean,
    emailContains?: string,
    emailIn?: string[],
    phoneContains?: string,
    phoneIn?: string[],
    contactCodeContains?: string,
    contactCodeIn?: string[],
    localCodeContains?: string,
    localCodeIn?: string[],
    loginIdContains?: string,
    loginIdIn?: string[],
    expireAtBefore?: number,
    expireAtAfter?: number,
    expireAtSince?: number,
    expireAtUntil?: number,
    active?: boolean,
    locked?: boolean,
    cbdsUidIn?: string[],
    defaultCbdsUidIn?: string[],
    roleIdIn?: string[],
    roleNameIn?: string[],
    resourceIdIn?: string[],
    resourceNameIn?: string[],
    currentPage?: number,
    pageSize?: number,
}

interface CreateUserAPIRequest {
    name: String,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: CreateUserAPIRequestUserAccount,
    cbdss: CreateUserAPIRequestUserCbds[],
}

interface CreateUserAPIRequestUserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface CreateUserAPIRequestUserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface UpdateUserAPIRequest {
    userId: string,
    name: String,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UpdateUserAPIRequestUserAccount,
    cbdss: UpdateUserAPIRequestUserCbds[],
}

interface UpdateUserAPIRequestUserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UpdateUserAPIRequestUserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

const userApi = {
    current: function (axiosInstance: AxiosInstance) {
        return axiosInstance.get<UserAPIResponse>('/common-system-api/user')
    },
    get: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.get<UserAPIResponse>('/common-system-api/users/:id', { pathVars: { id } })
    },
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<UserAPIResponse[]>('/common-system-api/users/batch-get', ids)
    },
    list: function (axiosInstance: AxiosInstance, data: ListUsersAPIRequest) {
        return axiosInstance.get<UserAPIResponse[]>('/common-system-api/users', { params: data })
    },
    create: function (axiosInstance: AxiosInstance, data: CreateUserAPIRequest) {
        return axiosInstance.post<UserAPIResponse>('/common-system-api/users', data)
    },
    batchCreate: function (axiosInstance: AxiosInstance, data: CreateUserAPIRequest[]) {
        return axiosInstance.post<UserAPIResponse[]>('/common-system-api/users/batch-create', data)
    },
    update: function (axiosInstance: AxiosInstance, data: UpdateUserAPIRequest) {
        return axiosInstance.put<UserAPIResponse>('/common-system-api/users/:id', data, { pathVars: { id: data.userId } })
    },
    batchUpdate: function (axiosInstance: AxiosInstance, data: UpdateUserAPIRequest[]) {
        return axiosInstance.put<UserAPIResponse[]>('/common-system-api/users/batch-update', data)
    },
    delete: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.delete('/common-system-api/users/:id', { pathVars: { id } })
    },
    batchDelete: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/users/batch-delete', ids)
    }
}

export default userApi