import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function DistributionCentreListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63008 0 0 .63006 -28.658 -80.076)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 158">
            <rect transform="translate(57.675 153.7)" width="7.097" height="5.52" data-name="矩形 154" />
            <rect transform="translate(64.772 153.7)" width="6.309" height="5.52" data-name="矩形 155" />
            <rect transform="translate(60.829 148.18)" width="7.097" height="5.52" data-name="矩形 156" />
            <path d="M 80.057,143.8 64.528,133 49,143.8" data-name="路径 236" />
            <line transform="translate(51.366 142.66)" y2="17.349" data-name="直线 68" />
            <line transform="translate(78.178 142.66)" y2="17.349" data-name="直线 69" />
            <path transform="translate(-2.599,-5.847)" d="m57 165.85v-14.853h20.254v14.853" data-name="路径 237" />
        </g>
    </SvgIcon>
}