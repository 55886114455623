import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function DashboardIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 70" strokeWidth="1.64" fill="none" stroke="currentColor">
            <g data-name="矩形 69" transform="matrix(.95238 0 0 1 2.038 2.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 72" transform="matrix(.95238 0 0 1 2.038 13.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 70" transform="matrix(.95238 0 0 1 12.514 2.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
            <g data-name="矩形 71" transform="matrix(.95238 0 0 1 12.514 13.04)">
                <rect width="10" height="10" rx="2.1" ry="2" stroke="none" />
                <rect x=".8" y=".8" width="8.4" height="8.4" rx="1.26" ry="1.2" strokeWidth="1.68" />
            </g>
        </g>
    </SvgIcon>
}