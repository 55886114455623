
interface PageIfore {
    pageCode: string
    pageName: string
    url: string
    access: string
    category: string
}

export const pageInfoList: PageIfore[] = [
    { pageCode: 'ONS010REGULAR', pageName: 'Place Regular Order', url: '/placecustorder-regular', access: 'ORDER.ONS010REGULAR.VIEW', category: 'Order' },
    { pageCode: 'ONS010SPOT', pageName: 'Place Spot Order', url: '/placecustorder-spot', access: 'ORDER.ONS010SPOT.VIEW', category: 'Order' },
    { pageCode: 'ONS010SOWC', pageName: 'Place Spot Order Without Contract', url: '/placecustorder-sowc', access: 'ORDER.ONS010SOWC.VIEW', category: 'Order' },
    { pageCode: 'ONS030ORDER', pageName: 'Order Change/Cancel', url: '/cro-order', access: 'ORDER.ONS030ORDER.VIEW', category: 'Order' },
    { pageCode: 'ONS030FORECAST', pageName: 'Forecast Change', url: '/cro-forecast', access: 'ORDER.ONS030FORECAST.VIEW', category: 'Order' },
    { pageCode: 'OCS022', pageName: 'Received Place Order (Spot without contract)', url: '/rece', access: 'ORDER.OCS022.VIEW', category: 'Order' },
    { pageCode: 'OCS027', pageName: 'Received Place Change/Cancel (Spot without contract)', url: '/repcwc', access: 'ORDER.OCS027.VIEW', category: 'Order' },
    { pageCode: 'ORS020', pageName: 'Supplier Order Change/Cancel', url: '/scro', access: 'ORDER.ORS020.VIEW', category: 'Order' },
    { pageCode: 'OCS010', pageName: 'CO Monitoring List', url: '/co', access: 'ORDER.OCS010.VIEW', category: 'Order' },
    { pageCode: 'OPS010', pageName: 'PO Monitoring List', url: '/po', access: 'ORDER.OPS010.VIEW', category: 'Order' },
    { pageCode: 'OSS010', pageName: 'SO Monitoring List', url: '/so', access: 'ORDER.OSS010.VIEW', category: 'Order' },
    { pageCode: 'ORS010', pageName: 'Change/Cancel Request List', url: '/cc', access: 'ORDER.ORS010.VIEW', category: 'Order' },
    { pageCode: 'OCS030', pageName: 'CO Monitoring List (Spot without contract)', url: '/monitoring', access: 'ORDER.OCS030.VIEW', category: 'Order' },
    { pageCode: 'OPS020', pageName: 'PO Monitoring List (Spot without contract)', url: '/pospot', access: 'ORDER.OPS020.VIEW', category: 'Order' },
    { pageCode: 'OSS020', pageName: 'SO Monitoring List (Spot without contract)', url: '/soMonitoring', access: 'ORDER.OSS020.VIEW', category: 'Order' },
    { pageCode: 'LIS010', pageName: 'Inbound Monitor List', url: '/inbound', access: 'LOGI.LIS010.VIEW', category: 'Logistics' },
    { pageCode: 'LOS011', pageName: 'Download Delivery Plan', url: '/deliveryplan', access: 'LOGI.LOS011.VIEW', category: 'Logistics' },
    { pageCode: 'LOS020', pageName: 'Download Outbound Prioritization', url: '/outboundPrioritization', access: 'LOGI.LOS020.VIEW', category: 'Logistics' },
    { pageCode: 'LOS030', pageName: 'Outbound Monitoring List', url: '/outbound', access: 'LOGI.LOS030.VIEW', category: 'Logistics' },
    { pageCode: 'LOS040', pageName: 'Outbound To WIP', url: '/outboundToWIP', access: 'LOGI.LOS040.VIEW', category: 'Logistics' },
    { pageCode: 'CIS010', pageName: 'Cargo Tracking(Container Journey)', url: '/containerJourney', access: 'LOGI.CIS010.VIEW', category: 'Logistics' },
    { pageCode: 'CIS011', pageName: 'Cargo Tracking Detail', url: '/cargotrackingDetail', access: 'LOGI.CIS011.VIEW', category: 'Logistics' },
    { pageCode: 'LSS010', pageName: 'Shipping Detail List', url: '/shippingdetail', access: 'LOGI.LSS010.VIEW', category: 'Logistics' },
    { pageCode: 'LDS010', pageName: 'Customer Delivery Instruction List', url: '/di', access: 'LOGI.LDS010.VIEW', category: 'Logistics' },
    { pageCode: 'LDS010BU', pageName: 'Customer Delivery Instruction List For BU', url: '/diForBu', access: 'LOGI.LDS010BU.VIEW', category: 'Logistics' },
    { pageCode: 'AIS030', pageName: 'Customs Invoice(Export)', url: '/expcustomsinvoice', access: 'LOGI.AIS030.VIEW', category: 'Logistics' },
    { pageCode: 'AIS040', pageName: 'Customs Invoice(Import)', url: '/impcustomsinvoice', access: 'LOGI.AIS040.VIEW', category: 'Logistics' },
    { pageCode: 'AIS010', pageName: 'Buyer(GR) Invoice List', url: '/gr', access: 'ACCT.AIS010.VIEW', category: 'Accounting' },
    { pageCode: 'AIS020', pageName: 'Seller(GI) Invoice List', url: '/gi', access: 'ACCT.AIS020.VIEW', category: 'Accounting' },
    { pageCode: 'AIS060', pageName: 'Account Receivable (AR) Payment Status List', url: '/ar', access: 'ACCT.AIS060.VIEW', category: 'Accounting' },
    { pageCode: 'MTS020', pageName: 'Parts Inventory List(By Parts)', url: '/partsinventory', access: 'MT.MTS020.VIEW', category: 'Accounting' },
    { pageCode: 'MTS030', pageName: 'Parts Inventory List(By Package)', url: '/packageinventory', access: 'MT.MTS030.VIEW', category: 'Accounting' },
    { pageCode: 'MTS040', pageName: 'Download Inbound Plan', url: '/dowloadinbplan', access: 'MT.MTS040.VIEW', category: 'Accounting' },
    { pageCode: 'MTS050', pageName: 'Download Overall Visualisation', url: '/dowloadOverallVisualisation', access: 'MT.MTS050.VIEW', category: 'Accounting' },
    { pageCode: 'DAS010', pageName: 'PO Management', url: '/dashboard/pomanagement', access: 'DASH.DAS010.VIEW', category: 'Dashboard' },
    { pageCode: 'DAS012', pageName: 'Cost Monitoring', url: '/dashboard/costs', access: 'DASH.DAS012.VIEW', category: 'Dashboard' },
    { pageCode: 'DAS013', pageName: 'Vendor Analysis', url: '/dashboard/vendorAnalysis', access: 'DASH.DAS013.VIEW', category: 'Dashboard' },
    { pageCode: 'DAS014', pageName: 'Inventory Management', url: '/dashboard/inventory', access: 'DASH.DAS014.VIEW', category: 'Dashboard' },
    { pageCode: 'DAS015', pageName: 'Accounts Receivable', url: '/dashboard/acctReceivable', access: 'DASH.DAS015.VIEW', category: 'Dashboard' },
    { pageCode: 'MLS010', pageName: 'Company List', url: '/company', access: 'MARS.MLS010.VIEW', category: 'Master' },
    { pageCode: 'MLS020', pageName: 'Business Unit List', url: '/bu', access: 'MARS.MLS020.VIEW', category: 'Master' },
    { pageCode: 'MLS030', pageName: 'Customer List', url: '/customer', access: 'MARS.MLS030.VIEW', category: 'Master' },
    { pageCode: 'MLS040', pageName: 'Supplier List', url: '/supplier', access: 'MARS.MLS040.VIEW', category: 'Master' },
    { pageCode: 'MLS050', pageName: 'Distribution Center List', url: '/dc', access: 'MARS.MLS050.VIEW', category: 'Master' },
    { pageCode: 'MLS070', pageName: 'Global Parts List', url: '/globalparts', access: 'MARS.MLS070.VIEW', category: 'Master' },
    { pageCode: 'MLS080', pageName: 'Unit Parts List', url: '/unitparts', access: 'MARS.MLS080.VIEW', category: 'Master' },
    { pageCode: 'MLS140', pageName: 'Order Group List', url: '/orderGroup', access: 'MARS.MLS140.VIEW', category: 'Master' },
    { pageCode: 'MLS090', pageName: 'Contract List', url: '/contract', access: 'MARS.MLS090.VIEW', category: 'Master' },
    { pageCode: 'MLS100', pageName: 'Contract Route List', url: '/contractroute', access: 'MARS.MLS100.VIEW', category: 'Master' },
    { pageCode: 'MLS170', pageName: 'FOB Setting', url: '/fobSetting', access: 'MARS.MLS170.VIEW', category: 'Master' },
    { pageCode: 'MLS110', pageName: 'Payment Terms List', url: '/payment', access: 'MARS.MLS110.VIEW', category: 'Master' },
    { pageCode: 'MLS180', pageName: 'Credit Check Rule', url: '/creditrules', access: 'MASTER.MLS180.VIEW', category: 'Master' },
    { pageCode: 'MLS190', pageName: 'Credit Check Rule Setting', url: '/creditsetting', access: 'MASTER.MLS190.VIEW', category: 'Master' },
    { pageCode: 'MLS120', pageName: 'Shipping Route List', url: '/shippingroute', access: 'MARS.MLS120.VIEW', category: 'Master' },
    { pageCode: 'MLS250', pageName: 'Cargo Status Setting', url: '/cargostatus', access: 'MARS.MLS250.VIEW', category: 'Master' },
    { pageCode: 'MOS020', pageName: 'Request List', url: '/requestTo', access: 'MARS.MOS020.VIEW', category: 'Master' },
    { pageCode: 'MOS021AddNewPart', pageName: 'Request Add New Part', url: '/requestTo/addNewPart', access: 'MARS.MOS021.CREATEADD', category: 'Master' },
    { pageCode: 'MOS021Modify', pageName: 'Request Modify Packing', url: '/requestTo/editPacking', access: 'MARS.MOS021.CREATEMODIFY', category: 'Master' },
    { pageCode: 'MOS021Remove', pageName: 'Request Remove Part', url: '/requestTo/remove', access: 'MARS.MOS021.CREATEREMOVE', category: 'Master' },
    { pageCode: 'MOS030', pageName: 'Received Request List', url: '/receivedRequest', access: 'MARS.MOS030.VIEW', category: 'Master' },
    { pageCode: 'MGS010', pageName: 'Common Download and Upload', url: '/commondownloadupload', access: 'MARS.MGS010.VIEW', category: 'Master' },
    { pageCode: 'MLS060', pageName: 'Port List', url: '/port', access: 'MARS.MLS060.VIEW', category: 'Master' },
    { pageCode: 'MLS260', pageName: 'Announcements List', url: '/announcements', access: '', category: 'Master' },
    { pageCode: 'CAS010', pageName: 'Role List', url: '/role', access: 'ACCS.CAS010.VIEW', category: 'Privilege' },
    { pageCode: 'CAS020', pageName: 'User List', url: '/user', access: 'ACCS.CAS020.VIEW', category: 'Privilege' },
    { pageCode: 'CAS030', pageName: 'Access History List', url: '/accessHistory', access: 'ACCS.CAS030.VIEW', category: 'Privilege' },
    { pageCode: 'CAS040', pageName: 'User Active List', url: '/userActive', access: 'ACCS.CAS040.VIEW', category: 'Privilege' },
    { pageCode: 'CSUGS130', pageName: 'Project', url: '/project', access: 'STCK.CSUGS130.VIEW', category: 'IMT' },
    { pageCode: 'CSUHS120', pageName: 'Usage History', url: '/usageHistory', access: 'STCK.CSUHS120.VIEW', category: 'IMT' },
    { pageCode: 'CSUHS140', pageName: 'Warehouse Actual Usage', url: '/actualUsage', access: 'STCK.CSUHS140.VIEW', category: 'IMT' },
    { pageCode: 'CSUHS150', pageName: 'Customer Actual Usage', url: '/customerActualUsage', access: 'STCK.CSUHS150.VIEW', category: 'IMT' },
    { pageCode: 'CSUGS120', pageName: 'Customer Usage', url: '/cuDownloadUpload', access: 'STCK.CSUGS120.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS110', pageName: 'Weekly Inventory Simulation', url: '/weeklyRundown', access: 'STCK.SMGTS110.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS210', pageName: 'Daily Rundown List', url: '/dailyRundownList', access: 'STCK.SMGTS210.VIEW', category: 'IMT' },
    { pageCode: 'VSRPS010', pageName: 'Pokayoke List', url: '/pokayoke', access: 'STCK.VSRPS010.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS310', pageName: 'Customer Inventory List', url: '/customerStockList', access: 'STCK.SMGTS310.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS410', pageName: 'Inventory Adjustment List', url: '/stockAdjustmentList', access: 'STCK.SMGTS410.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS610', pageName: 'Inventory History', url: '/impInventoryHistoryList', access: 'STCK.SMGTS610.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS710', pageName: 'Incoming', url: '/incoming', access: 'STCK.SMGTS710.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS810', pageName: 'Outbound', url: '/outgoing', access: 'STCK.SMGTS810.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS710MAPA', pageName: 'Incoming Mapa', url: '/incomingMapa', access: 'STCK.SMGTS710MAPA.VIEW', category: 'IMT' },
    { pageCode: 'SMGTS810MAPA', pageName: 'Outbound Mapa', url: '/outgoingMapa', access: 'STCK.SMGTS810MAPA.VIEW', category: 'IMT' },
    { pageCode: 'INT050', pageName: 'Place Change/Cancel', url: '/bcro', access: 'INT.INT050.VIEW', category: 'IMT' },
    { pageCode: 'INT060', pageName: 'Change/Cancel History', url: '/bccHistory', access: 'INT.INT060.VIEW', category: 'IMT' },
    { pageCode: 'MSOGS010', pageName: 'Order Calculation Grouping', url: '/orderCalculationGrouping', access: 'STCK.MSOGS010.VIEW', category: 'IMT' },
    { pageCode: 'MSAUF010', pageName: 'Inventory Management & Order Calculation Setting', url: '/alarmUsagePattern', access: 'STCK.MSAUF010.VIEW', category: 'IMT' },
    { pageCode: 'OCCLS010', pageName: 'Order Calculation List', url: '/orderCalculation', access: 'STCK.OCCLS010.VIEW', category: 'IMT' },
    { pageCode: 'OCCLS010PNA', pageName: 'Order Calculation List', url: '/orderCalculationPNA', access: 'STCK.OCCLS010PNA.VIEW', category: 'IMT' },
    { pageCode: 'MSOGS020', pageName: 'Order Calculation Grouping', url: '/orderCalculationGroupingEhm', access: 'STCK.MSOGS020.VIEW', category: 'IMT' },
    { pageCode: 'MSAUF020SM', pageName: 'Inventory Management Master', url: '/stockManagementMaster', access: 'STCK.MSAUF020SM.VIEW', category: 'IMT' },
    { pageCode: 'MSAUF020OC', pageName: 'Order Calculation Master', url: '/orderCalculationMaster', access: 'STCK.MSAUF020OC.VIEW', category: 'IMT' },
    { pageCode: 'MSAUF020SMOC', pageName: 'Inventory Management & Order Calculation Setting', url: '/stockManagementOrderCalcMaster', access: 'STCK.MSAUF020SMOC.VIEW', category: 'IMT' },
    { pageCode: 'OCCLS010SMT', pageName: 'Order Calculation List', url: '/orderCalculationEhm', access: 'STCK.OCCLS010SMT.VIEW', category: 'IMT' },
    { pageCode: 'INT010', pageName: 'Manage External Data', url: '/manageExternalData', access: 'INT.INT010.VIEW', category: 'Integration' },
    { pageCode: 'INT020', pageName: 'My Upload', url: '/myUpload', access: 'INT.INT020.VIEW', category: 'Integration' },
    { pageCode: 'INT030', pageName: 'Integration Batch', url: '/integrationBatch', access: 'INT.INT030.VIEW', category: 'Integration' },
    { pageCode: 'INT040', pageName: 'External Order List', url: '/externalOrderList', access: 'INT.INT040.VIEW', category: 'Integration' },
    { pageCode: 'TDL010', pageName: 'Todo List', url: '/todolist', access: '', category: 'Todo' },
]