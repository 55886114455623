import { AppBar, makeStyles, Toolbar, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { AnnouncementList } from "../../components/Announcement/AnnouncementList"
import { Scrollbar } from "../../components/Scrollbar/Scrollbar"
import { useApplicationSelector } from "../Application/applicationSlice"

export function WhatsNew() {
    useEffect(() => {
        const frontLayer = document.querySelector('#webapp-lcbm') as HTMLElement
        frontLayer.style.display = 'none'
        return () => {
            frontLayer.style.display = 'block'
        }
    }, [])

    const [scrollTarget, setScrollTarget] = useState<HTMLElement | undefined>(undefined)
    const location = useLocation()
    const announcements = useApplicationSelector(state => state.ui.announcements)
    useEffect(() => {
        const hash = location.hash
        const announcementId = hash.substring(3)
        if (announcements.find(announcement => announcement.id === announcementId)) {
            const anchorEl = document.querySelector(hash) as HTMLElement | null
            if (scrollTarget && anchorEl) {
                setTimeout(() => {
                    scrollTarget.scrollTo({ top: anchorEl.offsetTop - 64, behavior: 'smooth' })
                })
            }
        }
    }, [announcements, location.hash, scrollTarget])

    const classes = useStyles()
    return <Scrollbar ref={instance => {
        setScrollTarget(instance?.osTarget()?.querySelector('.os-viewport') as HTMLDivElement || null)
    }} className={classes.scrollbar}>
        <div className={classes.root}>
            <AppBar position="fixed" color="transparent" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h1" >What's New on BriVge</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.placeholder}></div>
            <AnnouncementList />
        </div>
    </Scrollbar>
}

const useStyles = makeStyles(theme => ({
    scrollbar: {
        width: '100%',
        height: '100%',
    },
    root: {
        width: '100%',
        minHeight: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    appbar: {
        color: theme.palette.text.primary,
        backgroundColor: 'rgba(250, 250, 250, 0.8)',
        backdropFilter: 'blur(8px)',
        boxShadow: 'inset 0px -1px 1px #eeeeee',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'center',
    },
    placeholder: {
        height: 64,
    },
}))