import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function MasterDownloadUploadIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62002 0 0 .62002 -227.04 -29.113)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 171">
            <path transform="translate(-3.31)" d="m388.82 68.3 5.676-6.622h-2.838c0-5.647-3.294-9.664-10.406-10.375 5.142 2.646 4.73 5.434 4.73 10.375h-2.838z" strokeLinejoin="round" data-name="路径 310" />
            <g strokeMiterlimit="10">
                <path transform="translate(0,-5.206)" d="m370 74.647v-1.412l3.529-4.235h5.647" data-name="路径 311" />
                <path transform="translate(-9.119,-5.206)" d="m410.18 74.647v-1.412l-3.529-4.235h-5.647" data-name="路径 312" />
                <path transform="translate(0,-7.56)" d="m392.59 77-2.117 2.117h-9.882l-2.118-2.117h-8.47v10.587h31.057v-10.587z" data-name="路径 313" />
            </g>
        </g>
    </SvgIcon>
}