import { useCallback } from 'react'
import { FormattedMessage } from "react-intl"
import { Nav } from '../../Nav/Nav'
import { ChangeCancelRequestListIcon } from "./assets/ChangeCancelRequestListIcon"
import { ChangeOrderForecastIcon } from "./assets/ChangeOrderForecastIcon"
import { CoMonitoringListIcon } from "./assets/CoMonitoringListIcon"
import { OrderIcon } from "./assets/OrderIcon"
import { PlaceOrderIcon } from "./assets/PlaceOrderIcon"
import { PoMonitoringListIcon } from "./assets/PoMonitoringListIcon"
import { SoMonitoringListIcon } from "./assets/SoMonitoringListIcon"

export interface OrderNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function OrderNav(props: OrderNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<OrderIcon />}
        iconId={'Order_Nav'}
        title={<FormattedMessage id="appbar.order" />}
        columns={[{
            name: <FormattedMessage id="nav.operations" />,
            items: [{
                access: ["ORDER.ONS010REGULAR.VIEW", "ORDER.ONS010SPOT.VIEW", "ORDER.ONS010SOWC.VIEW"],
                icon: <PlaceOrderIcon />,
                title: <FormattedMessage id="ONS010" />,
                path: '/placecustorder',
            }, {
                access: ["ORDER.ONS030ORDER.VIEW", "ORDER.ONS030FORECAST.VIEW", "ORDER.ONS030ORDERWC.VIEW"],
                icon: <ChangeOrderForecastIcon />,
                title: <FormattedMessage id="ONS030" />,
                path: '/cro',
            }, {
                access: ["ORDER.ONS040ORDER.VIEW", "ORDER.ONS040FORECAST.VIEW"],
                icon: <ChangeOrderForecastIcon />,
                title: <FormattedMessage id="ONS040" />,
                path: '/bro',
            },
            {
                access: "ORDER.OCS022.VIEW",
                icon: <ChangeCancelRequestListIcon />,
                title: <FormattedMessage id="OCS022" />,
                path: '/rece',
            },
            {
                access: "ORDER.OCS027.VIEW",
                icon: <ChangeOrderForecastIcon />,
                title: <FormattedMessage id="OCS027" />,
                path: '/repcwc',
            }, {
                access: "ORDER.ORS020.VIEW",
                icon: <ChangeOrderForecastIcon />,
                title: <FormattedMessage id="ORS020" />,
                path: '/scro',
            },
            ]
        }, {
            name: <FormattedMessage id="nav.history" />,
            items: [{
                access: "ORDER.OCS010.VIEW",
                icon: <CoMonitoringListIcon />,
                title: <FormattedMessage id="OCS010" />,
                path: '/co',
            }, {
                access: "ORDER.OPS010.VIEW",
                icon: <PoMonitoringListIcon />,
                title: <FormattedMessage id="OPS010" />,
                path: '/po',
            },
            {
                access: "ORDER.OSS010.VIEW",
                icon: <SoMonitoringListIcon />,
                title: <FormattedMessage id="OSS010" />,
                path: '/so',
            }, {
                access: "ORDER.ORS010.VIEW",
                icon: <ChangeCancelRequestListIcon />,
                title: <FormattedMessage id="ORS010" />,
                path: '/cc',
            },]
        }
            , {
            name: <FormattedMessage id="nav.spotWithContract" />,
            items: [
                {
                    access: "ORDER.OCS030.VIEW",
                    icon: <PoMonitoringListIcon />,
                    title: <FormattedMessage id="OCS030" />,
                    path: '/monitoring',
                },
                {
                    access: "ORDER.OPS020.VIEW",
                    icon: <PoMonitoringListIcon />,
                    title: <FormattedMessage id="OPS020" />,
                    path: '/pospot',
                },
                {
                    access: "ORDER.OSS020.VIEW",
                    icon: <PoMonitoringListIcon />,
                    title: <FormattedMessage id="OSS020" />,
                    path: '/soMonitoring',
                },
            ]
        }
        ]}
    />
}