import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function OrderCalculationGroupingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.072748 0 0 .072748 -2.4448 -2.2628)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
            <g strokeWidth="3.7795">
                <rect x="123.35" y="98.777" width="22.224" height="22.224" />
                <line x1="164.73" x2="248.26" y1="101.57" y2="101.57" />
                <line x1="164.73" x2="256.01" y1="117.86" y2="117.86" />
                <rect x="123.35" y="145.98" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="148.78" y2="148.78" />
                <line x1="164.73" x2="203.81" y1="165.07" y2="165.07" />
                <rect x="123.35" y="193.23" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="196.02" y2="196.02" />
                <line x1="164.73" x2="203.81" y1="212.31" y2="212.31" />
                <rect x="123.35" y="240.43" width="22.224" height="22.224" />
                <line x1="164.73" x2="203.81" y1="243.23" y2="243.23" />
                <line x1="164.73" x2="203.81" y1="259.52" y2="259.52" />
            </g>
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.09 50.69 61 52.79 66.04 47.75" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 63.39 61.35 65.49 66.39 60.45" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 75.99 61.35 78.09 66.39 73.05" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="59.44 88.58 61.35 90.68 66.39 85.64" />
            <path d="m288.63 139.56h-76.686c-5.5559 0-10.054 4.4976-10.054 10.054v163.46c0 5.5559 4.4976 10.054 10.054 10.054h76.686" strokeWidth="7.559" />
            <path d="m288.63 139.56h33.562c5.5559 0 10.054 4.4976 10.054 10.054v163.46c0 5.5559-4.4976 10.054-10.054 10.054h-33.562" strokeWidth="7.559" />
            <g strokeWidth="3.7795">
                <path d="m288.63 157.93h21.203c2.7212 0 4.9512 2.2299 4.9512 4.9512v19.464c0 2.7212-2.2299 4.9512-4.9512 4.9512h-21.203" />
                <path d="m288.63 157.93h-64.327c-2.7212 0-4.9512 2.2299-4.9512 4.9512v19.464c0 2.7212 2.2299 4.9512 4.9512 4.9512h64.327" />
                <rect x="221.39" y="201.96" width="20.183" height="20.183" />
                <rect x="257.56" y="201.96" width="20.183" height="20.183" />
                <rect x="294.37" y="201.96" width="20.183" height="20.183" />
                <rect x="221.39" y="239.79" width="20.183" height="20.183" />
                <rect x="257.56" y="239.79" width="20.183" height="20.183" />
                <rect x="294.37" y="238.69" width="20.183" height="58.734" />
                <rect x="221.39" y="277.62" width="20.183" height="20.183" />
                <rect x="257.56" y="277.62" width="20.183" height="20.183" />
            </g>
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="82.94 40.19 98.79 40.19 98.79 46.07 98.79 59.46" strokeWidth="2" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="83.68 40.19 50.93 40.19 50.93 100.65 77.44 100.65" strokeWidth="2" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="50.54 45.27 46.27 45.27 46.27 105.73 77.84 105.73" />
            <polyline transform="matrix(3.7795 0 0 3.7795 -93.408 -86.457)" points="45.65 49.35 41.38 49.35 41.38 109.81 89.25 109.81 89.25 108.77" />
        </g>
    </SvgIcon>
}