import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function SellerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63029 0 0 .63029 -155.82 -221.21)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4073">
            <g strokeLinecap="round">
                <path transform="translate(-21.739,-3.478)" d="m301 381.96h1.7a0.565 0.565 0 0 0 0.565-0.565v-15.766a0.626 0.626 0 0 0-0.626-0.626h-1.639" data-name="路径 1877" />
                <path transform="translate(-5.217,-3.478)" d="m274.3 365h-11.3v16.957h13.565" data-name="路径 1878" />
                <path transform="translate(-8.696,-6.957)" d="m274.39 373h-2.261a1.1305 1.1305 0 0 0 0 2.261h1.13a1.1305 1.1305 0 1 1 0 2.261h-2.26" data-name="路径 1879" />
                <line transform="translate(264,365)" y2="1" data-name="直线 865" />
                <line transform="translate(264,371)" y2="1" data-name="直线 866" />
                <line transform="translate(261,375)" x1="6" data-name="直线 867" />
                <line transform="translate(269,375)" x1="2" data-name="直线 868" />
            </g>
            <path transform="translate(0,-13.913)" d="m257.78 392.39a3.393 3.393 0 1 0-3.391 3.391h12.435a0.565 0.565 0 0 0 0.565-0.565v-2.826" data-name="路径 1880" />
            <path transform="translate(0 -.87)" d="m257.78 362.39a3.3915 3.3915 0 1 0-6.783 0v16.957" data-name="路径 1881" />
            <path transform="translate(-17.391)" d="m292.7 357a1.7 1.7 0 0 1 1.7 1.7v19.783a6.242 6.242 0 0 1-1.7 3.957 6.242 6.242 0 0 1-1.7-3.957v-19.783a1.7 1.7 0 0 1 1.7-1.7z" data-name="路径 1882" />
            <path transform="translate(-15.652,-2.609)" d="m292.65 363h-4.522a1.13 1.13 0 0 0-1.13 1.13v5.264" strokeLinecap="round" data-name="路径 1883" />
            <line transform="translate(274,377)" x2="3" data-name="直线 869" />
            <line transform="translate(275,382)" y1="1" strokeLinecap="round" data-name="直线 870" />
        </g>
    </SvgIcon>
}