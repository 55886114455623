import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { RequestReceiveListIcon } from "../MasterNav/assets/RequestReceiveListIcon"
import { Nav } from "../Nav"
import { RoleListIcon } from "./assets/RoleListIcon"
import { UserListIcon } from "./assets/UserListIcon"

export interface PrivilegeNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function PrivilegeNav(props: PrivilegeNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<RoleListIcon />}
        iconId={'Privilege_Nav'}
        title={<FormattedMessage id="appbar.privilege" />}
        columns={[{
            items: [{
                access: "ACCS.CAS010.VIEW",
                icon: <RoleListIcon />,
                title: <FormattedMessage id="CAS010" />,
                path: '/role',
            }, {
                access: "ACCS.CAS020.VIEW",
                icon: <UserListIcon />,
                title: <FormattedMessage id="CAS020" />,
                path: '/user',
            },
            {
                access: ["ACCS.CAS030.VIEW"],
                icon: <RequestReceiveListIcon />,
                title: <FormattedMessage id="CAS030" />,
                path: '/accessHistory',
            },
            {
                access: ["ACCS.CAS040.VIEW"],
                icon: <UserListIcon />,
                title: <FormattedMessage id="CAS040" />,
                path: '/userActive',
            }
            ]
        }]}
    />
}
