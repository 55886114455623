import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { DownloadInboundPlanIcon } from "./assets/DownloadInboundPlanIcon"
import { DownloadOverallVisualisationIcon } from "./assets/DownloadOverallVisualisationIcon"
import { ManagementToolIcon } from "./assets/ManagementToolIcon"
import { PartsInventoryListByPackageIcon } from "./assets/PartsInventoryListByPackageIcon"
import { PartsInventoryListByPartsIcon } from "./assets/PartsInventoryListByPartsIcon"

export interface MtNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function MtNav(props: MtNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<ManagementToolIcon />}
        iconId={'InventoryMT_Nav'}
        title={<FormattedMessage id="appbar.mt" />}
        columns={[{
            items: [{
                access: "MT.MTS020.VIEW",
                icon: <PartsInventoryListByPartsIcon />,
                title: <FormattedMessage id="MTS020" />,
                path: '/partsinventory',
            }, {
                access: "MT.MTS030.VIEW",
                icon: <PartsInventoryListByPackageIcon />,
                title: <FormattedMessage id="MTS030" />,
                path: '/packageinventory',
            }, {
                access: "MT.MTS040.VIEW",
                icon: <DownloadInboundPlanIcon />,
                title: <FormattedMessage id="MTS040" />,
                path: '/dowloadinbplan',
            }, {
                access: "MT.MTS050.VIEW",
                icon: <DownloadOverallVisualisationIcon />,
                title: <FormattedMessage id="MTS050" />,
                path: '/dowloadOverallVisualisation',
            },]
        }]}
    />
}