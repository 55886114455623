import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function RoleListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.62162 0 0 .62162 -128.21 -28.054)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 163">
            <g strokeMiterlimit="10">
                <path transform="translate(0,-5.467)" d="m221.14 70.071v3.392h-8.141v-7.463h6.106" data-name="路径 268" />
                <path transform="translate(-.965 -5.467)" d="m216 69.392 2.035 2.035 4.749-5.427" data-name="路径 269" />
                <path transform="translate(0,-10.612)" d="m221.14 86.071v3.392h-8.141v-7.463h6.106" data-name="路径 270" />
                <path transform="translate(-.965 -10.612)" d="m216 85.392 2.035 2.035 4.749-5.427" data-name="路径 271" />
                <path transform="translate(0 -.322)" d="m221.14 54.071v3.392h-8.141v-7.463h6.106" data-name="路径 272" />
                <path transform="translate(-.965 -.322)" d="m216 53.392 2.035 2.035 4.749-5.427" data-name="路径 273" />
            </g>
            <g strokeLinejoin="round">
                <path transform="translate(-5.467,-4.18)" d="m240.86 74.212a2.861 2.861 0 0 0 2.714-2.714v-6.106c0-2.241-4.427-3.392-6.784-3.392s-6.785 1.151-6.785 3.392v6.108a2.861 2.861 0 0 0 2.714 2.714" data-name="路径 274" />
                <path transform="translate(-7.396)" d="m238.71 55.784a2.6 2.6 0 0 0 2.714-2.714v-1.356a2.714 2.714 0 1 0-5.427 0v1.357a2.6 2.6 0 0 0 2.713 2.713z" data-name="路径 275" />
                <line transform="translate(235.63 62.74)" y1="17.782" data-name="直线 86" />
                <line transform="translate(227.55 62.74)" y2="17.782" data-name="直线 87" />
                <line transform="translate(231.59,69.206)" y2="11.316" data-name="直线 88" />
            </g>
        </g>
    </SvgIcon>
}