import { Collapse, Grow, IconButton, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core'
import { CheckCircleOutline, ErrorOutline, ExpandLess, ExpandMore, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useCallback, useState } from "react"
import { FormattedMessage } from 'react-intl'
import { IntlString } from '../../utils/IntlString'
import { Notification } from '../../utils/Notification'
import { Scrollbar } from "../Scrollbar/Scrollbar"

export interface NoticeHistoryItemProps {
    notification: Notification & { id: string | number, dismissed?: boolean },
}

export function NoticeHistoryItem(props: NoticeHistoryItemProps) {
    const { notification } = props
    const { type, title, dismissed } = notification
    const messages = !notification.messages ? [] : notification.messages instanceof Array ? notification.messages : [notification.messages]

    const [expanded, setExpanded] = useState(true)
    const styles = useStyles()
    return <Grow in={!dismissed}>
        <Alert severity={type} icon={false} className={styles.root}>
            <div className={styles.header}>
                <Icon severity={type} />
                <Title title={title} />
                {messages.length > 0 && <ExpandButton expanded={expanded} setExpanded={setExpanded} />}
            </div>
            {messages.length > 0 && <Content expanded={expanded} messages={messages} />}
        </Alert>
    </Grow>
}

function Icon({ severity }: { severity: string }) {
    const theme = useTheme()
    if (severity === 'info') {
        return <InfoOutlined style={{ color: theme.palette.info.main }} />
    } else if (severity === 'success') {
        return <CheckCircleOutline style={{ color: theme.palette.success.main }} />
    } else if (severity === 'warning') {
        return <ReportProblemOutlined style={{ color: theme.palette.warning.main }} />
    } else if (severity === 'error') {
        return <ErrorOutline style={{ color: theme.palette.error.main }} />
    } else {
        return null
    }
}

function Title({ title }: { title: string | IntlString }) {
    const styles = useStyles()
    const titleElement = typeof title === 'string' ? title : <FormattedMessage id={title.code} values={{ ...title.args }} />
    return <Tooltip title={titleElement}>
        <Typography variant="subtitle1" noWrap className={styles.title}>
            {titleElement}
        </Typography>
    </Tooltip>
}

function ExpandButton({ expanded, setExpanded }: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) {
    const handleClick = useCallback(() => setExpanded(v => !v), [setExpanded])
    return <IconButton size="small" onClick={handleClick}>
        <Tooltip title={<FormattedMessage id={expanded ? 'notice.shrink' : 'notice.expand'} />}>
            {expanded ? <ExpandMore /> : <ExpandLess />}
        </Tooltip>
    </IconButton>
}

function Content({ expanded, messages }: { expanded: boolean, messages: (string | IntlString)[] }) {
    const styles = useStyles()
    return <Collapse in={expanded}>
        <Scrollbar className={styles.content}>
            {messages.map((message, index) =>
                <Typography key={index} variant="body1" color="textPrimary" className={styles.message}>
                    {typeof message === 'string' ? message : <FormattedMessage id={message.code} values={{ ...message.args }} defaultMessage={message.defaultMessage} />}
                </Typography>
            )}
        </Scrollbar>
    </Collapse>
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${theme.spacing(2)}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        transition: `all ${theme.transitions.duration.standard} ${theme.transitions.easing.easeInOut}`,
        borderRadius: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1 1 auto',
        padding: `0 ${theme.spacing(0.5)}px`,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        padding: theme.spacing(0.5),
        borderWidth: 4,
        borderColor: 'rgba(20,20,20,0.3)',
        borderLeftStyle: 'solid',
        lineHeight: '1rem',
        background: 'rgba(255,255,255,0.5)',
        maxHeight: 100,
        overflow: 'auto',
    },
    message: {
        margin: `${theme.spacing(0.5)}px 0`,
    },
}))

