import { Input, makeStyles, Typography } from "@material-ui/core"
import { useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { resetPasswordActions, useResetPasswordSelector } from "./resetPasswordSlice"

export function ConfirmNewPasswordInput() {
    const dispatch = useDispatch()
    const confirmNewPassword = useResetPasswordSelector(state => state.confirmNewPassword)
    const valid = useResetPasswordSelector(state => !state.confirmNewPasswordMessage)
    const message = useResetPasswordSelector(state => state.confirmNewPasswordMessage)
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetPasswordActions.setConfirmNewPassword(e.target.value))
    }, [dispatch])
    const intl = useIntl()
    const styles = useStyles()
    return <div className={styles.root}>
        <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="resetPassword.confirmNewPassword" />
        </Typography>
        <Input type="password"
            id="confirm-new-password"
            autoComplete="confirm-new-password"
            value={confirmNewPassword}
            placeholder={intl.formatMessage({ id: 'resetPassword.placeholder.confirmNewPassword' })}
            onChange={handleChange}
            fullWidth
            disableUnderline
            className={styles.input}
        />
        {!valid && <Typography variant="body2" color="error">
            {typeof message === 'string'
                ? message
                : <FormattedMessage id={message.code} values={message.args as any} />}
        </Typography>}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1.5)
    },
    input: {
        height: 40,
        padding: theme.spacing(2),
        marginTop: theme.spacing(0.5),
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.background.paper,
    },
}))