import 'overlayscrollbars/css/OverlayScrollbars.css'
import React from "react"
import ReactDOM from "react-dom"
import singleSpaReact from "single-spa-react"
import ErrorBoundary from "./layouts/ErrorBoundary/ErrorBoundary"
import { Root, RootProps } from "./Root"

const lifecycles = singleSpaReact<RootProps>({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    return <ErrorBoundary err={err} info={info} props={props} />
  },
})

export const { bootstrap, mount, unmount } = lifecycles
