import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function AccountsReceivableIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.20307 0 0 .20307 -4.3043 -3.0264)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="47.78" y2="47.78" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="99.58" y2="99.58" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="65.05" y2="65.05" />
            <line strokeWidth="5" x1="36.5" x2="43.01" y1="82.31" y2="82.31" />
            <rect x="53.12" y="45.1" width="11.17" height="11.17" />
            <line x1="70.1" x2="98.07" y1="47.33" y2="47.33" />
            <line x1="70.1" x2="98.07" y1="53.96" y2="53.96" />
            <rect x="53.12" y="67.74" width="11.17" height="11.17" />
            <line x1="70.52" x2="98.49" y1="69.97" y2="69.97" />
            <line x1="70.52" x2="93.07" y1="76.6" y2="76.6" />
            <rect x="53.12" y="90.38" width="11.17" height="11.17" />
            <line x1="70.52" x2="89.49" y1="92.62" y2="92.62" />
            <line x1="70.52" x2="92.17" y1="99.24" y2="99.24" />
            <path d="m108.26 68.46v-32.08c0-3.08-2.5-5.58-5.58-5.58h-57.66c-3.08 0-5.58 2.5-5.58 5.58v75.24c0 3.08 2.5 5.58 5.58 5.58h57.66c3.08 0 5.58-2.5 5.58-5.58v-1.4" />
            <path d="m108.26 68.46c0.73-0.08 1.48-0.12 2.23-0.12 11.6 0 21 9.4 21 21s-9.4 21-21 21c-0.76 0-1.5-0.04-2.23-0.12" />
            <path d="m108.26 68.46c-10.55 1.12-18.77 10.04-18.77 20.88s8.22 19.77 18.77 20.88" />
            <path strokeWidth="1" fill="currentColor" d="m119.05 88.43v5.61c0 0.44-0.16 0.82-0.49 1.14s-0.72 0.48-1.17 0.48h-4.93v3.95c0 0.45-0.16 0.83-0.48 1.15s-0.7 0.47-1.14 0.47c-0.46 0-0.85-0.16-1.17-0.47s-0.48-0.7-0.48-1.15v-3.99h-4.89c-0.44 0-0.82-0.16-1.14-0.47s-0.48-0.7-0.48-1.15 0.16-0.83 0.47-1.15 0.7-0.47 1.15-0.47h11.44v-2.33h-11.4c-0.45 0-0.83-0.15-1.15-0.46s-0.47-0.69-0.47-1.16v-5.59c0-0.45 0.16-0.83 0.47-1.15s0.7-0.47 1.15-0.47h4.85v-3.95c0-0.44 0.15-0.82 0.45-1.14s0.71-0.48 1.22-0.48c0.44 0 0.81 0.16 1.13 0.47s0.47 0.7 0.47 1.15v3.95h4.97c0.45 0 0.84 0.16 1.17 0.48s0.49 0.7 0.49 1.14-0.16 0.84-0.49 1.17-0.72 0.49-1.17 0.49h-11.44v2.31h11.4c0.45 0 0.84 0.16 1.17 0.48s0.49 0.7 0.49 1.14z" />
        </g>
    </SvgIcon>
}