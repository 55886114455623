import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PortListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.6341 0 0 .63409 -231.6 -29.102)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 159">
            <path transform="translate(-10.789,-2.36)" d="m401 59h6.629v1.989h1.326v2.651h-1.326v3.977" data-name="路径 238" />
            <line transform="translate(396,51.993)" y2="5" data-name="直线 70" />
            <line transform="translate(393,54.993)" y2="2" data-name="直线 71" />
            <path transform="translate(-5.057,-4.046)" d="m390.63 64h-6.629v3.977" data-name="路径 239" />
            <path transform="translate(-8.429,-4.046)" d="m400.63 69.3v-5.3h-6.629v5.3" data-name="路径 240" />
            <path transform="translate(-1.686,-4.046)" d="m380.63 64h-6.629v3.977" data-name="路径 241" />
            <path transform="translate(-6.743,-1.686)" d="m395.63 61.64v-4.64h-6.629" data-name="路径 242" />
            <path transform="translate(-3.371,-1.686)" d="m385.63 61.64v-4.64h-6.629v4.64" data-name="路径 243" />
            <path transform="translate(0,-6.069)" d="m374.05 77.808c0-3.808-5.05-3.831-5.05-7.808h11.855l3.109 1.326h14.2v3.926a3.768 3.768 0 0 1-0.363 2.071" data-name="路径 244" />
            <path transform="translate(-1.901,-9.531)" d="m374.64 82.248 2.19-1.716a1.159 1.159 0 0 1 1.584 0.114l0.818 0.823a1.16 1.16 0 0 0 1.609 0.093l1.23-1.012a1.156 1.156 0 0 1 1.578 0.064l0.935 0.886a1.158 1.158 0 0 0 1.6 0.042l1.12-0.97a1.156 1.156 0 0 1 1.576 0.015l1.051 0.941a1.158 1.158 0 0 0 1.6-0.01l1.006-0.921a1.156 1.156 0 0 1 1.577-0.034l1.163 0.988a1.159 1.159 0 0 0 1.606-0.062l0.89-0.862a1.158 1.158 0 0 1 1.58-0.084l2.113 1.706" data-name="路径 245" />
            <path transform="translate(0,-11.8)" d="m369 87.713 0.573-0.449a1.157 1.157 0 0 1 1.583 0.114l0.818 0.822a1.159 1.159 0 0 0 1.609 0.094l1.23-1.012a1.157 1.157 0 0 1 1.578 0.064l0.935 0.886a1.158 1.158 0 0 0 1.605 0.042l1.12-0.97a1.156 1.156 0 0 1 1.576 0.015l1.051 0.941a1.158 1.158 0 0 0 1.6-0.01l1.006-0.921a1.155 1.155 0 0 1 1.576-0.034l1.163 0.988a1.158 1.158 0 0 0 1.606-0.062l0.89-0.862a1.156 1.156 0 0 1 1.58-0.083l2.113 1.706" data-name="路径 246" />
            <line transform="translate(379,66.993)" x2="1" data-name="直线 72" />
        </g>
    </SvgIcon>
}