import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PartsInventoryListByPackageIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63264 0 0 .63264 -179.96 -130.01)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.2" data-name="组 4077">
            <rect transform="translate(303,220)" width="17" height="13" data-name="矩形 14404" />
            <path transform="translate(-9.856,-5.438)" d="m323.62 225v2.641h-4.621v-2.641" data-name="路径 1893" />
            <path transform="translate(0,-1.699)" d="m313.76 237.1v2.641a1.32 1.32 0 0 1-1.32 1.32h-21.125a1.32 1.32 0 0 1-1.32-1.32v-24.421a1.32 1.32 0 0 1 1.32-1.32h2.641" data-name="路径 1894" />
            <path transform="translate(-10.196,-1.699)" d="m320 214h2.641a1.32 1.32 0 0 1 1.32 1.32v3.961" data-name="路径 1895" />
            <path transform="translate(-1.359,-3.059)" d="m312.48 238.46v1.32h-18.484v-21.784h1.32" data-name="路径 1896" />
            <path transform="translate(-10.196,-3.059)" d="m320 218h1.32v2.641" data-name="路径 1897" />
            <path transform="translate(-2.719)" d="m309.22 211.64h-1.98a2.641 2.641 0 1 0-5.281 0h-1.98a1.98 1.98 0 0 0-1.98 1.98v1.98h13.2v-1.98a1.98 1.98 0 0 0-1.979-1.98z" data-name="路径 1898" />
            <line transform="translate(302,211)" y2="1" data-name="直线 891" />
            <line transform="translate(316,231)" x1="2" data-name="直线 892" />
            <line transform="translate(314,231)" x1="2" data-name="直线 893" />
        </g>
    </SvgIcon>
}