import {Divider, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import {ArrowBack} from "@material-ui/icons"
import {FormattedMessage} from "react-intl"
import {useDispatch} from "react-redux"
import {loginActions} from "./loginSlice"
import {VerificationMfaRecoveryInput} from "./VerificationMfaRecoveryInput"
import {VerifyButton} from "./VerifyButton"

export function VerifyMfaRecoveryForm() {
  const dispatch = useDispatch()
  const styles = useStyles()

  return <form className={styles.form} onSubmit={(e) => {
    dispatch(loginActions.recoveryLogin())
    e.preventDefault()
  }}>
    <Grid container alignItems="center">
      <Grid item style={{marginLeft: -16, marginRight: 16}}>
        <IconButton onClick={() => dispatch(loginActions.setStep('sign-in'))}>
          <ArrowBack color="primary" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="h5" style={{textTransform: 'uppercase', lineHeight: '18px'}}>
          <FormattedMessage id="login.verifyMfaRecovery" />
        </Typography>
      </Grid>
    </Grid>
    <Divider className={styles.divider} />
    <Typography variant="body2" color="textSecondary" className={styles.label}>
      <FormattedMessage id="login.verificationMfaRecovery" />
    </Typography>
    <Grid container alignItems="center" style={{marginTop: 2, marginBottom: 16}}>
      <Grid item xs={8}>
        <VerificationMfaRecoveryInput />
      </Grid>
    </Grid>
    <div className={styles.message}>
      <Grid item xs={12}>
        <FormattedMessage id="login.verifyMfaRecoveryTips" />
      </Grid>
    </div>
    <VerifyButton />
  </form >
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    height: 500,
    background: '#F2F1F7',
    borderRadius: 16,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`
  },
  divider: {
    margin: '16px 0 28px',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
    backgroundSize: '6px 1px',
  },
  label: {
    marginTop: -4,
    marginBottom: 4,
  },
  input: {
    height: 40,
    padding: theme.spacing(2),
    marginTop: 2,
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
  },
  message: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
    height: 20,
  },
  link: {
    textDecorationColor: theme.palette.secondary.main,
  },
  button: {
    height: theme.spacing(4),
    fontWeight: 'normal',
    fontSize: '0.75rem',
    borderWidth: 1.5,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderWidth: 1.5,
      borderColor: theme.palette.primary.main,
    },
  },
  or: {
    margin: `${theme.spacing(4)}px 0`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
