import { Avatar, makeStyles, Typography } from '@material-ui/core'
import { ExpandMore } from "@material-ui/icons"
import { forwardRef } from 'react'
import { NeumorphismButton } from '../../../components/NeumorphismButton/NeumorphismButton'
import { useApplicationSelector } from '../../Application/applicationSlice'

export interface UserButtonProps {
    onClick: () => void,
}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>((props, ref) => {
    const { onClick } = props

    const loginId = useApplicationSelector(state => state.auth.user?.account.loginId)
    const userName = useApplicationSelector(state => state.auth.user?.name)

    const classes = useStyles()
    return <NeumorphismButton
        ref={ref}
        onClick={onClick}
        className={classes.root}
        id='Base_Workbench_UserProfile_Button'
    >
        <div className={classes.flex}>
            <Avatar variant='rounded' className={classes.avatar}>
                {loginId?.charAt(0)}
            </Avatar>
            <div className={classes.name}>
                <Typography color="textSecondary" className={classes.primary} id="base_user_login_id_label">{loginId}</Typography>
                <Typography color="textPrimary" className={classes.secondary} id="base_user_name_label">{userName}</Typography>
            </div>
            <ExpandMore id="base_user_expand_more" className={classes.expand} />
        </div>
    </NeumorphismButton>
})

const useStyles = makeStyles(theme => ({
    root: {
        width: 160,
        flex: '0 0 auto',
        padding: 3,
    },
    flex: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    avatar: {
        width: 26,
        height: 26,
        borderRadius: 3,
        marginRight: theme.spacing(1),
        backgroundColor: '#003362',
        color: '#eeeeee',
    },
    name: {
        flex: '1 1 auto',
        textAlign: 'left',
        overflow: 'hidden'
    },
    primary: {
        fontSize: 12,
        transform: 'scale(0.6)',
        width: '160%',
        transformOrigin: 'left',
        lineHeight: 1,
        margin: `-2px 0`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    secondary: {
        fontSize: 14,
        transform: 'scale(0.8)',
        width: '125%',
        transformOrigin: 'left',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    expand: {
        width: 16,
        marginRight: 8,
    }
}))