import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function CargoStatusSettingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.17848 0 0 .17848 -1.2289 -1.4805)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polyline points="77.75 61.11 76.47 62.86 73.94 59.41 70.95 62.86 68.88 59.41 66.21 62.86 63.81 59.41 61.28 62.86 58.75 58.96 58.75 50.2 75.04 50.2" />
            <line x1="97.53" x2="81.53" y1="91.96" y2="91.96" />
            <line x1="97.59" x2="81.58" y1="96.72" y2="96.72" />
            <polyline points="81.24 49.74 103.04 49.74 103.04 61.53" />
            <polyline points="103.04 68.81 103.04 105.43 72.6 105.43" />
            <polyline points="35.98 75.68 35.98 49.74 75.06 49.74" />
            <line x1="67.7" x2="54.97" y1="105.43" y2="105.43" />
            <line x1="35.98" x2="35.98" y1="102.04" y2="86.42" />
            <path d="m75.06 49.74c-0.01 0.16-0.02 0.31-0.02 0.47" />
            <path d="m77.75 61.11c-1.74-2.93-2.76-6.34-2.76-9.99 0-0.31 0.03-0.61 0.05-0.92" />
            <path d="m104.95 67.77c-0.61 0.38-1.25 0.72-1.91 1.04" />
            <path d="m75.06 49.74c0.71-10.19 9.18-18.24 19.55-18.24s19.62 8.78 19.62 19.62c0 4.11-1.27 7.92-3.43 11.08" />
            <path d="m77.75 61.11c3.42 5.76 9.68 9.63 16.86 9.63 3.02 0 5.87-0.7 8.43-1.93" />
            <path d="m110.8 62.19c-1.54 2.24-3.54 4.14-5.85 5.57" />
            <path d="m106.32 57.71c1.1-1.95 1.74-4.2 1.74-6.6 0-7.42-6.02-13.44-13.44-13.44-6.96 0-12.68 5.29-13.37 12.06" />
            <path d="m81.24 49.74c-0.05 0.45-0.07 0.92-0.07 1.38 0 7.42 6.02 13.44 13.44 13.44 2.08 0 4.04-0.49 5.8-1.33" />
            <path d="m100.41 63.23c0.95-0.46 1.82-1.05 2.63-1.71" />
            <path d="m106.32 57.71c-0.84 1.48-1.97 2.75-3.28 3.81" />
            <path d="m110.8 62.19 18.59 18.59c0.85 0.85 0.85 2.22 0 3.07l-2.64 2.64c-0.85 0.85-2.22 0.85-3.07 0l-18.73-18.73" />
            <line x1="113.9" x2="109.31" y1="65.92" y2="70.5" />
            <path d="m31.95 99.15c-1.03-1.37-1.64-3.07-1.64-4.92 0-3.65 2.38-6.74 5.67-7.82" />
            <path d="m45.12 99.15c1.03-1.37 1.64-3.07 1.64-4.92 0-4.54-3.68-8.23-8.23-8.23-0.89 0-1.75 0.15-2.56 0.41" />
            <path d="m45.12 99.15c-1.5 2.01-3.89 3.31-6.59 3.31-0.9 0-1.75-0.16-2.56-0.42" />
            <path d="m35.98 102.04c-1.62-0.53-3.02-1.55-4.03-2.89" />
            <path d="m35.98 75.68c-0.92 0.06-1.65 0.81-1.65 1.74v3.25c-0.77 0.23-1.51 0.54-2.21 0.89l-2.46-2.46c-0.69-0.69-1.8-0.69-2.48 0l-3.3 3.3c-0.69 0.69-0.69 1.8 0 2.48l2.34 2.34c-0.48 0.85-0.87 1.76-1.16 2.71h-3.34c-0.97 0-1.76 0.79-1.76 1.76v4.67c0 0.97 0.79 1.76 1.76 1.76h3.34c0.11 0.35 0.24 0.7 0.37 1.03" />
            <path d="m55.08 89.94h-3.31c-0.28-0.91-0.64-1.78-1.09-2.6l2.69-2.69c0.69-0.69 0.69-1.8 0-2.48l-3.3-3.3c-0.69-0.69-1.8-0.69-2.48 0l-2.75 2.75c-0.74-0.38-1.52-0.71-2.33-0.95v-3.25c0-0.97-0.79-1.76-1.76-1.76h-4.67s-0.07 0-0.11 0.01" />
            <path d="m25.44 99.15c0.18 0.44 0.37 0.87 0.58 1.29l-2.2 2.2c-0.69 0.69-0.69 1.8 0 2.48l3.3 3.3c0.69 0.69 1.8 0.69 2.48 0l2.14-2.14c0.82 0.45 1.69 0.81 2.6 1.09v3.66c0 0.97 0.79 1.76 1.76 1.76h4.67c0.97 0 1.76-0.79 1.76-1.76v-3.66c0.92-0.28 1.79-0.66 2.62-1.11" />
            <path d="m67.7 105.43c-0.68 2.13-2.41 3.88-4.74 4.46-3.45 0.86-6.93-1.12-7.99-4.46" />
            <path d="m67.7 105.43c0.36-1.14 0.43-2.4 0.12-3.64-0.9-3.58-4.53-5.75-8.11-4.85s-5.75 4.53-4.85 8.11c0.03 0.13 0.08 0.26 0.12 0.39" />
            <path d="m55.08 89.94c0.18-0.35 0.49-0.63 0.91-0.74l3.68-0.92c0.76-0.19 1.54 0.27 1.73 1.04l0.66 2.63c0.81 0.04 1.6 0.17 2.36 0.38l1.38-2.31c0.4-0.68 1.28-0.9 1.96-0.49l3.25 1.95c0.68 0.4 0.9 1.28 0.49 1.96l-1.45 2.43c0.42 0.49 0.8 1.01 1.14 1.57l2.56-0.64c0.76-0.19 1.54 0.27 1.73 1.04l0.92 3.68c0.19 0.76-0.27 1.54-1.04 1.73l-2.56 0.64c-0.03 0.53-0.1 1.06-0.2 1.57" />
            <path d="m72.6 105.43c-0.03 0.15-0.05 0.3-0.09 0.45l2.71 1.62c0.68 0.4 0.9 1.28 0.49 1.96l-1.95 3.25c-0.4 0.68-1.28 0.9-1.96 0.49l-2.65-1.59c-0.56 0.51-1.17 0.98-1.83 1.37l0.71 2.82c0.19 0.76-0.27 1.54-1.04 1.73l-3.68 0.92c-0.76 0.19-1.54-0.27-1.73-1.04l-0.71-2.82c-0.65-0.03-1.29-0.12-1.92-0.27l-1.5 2.51c-0.4 0.68-1.28 0.9-1.96 0.49l-3.25-1.95c-0.68-0.4-0.9-1.28-0.49-1.96l1.44-2.4c-0.54-0.58-1.03-1.21-1.45-1.9l-2.89 0.72c-0.76 0.19-1.54-0.27-1.73-1.04l-0.22-0.88" />
            <path d="m55.08 89.94c-0.15 0.3-0.22 0.64-0.13 0.99l0.66 2.63c-0.59 0.36-1.14 0.76-1.65 1.21l-2.17-1.3c-0.68-0.4-1.55-0.18-1.96 0.49l-1.95 3.25c-0.4 0.68-0.18 1.55 0.49 1.96l2.11 1.26c-0.19 0.73-0.31 1.49-0.35 2.26l-2.89 0.72c-0.76 0.19-1.23 0.97-1.04 1.73l0.07 0.28" />
            <line x1="46.3" x2="46.93" y1="105.43" y2="107.94" />
        </g>
    </SvgIcon>
}