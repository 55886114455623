import { useCallback, useEffect, useMemo, useState } from "react"
import { CbdsType } from "../../enums/CbdsType"
import { useApplicationSelector } from "../../layouts/Application/applicationSlice"


export const DataLayer = () => {

    const company = useGetCompany()
    const loginUser = useApplicationSelector(state => state.auth.user)
    const language = useApplicationSelector(state => state.i18n.language)
    const userSetting = useApplicationSelector(state => state.setting?.userSetting)
    const [logoned, setLogoned] = useState<boolean>(false)

    const loginLogout = useCallback((
        company: any,
        loginUser: any,
        language: string,
        logoned: boolean,
        userSetting: any
    ) => {
        if (loginUser && company && !logoned && userSetting) {
            // GET USER ROLE
            const userRole = company.cbdsType === CbdsType.Bu ? "BU" : (company.cbdsType === CbdsType.Cust ? "Customer" : (company.cbdsType === CbdsType.Dc ? "DC" : "Supplier"));
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                'userId': loginUser.account.loginId,
                'name': loginUser.name,
                'lang': language,
                'userRole': userRole,
                'pageName': "Home Page",
                'email': loginUser.email,
                'contactNumber': loginUser.contactCode,
                'companyName': company.cbdsCode,
                'rulebase': userSetting?.daSetting.ruleBasedRecommendation ? 'yes' : 'no',
                'recommendation': userSetting?.daSetting.aiPageRecommendation ? 'yes' : 'no',
                'event': 'login'
            })
            setLogoned(true)
        } else if (!loginUser && !company && logoned) {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                'event': 'logout',
            })
            setLogoned(false)
        }
    }, [])

    useEffect(() => {
        loginLogout(company, loginUser, language, logoned, userSetting)
    }, [company, language, loginLogout, loginUser, logoned, userSetting])

    return null
}

const useGetCompany = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const loginUserCompanies = useApplicationSelector(state => state.auth.userCompanies)

    const company = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            const selectedCompany = loginUserCompanies?.find(f => f.cbdsUid === companyUid)
            if (selectedCompany) {
                return selectedCompany
            }
        }
        return null
    }, [companyUid, loginUserCompanies])

    return company
}
