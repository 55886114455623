import { Button, Collapse, IconButton, makeStyles, Slide, Tooltip, Typography, useTheme } from '@material-ui/core'
import { CheckCircleOutline, Close, ErrorOutline, ExpandLess, ExpandMore, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { applicationActions } from '../../layouts/Application/applicationSlice'
import { useFunctionStore } from "../../Root"
import { IntlString } from '../../utils/IntlString'
import { Notification, NotificationAction } from '../../utils/Notification'
import { Scrollbar } from "../Scrollbar/Scrollbar"

export interface NoticeProps {
    autoHideDuration: number,
    notification: Notification & { id: string | number, dismissed?: boolean },
}

export function Notice(props: NoticeProps) {
    const { autoHideDuration, notification } = props
    const { id, type, title, dismissed } = notification
    const messages = !notification.messages ? [] : notification.messages instanceof Array ? notification.messages : [notification.messages]
    const actions = useMemo(() => !notification.actions ? [] : notification.actions instanceof Array ? notification.actions : [notification.actions], [notification.actions])

    const dispatch = useDispatch()

    // auto hide
    useEffect(() => {
        const timer = setTimeout(() => {
            !dismissed && dispatch(applicationActions.closeNotification(id))
        }, autoHideDuration)
        return () => clearTimeout(timer)
    }, [autoHideDuration, dismissed, dispatch, id])

    // auto remove
    const functionStore = useFunctionStore()
    useEffect(() => {
        if (dismissed) {
            actions.forEach(action => action.functionId && functionStore.unregister(action.functionId))
            dispatch(applicationActions.removeNotification(id))
        }
    }, [actions, dispatch, dismissed, functionStore, id])

    const [expanded, setExpanded] = useState(true)
    const styles = useStyles()
    return <Slide in={!dismissed} direction="left">
        <Alert severity={type} icon={false} className={styles.root}>
            <div className={styles.header}>
                <Icon severity={type} />
                <Title title={title} />
                {messages.length > 0 && <ExpandButton expanded={expanded} setExpanded={setExpanded} />}
                <CloseButton id={id} />
            </div>
            {messages.length > 0 && <Content expanded={expanded} messages={messages} />}
            {actions.length > 0 && <Footer id={id} actions={actions} />}
        </Alert>
    </Slide>
}

function Icon({ severity }: { severity: string }) {
    const theme = useTheme()
    if (severity === 'info') {
        return <InfoOutlined style={{ color: theme.palette.info.main }} />
    } else if (severity === 'success') {
        return <CheckCircleOutline style={{ color: theme.palette.success.main }} />
    } else if (severity === 'warning') {
        return <ReportProblemOutlined style={{ color: theme.palette.warning.main }} />
    } else if (severity === 'error') {
        return <ErrorOutline style={{ color: theme.palette.error.main }} />
    } else {
        return null
    }
}

function Title({ title }: { title: string | IntlString }) {
    const styles = useStyles()
    const titleElement = typeof title === 'string' ? title : <FormattedMessage id={title.code} values={{ ...title.args }} />
    return <Tooltip title={titleElement}>
        <Typography variant="subtitle1" noWrap className={styles.title}>
            {titleElement}
        </Typography>
    </Tooltip>
}

function ExpandButton({ expanded, setExpanded }: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) {
    const handleClick = useCallback(() => setExpanded(v => !v), [setExpanded])
    return <IconButton size="small" onClick={handleClick}>
        <Tooltip title={<FormattedMessage id={expanded ? 'notice.shrink' : 'notice.expand'} />}>
            {expanded ? <ExpandMore /> : <ExpandLess />}
        </Tooltip>
    </IconButton>
}

function CloseButton({ id }: { id: string | number }) {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => dispatch(applicationActions.closeNotification(id)), [dispatch, id])
    return <IconButton size="small" onClick={handleClick}>
        <Tooltip title={<FormattedMessage id="notice.close" />}>
            <Close />
        </Tooltip>
    </IconButton>
}

function Content({ expanded, messages }: { expanded: boolean, messages: (string | IntlString)[] }) {
    const styles = useStyles()
    return <Collapse in={expanded}>
        <Scrollbar className={styles.content}>
            {messages.map((message, index) =>
                <Typography key={index} variant="body1" color="textPrimary" className={styles.message}>
                    {typeof message === 'string' ? message : <FormattedMessage id={message.code} values={{ ...message.args }} defaultMessage={message.defaultMessage} />}
                </Typography>
            )}
        </Scrollbar>
    </Collapse>
}

function Footer({ id, actions }: { id: string | number, actions: NotificationAction[] }) {
    const styles = useStyles()
    return <div className={styles.footer}>
        {actions.map(action => (
            <Action key={action.functionId} id={id} action={action} />
        ))}
    </div>
}

function Action({ id, action }: { id: string | number, action: NotificationAction }) {
    const { functionId, label, closeOnAction } = action
    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const handleClick = useCallback(() => {
        if (functionId) {
            functionStore.dispatch(functionId)
        }
        if (closeOnAction !== false) {
            dispatch(applicationActions.closeNotification(id))
        }
    }, [functionStore, functionId, closeOnAction, dispatch, id])
    const styles = useStyles()
    return <Button onClick={handleClick} className={styles.button}>
        {typeof label === 'string' ? label : <FormattedMessage id={label.code} values={{ ...label.args }} />}
    </Button>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        padding: `0 ${theme.spacing(2)}px`,
        margin: `${theme.spacing(2)}px 0`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        transition: `all ${theme.transitions.duration.standard} ${theme.transitions.easing.easeInOut}`,
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1 1 auto',
        padding: `0 ${theme.spacing(0.5)}px`,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        padding: theme.spacing(0.5),
        borderWidth: 4,
        borderColor: 'rgba(20,20,20,0.3)',
        borderLeftStyle: 'solid',
        lineHeight: '1rem',
        background: 'rgba(255,255,255,0.5)',
        maxHeight: 100,
        overflow: 'auto',
    },
    message: {
        margin: `${theme.spacing(0.5)}px 0`,
    },
    footer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    button: {
        minWidth: 80,
    }
}))

