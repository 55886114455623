import { Portal } from '@material-ui/core'
import { useCallback, useRef, useState } from 'react'
import { UserButton } from '../UserButton/UserButton'
import { UserMenu } from '../UserMenu/UserMenu'

export function User() {
    const ref = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)

    const handleClick = useCallback(() => setOpen(true), [])
    const handleClose = useCallback(() => setOpen(false), [])

    return <>
        <UserButton ref={ref} onClick={handleClick} />
        <Portal>
            <UserMenu
                anchorEl={ref.current}
                open={open}
                onClose={handleClose}
            />
        </Portal>
    </>
}
