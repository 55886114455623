import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function PartsInventoryListByPartsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.63264 0 0 .63264 -179.4 -129.81)" fill="none" stroke="currentColor" data-name="组 4078">
            <g strokeMiterlimit="10" strokeWidth="1.2">
                <path transform="translate(0,-1.699)" d="m313.76 237.1v2.641a1.32 1.32 0 0 1-1.32 1.32h-21.125a1.32 1.32 0 0 1-1.32-1.32v-24.421a1.32 1.32 0 0 1 1.32-1.32h2.641" data-name="路径 1894" />
                <path transform="translate(-10.196,-1.699)" d="m320 214h2.641a1.32 1.32 0 0 1 1.32 1.32v3.961" data-name="路径 1895" />
                <path transform="translate(-1.359,-3.059)" d="m312.48 238.46v1.32h-18.484v-21.784h1.32" data-name="路径 1896" />
                <path transform="translate(-10.196,-3.059)" d="m320 218h1.32v2.641" data-name="路径 1897" />
                <path transform="translate(-2.719)" d="m309.22 211.64h-1.98a2.641 2.641 0 1 0-5.281 0h-1.98a1.98 1.98 0 0 0-1.98 1.98v1.98h13.2v-1.98a1.98 1.98 0 0 0-1.979-1.98z" data-name="路径 1898" />
                <line transform="translate(302,211)" y2="1" data-name="直线 891" />
            </g>
            <g transform="translate(150.41 72.38)" strokeLinejoin="round" data-name="组 75">
                <path d="m169.21 154.89v-1.568a0.353 0.353 0 0 0-0.353-0.353h-0.9a0.356 0.356 0 0 1-0.343-0.273 5.64 5.64 0 0 0-0.616-1.486 0.354 0.354 0 0 1 0.048-0.435l0.576-0.576a0.383 0.383 0 0 0 0.065-0.564l-1.109-1.109a0.353 0.353 0 0 0-0.5 0l-0.64 0.639a0.356 0.356 0 0 1-0.436 0.049 5.649 5.649 0 0 0-1.486-0.617 0.354 0.354 0 0 1-0.273-0.341v-0.813c0-0.287-0.158-0.444-0.353-0.444h-1.568a0.353 0.353 0 0 0-0.353 0.353v0.9a0.356 0.356 0 0 1-0.273 0.343 5.645 5.645 0 0 0-1.486 0.617 0.354 0.354 0 0 1-0.435-0.048l-0.576-0.576a0.382 0.382 0 0 0-0.564-0.065l-1.109 1.109a0.353 0.353 0 0 0 0 0.5l0.64 0.64a0.356 0.356 0 0 1 0.049 0.436 5.649 5.649 0 0 0-0.617 1.486 0.354 0.354 0 0 1-0.341 0.273h-0.813c-0.287 0-0.444 0.158-0.444 0.353v1.568a0.353 0.353 0 0 0 0.353 0.353h0.9a0.356 0.356 0 0 1 0.343 0.273 5.651 5.651 0 0 0 0.617 1.486 0.354 0.354 0 0 1-0.048 0.435l-0.576 0.576a0.382 0.382 0 0 0-0.065 0.564l1.109 1.109a0.353 0.353 0 0 0 0.5 0l0.64-0.64a0.356 0.356 0 0 1 0.436-0.049 5.645 5.645 0 0 0 1.486 0.617 0.354 0.354 0 0 1 0.273 0.341v0.879c0 0.252 0.127 0.377 0.284 0.377h1.637a0.353 0.353 0 0 0 0.353-0.353v-0.9a0.356 0.356 0 0 1 0.273-0.343 5.645 5.645 0 0 0 1.483-0.613 0.354 0.354 0 0 1 0.435 0.048l0.576 0.576a0.383 0.383 0 0 0 0.564 0.065l1.109-1.109a0.353 0.353 0 0 0 0-0.5l-0.64-0.64a0.356 0.356 0 0 1-0.044-0.44 5.64 5.64 0 0 0 0.618-1.486 0.354 0.354 0 0 1 0.341-0.273h0.813c0.282 2e-3 0.44-0.156 0.44-0.351z" data-name="路径 14" />
                <circle transform="translate(158.92 150.99)" cx="3.132" cy="3.132" r="3.132" strokeLinecap="round" data-name="椭圆 2" />
            </g>
        </g>
    </SvgIcon>
}