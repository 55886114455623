import { AxiosInstance, AxiosResponse } from 'axios'

enum ResourceType {
    QUERY = 'QUERY',
    ACTION = 'ACTION',
}

interface ResourceAPIResponse {
    id: string,
    code: string,
    type: ResourceType
    category: string,
    module: string,
    action: string,
}

const resourceApi = {
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/resources/batch-get', ids) as Promise<AxiosResponse<ResourceAPIResponse[]>>
    },
}

export default resourceApi