import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function RequestListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.61911 0 0 .61911 -177.57 -28.584)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 166">
            <path transform="translate(0,-5.862)" d="m310.53 77.073a8.478 8.478 0 0 1-9.369 0c-0.782-0.463-10.16-7.073-10.16-7.073v14.845a2.121 2.121 0 0 0 2.121 2.121h25.449a2.121 2.121 0 0 0 2.121-2.121v-14.845s-9.379 6.614-10.162 7.073z" strokeLinejoin="round" data-name="路径 285" />
            <g strokeMiterlimit="10">
                <line transform="translate(311,71.104)" x2="7" y2="7" data-name="直线 89" />
                <line transform="translate(294,71.104)" x1="7" y2="7" data-name="直线 90" />
                <path transform="translate(-10.259,-3.381)" d="m330.95 67.519v-0.707a2.4 2.4 0 0 0-1.027-1.91l-3.922-3.368" data-name="路径 286" />
                <path transform="translate(0,-3.38)" d="m291 67.518v-0.707a2.4 2.4 0 0 1 1.027-1.91l3.921-3.369" data-name="路径 287" />
                <path transform="translate(-10.258)" d="m328.12 53.535h3.535v-0.707a2.787 2.787 0 0 0-2.828-2.828h-2.828" data-name="路径 288" />
                <path transform="translate(-2.052)" d="m298 67.673v-14.138a3.535 3.535 0 0 1 3.535-3.535h19.793a3.535 3.535 0 0 0-3.535 3.535v14.138" data-name="路径 289" />
                <line transform="translate(300,56.104)" x2="12" data-name="直线 91" />
                <line transform="translate(300,60.104)" x2="12" data-name="直线 92" />
                <line transform="translate(300.19,63.431)" x2="6.912" data-name="直线 93" />
            </g>
        </g>
    </SvgIcon>
}