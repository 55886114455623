import { AxiosInstance } from 'axios'

interface FavouritePageAPIResponse {
    pageId: number,
    pageCode: string,
    userId: number,
    cbdsUid: string,
    resourceId: number,
    resourceCode: string,
    createdDate: Date,
    updatedDate: Date,
}

const favouritePageApi = {
    list: function (axiosInstance: AxiosInstance) {
        return axiosInstance.get<FavouritePageAPIResponse[]>('/common-system-api/user-pages/favorites')
    },
}

export default favouritePageApi