import { ButtonBase, makeStyles } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'
import * as React from 'react'

export interface NavScrollButtonProps {
    direction: 'left' | 'right',
    disabled?: boolean,
    onClick: () => void,
}

export function NavScrollButton(props: NavScrollButtonProps) {
    const { direction, disabled, onClick } = props
    const classes = useStyles()
    return <ButtonBase
        component="div"
        onClick={onClick}
        className={clsx(classes.root, disabled && classes.disabled)}
        tabIndex={-1}
    >
        {direction === 'left' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </ButtonBase>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 40,
        flexShrink: 0,
        opacity: 0.8,
    },
    disabled: {
        opacity: 0,
        pointerEvents: 'none',
        cursor: 'default',
    }
}))