import { makeStyles, Typography } from "@material-ui/core"
import { condition } from "@rithe/utils"
import { FormattedDate } from "react-intl"
import { Link } from "react-router-dom"

interface AnnouncementOutlineProps {
    id: string,
    type: string,
    title: string,
    summary: string,
    publishAt: number,
}

export const AnnouncementOutline = (props: AnnouncementOutlineProps) => {
    const { id, type, title, summary, publishAt } = props

    const classes = useStyles()
    return <div className={classes.root}>
        <div className={classes.tagLine}>
            {condition(type)
                .case('NEW').then(() => <Typography variant="body1" className={classes.tagNew}>{type}</Typography>)
                .case('IMPROVEMENT').then(() => <Typography variant="body1" className={classes.tagImprovement}>{type}</Typography>)
                .case('MAINTENANCE').then(() => <Typography variant="body1" className={classes.tagMaintenance}>{type}</Typography>)
                .default(() => <Typography variant="body1">{type}</Typography>)
            }
            <Typography variant="body2">
                <FormattedDate value={publishAt} dateStyle="medium" />
            </Typography>
        </div>
        <div className={classes.titleLine}>
            <Typography variant="h4" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {title}
            </Typography>
        </div>
        <div className={classes.summaryLine}>
            <Typography variant="body2">
                {summary}
            </Typography>
        </div>
        <div className={classes.linkLine}>
            <Link to={`/whats-new#a-${id}`}>LEARN MORE →</Link>
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    tagLine: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(1),
    },
    tagNew: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#16cad3',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    tagImprovement: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#2d9cef',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    tagMaintenance: {
        width: 160,
        height: 24,
        borderRadius: 4,
        backgroundColor: '#df5988',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    titleLine: {
        paddingBottom: theme.spacing(1),
    },
    summaryLine: {
        paddingBottom: theme.spacing(1),
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    linkLine: {

    }
}))