import { useCallback } from 'react'
import { FormattedMessage } from "react-intl"
import { Nav } from "../Nav"
import { PoMonitoringListIcon } from '../OrderNav/assets/PoMonitoringListIcon'
import { BusinessUnitListScreenIcon } from "./assets/BusinessUnitListScreenIcon"
import { CargoStatusSettingIcon } from "./assets/CargoStatusSettingIcon"
import { CompanyListScreenIcon } from "./assets/CompanyListScreenIcon"
import { ContractListIcon } from "./assets/ContractListIcon"
import { ContractRouteListIcon } from "./assets/ContractRouteListIcon"
import { CustomerListIcon } from "./assets/CustomerListIcon"
import { DistributionCentreListIcon } from "./assets/DistributionCentreListIcon"
import { FobSettingIcon } from "./assets/FobSettingIcon"
import { GlobalPartsListIcon } from "./assets/GlobalPartsListIcon"
import { MasterDownloadUploadIcon } from "./assets/MasterDownloadUploadIcon"
import { MasterIcon } from "./assets/MasterIcon"
import { PaymentTermsListIcon } from "./assets/PaymentTermsListIcon"
import { PortListIcon } from "./assets/PortListIcon"
import { RequestAddPartsIcon } from "./assets/RequestAddPartsIcon"
import { RequestListIcon } from "./assets/RequestListIcon"
import { RequestModifyPackageIcon } from "./assets/RequestModifyPackageIcon"
import { RequestReceiveListIcon } from "./assets/RequestReceiveListIcon"
import { RequestRemovePartsIcon } from "./assets/RequestRemovePartsIcon"
import { ShippingRouteListIcon } from "./assets/ShippingRouteListIcon"
import { SupplierListIcon } from "./assets/SupplierListIcon"
import { UnitPartsListIcon } from "./assets/UnitPartsListIcon"

export interface MasterNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function MasterNav(props: MasterNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<MasterIcon />}
        iconId={'Master_Nav'}
        title={<FormattedMessage id="appbar.master" />}
        menuPlacement="bottom"
        columns={[{
            name: <FormattedMessage id="nav.company" />,
            items: [{
                access: "MARS.MLS010.VIEW",
                icon: <CompanyListScreenIcon />,
                title: <FormattedMessage id="MLS010" />,
                path: '/company',
            }, {
                access: "MARS.MLS020.VIEW",
                icon: <BusinessUnitListScreenIcon />,
                title: <FormattedMessage id="MLS020" />,
                path: '/bu',
            }, {
                access: "MARS.MLS030.VIEW",
                icon: <CustomerListIcon />,
                title: <FormattedMessage id="MLS030" />,
                path: '/customer',
            }, {
                access: "MARS.MLS040.VIEW",
                icon: <SupplierListIcon />,
                title: <FormattedMessage id="MLS040" />,
                path: '/supplier',
            }, {
                access: "MARS.MLS050.VIEW",
                icon: <DistributionCentreListIcon />,
                title: <FormattedMessage id="MLS050" />,
                path: '/dc',
            },]
        }, {
            name: <FormattedMessage id="partsInfo" />,
            items: [{
                access: "MARS.MLS070.VIEW",
                icon: <GlobalPartsListIcon />,
                title: <FormattedMessage id="MLS070" />,
                path: '/globalparts',
            }, {
                access: "MARS.MLS080.VIEW",
                icon: <UnitPartsListIcon />,
                title: <FormattedMessage id="MLS080" />,
                path: '/unitparts',
            }, {
                access: "MARS.MLS140.VIEW",
                icon: <ContractListIcon />,
                title: <FormattedMessage id="MLS140" />,
                path: '/orderGroup',
            },
            {
                access: "MARS.MLS090.VIEW",
                icon: <ContractListIcon />,
                title: <FormattedMessage id="MLS090" />,
                path: '/contract',
            }, {
                access: "MARS.MLS100.VIEW",
                icon: <ContractRouteListIcon />,
                title: <FormattedMessage id="MLS100" />,
                path: '/contractroute',
            }, {
                access: "MARS.MLS170.VIEW",
                icon: <FobSettingIcon />,
                title: <FormattedMessage id="MLS170" />,
                path: '/fobSetting',
            }, {
                access: "MARS.MLS110.VIEW",
                icon: <PaymentTermsListIcon />,
                title: <FormattedMessage id="MLS110" />,
                path: '/payment',
            },
            {
                access: "MASTER.MLS180.VIEW",
                icon: <PaymentTermsListIcon />,
                title: <FormattedMessage id="MLS180" />,
                path: '/creditrules',
            }, {
                access: "MASTER.MLS190.VIEW",
                icon: <PaymentTermsListIcon />,
                title: <FormattedMessage id="MLS190" />,
                path: '/creditsetting',
            }, {
                access: "MARS.MLS120.VIEW",
                icon: <ShippingRouteListIcon />,
                title: <FormattedMessage id="MLS120" />,
                path: '/shippingroute',
            }, {
                access: "MARS.MLS250.VIEW",
                icon: <CargoStatusSettingIcon />,
                title: <FormattedMessage id="MLS250" />,
                path: '/cargostatus',
            }
            ]
        }, {
            name: <FormattedMessage id="request" />,
            items: [{
                access: "MARS.MOS020.VIEW",
                icon: <RequestListIcon />,
                title: <FormattedMessage id="MOS020" />,
                path: '/requestTo',
            }, {
                access: "MARS.MOS021.CREATEADD",
                icon: <RequestAddPartsIcon />,
                title: <FormattedMessage id="MOS021AddNewPart" />,
                path: '/requestTo/addNewPart',
            }, {
                access: "MARS.MOS021.CREATEMODIFY",
                icon: <RequestModifyPackageIcon />,
                title: <FormattedMessage id="MOS021Modify" />,
                path: '/requestTo/editPacking',
            }, {
                access: "MARS.MOS021.CREATEREMOVE",
                icon: <RequestRemovePartsIcon />,
                title: <FormattedMessage id="MOS021Remove" />,
                path: '/requestTo/remove',
            }, {
                access: "MARS.MOS030.VIEW",
                icon: <RequestReceiveListIcon />,
                title: <FormattedMessage id="MOS030" />,
                path: '/receivedRequest',
            },

            ]
        }, {
            name: <FormattedMessage id="global" />,
            items: [{
                access: "MARS.MGS010.VIEW",
                icon: <MasterDownloadUploadIcon />,
                title: <FormattedMessage id="MGS010" />,
                path: '/commondownloadupload',
            }, {
                access: "MARS.MLS060.VIEW",
                icon: <PortListIcon />,
                title: <FormattedMessage id="MLS060" />,
                path: '/port',
            }, {
                power: true,
                icon: <PoMonitoringListIcon />,
                title: <FormattedMessage id="MLS260" />,
                path: '/announcements',
            },

            ]
        }]}
    />
}