export default function animate<E extends HTMLElement>(
    element: E,
    property: keyof E,
    target: number,
    options?: { duration: number, ease: (time: number) => number }) {
    const {
        duration = 300,
        ease = easeInOutSin
    } = options || {}

    const origin = element[property] as unknown as number
    let start: number | null = null
    let cancelled = false

    function step(timestamp: number) {
        if (cancelled) return
        if (start === null) {
            start = timestamp
        }
        const time = Math.min(1, (timestamp - start) / duration)
        element[property] = origin + (target - origin) * ease(time) as unknown as E[keyof E]

        if (time < 1) {
            requestAnimationFrame(step)
        }
    }

    if (origin !== target) {
        requestAnimationFrame(step)
    }

    return () => {
        cancelled = true
    }
}

function easeInOutSin(time: number) {
    return (1 + Math.sin(Math.PI * time - Math.PI / 2)) / 2
}