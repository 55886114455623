import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function RequestRemovePartsIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <switch transform="matrix(.63573 0 0 .63573 1.9364 2.5809)">
            <g>
                <g transform="translate(-399.22 -25.383)">
                    <g transform="translate(399.82 25.984)">
                        <g transform="translate(2.709)">
                            <g transform="translate(0,5.229)">
                                <path d="m3.4 22.5c-0.5 0-0.9-0.2-1.3-0.5l-2.2-2.2c-0.7-0.7-0.7-1.8 0-2.5l17.7-17.7c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.2 0.2-0.2 0.6 0 0.8l2.2 2.2c0.2 0.2 0.6 0.2 0.8 0l17.7-17.6c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-17.6 17.7c-0.3 0.3-0.8 0.5-1.3 0.5z" />
                            </g>
                            <g transform="translate(16.117)">
                                <path d="m7.9 11.6c-0.3 0-0.6-0.1-0.8-0.3l-7.4-7.4c-0.4-0.4-0.4-1.1 0-1.6l2.7-2.7c0.4-0.4 1.1-0.4 1.6 0l7.4 7.4c0.4 0.4 0.4 1.1 0 1.6l-2.7 2.7c-0.3 0.2-0.5 0.3-0.8 0.3zm-7.3-8.4 7.2 7.2 2.5-2.5-7.1-7.3z" />
                            </g>
                            <g transform="translate(17.779,1.662)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                            <g transform="translate(21.507,5.39)">
                                <path d="m0 4c-0.2 0-0.3-0.1-0.4-0.2-0.2-0.2-0.2-0.6 0-0.8l3.4-3.4c0.2-0.2 0.6-0.2 0.8 0s0.2 0.6 0 0.8l-3.4 3.4c-0.1 0.1-0.2 0.2-0.4 0.2z" />
                            </g>
                        </g>
                        <g transform="translate(0 .856)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.1 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                        <g transform="translate(16.586,16.531)">
                            <path d="m3.6-0.6h6.7c0.3 0 0.6 0.2 0.7 0.4l3.3 5.8c0.1 0.3 0.1 0.6 0 0.8l-3.3 5.8c-0.2 0.3-0.4 0.4-0.7 0.4h-6.7c-0.3 0-0.6-0.2-0.7-0.4l-3.3-5.8c-0.1-0.3-0.1-0.6 0-0.8l3.3-5.8c0.1-0.2 0.4-0.4 0.7-0.4zm6.5 1.2h-6.3l-3.1 5.4 3.1 5.4h6.3l3.1-5.4z" />
                            <g transform="translate(3.694,2.799)">
                                <path d="m3.2-0.6c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.9-3.8 3.9-3.8-1.7-3.8-3.8c0-2.2 1.7-3.9 3.8-3.9zm0 6.5c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6z" />
                            </g>
                        </g>
                    </g>
                    <path d="m421.2 39.4c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8 0 0.1-0.2 0.2-0.5 0.2z" />
                    <path d="m419.7 40.9c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m418.2 42.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m416.7 44c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0-0.3 0.2-0.5 0.2z" />
                    <path d="m415.2 45.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m413.7 47c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m412.2 48.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m410.7 50c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                    <path d="m409.2 51.5c-0.1 0-0.2 0-0.3-0.1-1.9-1.1-3.7-2.4-5.2-4-0.2-0.3-0.2-0.6 0.1-0.8s0.6-0.2 0.8 0.1c1.4 1.5 3.1 2.8 4.9 3.8 0.3 0.2 0.4 0.5 0.2 0.8-0.1 0.1-0.3 0.2-0.5 0.2z" />
                </g>
                <circle cx="3.4" cy="24.7" r="3.1" fill="#FFF" stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" />
                <rect x="1.4" y="24.2" width="4.1" height="1" />
            </g>
        </switch>
    </SvgIcon>
}