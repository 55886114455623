import axios, { AxiosInstance } from 'axios'
import { Dispatch } from 'react'
import appConfig from '../../configs/appConfig'
import { applicationActions } from '../../layouts/Application/applicationSlice'

declare module 'axios' {
    interface AxiosRequestConfig {
        pathVars?: Record<string, string>,
        feature?: string,
    }
    interface AxiosInterceptorManager<V> {
        use<T = V>(
            onFulfilled?: (value: V) => T | Promise<T>,
            onRejected?: (error: AxiosError<T>) => AxiosError<T> | Promise<AxiosError<T>>,
            options?: { synchronous?: boolean, runWhen?: (config: AxiosRequestConfig) => boolean }): number
        eject(id: number): void
    }
}

/** Global instance */
let instance: AxiosInstance | null = null

interface AxiosInstanceOptions {
    token: string | null,
    companyUid: string | null,
    language: string,
}

/**
 * As this will used in `useEffect` hook or `useCallback` hook, we should make sure at least
 * one `useAxiosInstance` hook always returns the same instance. Otherwise, the instance change
 * will cause the dependency change, which will cause the `useEffect` hook to re-run and 
 * `useCallback` hook to re-render.
 * 
 * @returns a stable axios instance
 */
export default function shareAxiosInstance(dispatch: Dispatch<any>, options: AxiosInstanceOptions) {
    // create global instance if not exists
    instance || (instance = createAxiosInstance(dispatch))
    // set headers
    instance.defaults.headers.common = Object.assign({},
        options.token !== null && { 'token': options.token },
        options.companyUid !== null && { 'company': options.companyUid },
        { 'preferred-locale': options.language },
        { 'accept-language': options.language })

    return instance
}

function createAxiosInstance(dispatch: Dispatch<any>) {
    // share a single axios instance
    const instance = axios.create({
        baseURL: appConfig.apiBaseURL,
        timeout: appConfig.requestTimeout,
        withCredentials: false,
        transitional: {
            clarifyTimeoutError: true,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    })
    // set url
    instance.interceptors.request.use(config => {
        let url: string | undefined = config.url
        if (config.url && config.pathVars) {
            Object.entries(config.pathVars).forEach(([key, value]) => {
                url = url!.replace(`:${key}`, encodeURIComponent(value))
            })
        }
        let feature: string | undefined = config.feature
        if (config.method && config.url && !config.feature) {
            feature = `${config.method} ${config.url}`
        }
        return {
            ...config,
            url,
            feature,
        }
    })
    // set token if it exists in response headers
    instance.interceptors.response.use(response => {
        // NOTE: there are possibility that the token is a old one when requesting concurrently
        const token = response.headers['token'] || response.headers['Token']
        token && dispatch(applicationActions.setToken(token))
        return response
    }, error => {
        // 401 Unauthorized
        if (error.response && error.response.status === 401) {
            dispatch(applicationActions.logout())
        }
        return Promise.reject(error)
    }, { synchronous: true })
    // parse blob to json
    instance.interceptors.response.use(undefined, error => {
        if (error.response?.config.responseType === 'blob') {
            const blob = error.response.data as unknown as Blob
            if (blob.type === 'application/json') {
                return new Promise((_resolve, reject) => {
                    blob.text().then(text => {
                        error.response!.data = JSON.parse(text)
                        reject(error)
                    })
                })
            } else {
                return Promise.reject(error)
            }
        } else {
            return Promise.reject(error)
        }
    }, { synchronous: true })
    return instance
}