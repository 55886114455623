import { AxiosResponse } from 'axios'
import { IntlString } from "../IntlString"
import { Notification } from '../Notification'

export default function responseToNotification(response: AxiosResponse, options?: Partial<Notification>): Notification {
    let title: string | IntlString = { code: 'notice.unknown' }
    // title: first feature, then url, then unknown
    if (response.config.feature) {
        title = { code: response.config.feature }
    } else if (response.config.url) {
        title = { code: response.config.url }
    }
    return {
        type: 'success',
        title,
        messages: [{ code: 'notice.success' }],
        ...options
    }
}
