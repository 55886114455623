import { AxiosInstance, AxiosResponse } from 'axios'

interface CbdsInfo {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: string,
    cbdsCode: string,
    cbdsName: string,
    defaultFlag: number,
}

const cbdsApi = {
    getCompany: function (axiosInstance: AxiosInstance, cbdsUids: string[]) {
        return axiosInstance.post('/lcbm-system-master-api/api/getCompanyByUids', cbdsUids) as Promise<AxiosResponse<CbdsInfo[]>>
    }
}

export default cbdsApi