import { Divider, makeStyles } from "@material-ui/core"
import { Fragment } from "react"
import { useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { AnnouncementItem } from "./AnnouncementItem"

export const AnnouncementList = () => {
    const announcements = useApplicationSelector(state => state.ui.announcements)

    const styles = useStyles()
    return <div className={styles.root}>
        <div className={styles.body}>
            {[...announcements].reverse().map((announcement, index) => {
                return <Fragment key={announcement.id}>
                    {index > 0 && <Divider />}
                    <AnnouncementItem
                        id={announcement.id}
                        type={announcement.type}
                        title={announcement.title}
                        content={announcement.content}
                        publishAt={announcement.publishAt}
                    />
                </Fragment>
            })}
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
    },
    body: {
        width: 800,
    },
}))