import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function SoMonitoringListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.58691 0 0 .58691 -203.21 -205.94)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4089">
            <line transform="translate(350.59 376.26)" x2="14.899" strokeLinejoin="round" data-name="直线 918" />
            <path transform="translate(0,-1.049)" d="m361.71 382.71v-2.316h-9.892a1.23 1.23 0 0 1-1.228-1.229v-17.676h17.929" strokeLinejoin="round" data-name="路径 1950" />
            <g strokeMiterlimit="10">
                <path transform="translate(-17.487)" d="m386.58 364.38a5.056 5.056 0 1 0 5.054-5.055 5.054 5.054 0 0 0-5.054 5.055z" data-name="路径 1951" />
                <path transform="translate(-13.45,-17.386)" d="m378.27 401.32v-4.4a1.82 1.82 0 0 1 1.82-1.817h15.011a1.82 1.82 0 0 1 1.819 1.817v4.4" data-name="路径 1952" />
                <path transform="translate(-13.993,-11.493)" d="m379.38 389.66v-0.838a5.843 5.843 0 0 1 5.843-5.843h5.818a5.843 5.843 0 0 1 5.843 5.843v0.838" data-name="路径 1953" />
            </g>
            <g strokeLinejoin="round">
                <line transform="translate(357.76 381.66)" x2="7.062" strokeLinecap="round" data-name="直线 919" />
                <path transform="translate(-2.541,-10.123)" d="m358.58 381.54a1.377 1.377 0 1 1-1.378-1.378 1.378 1.378 0 0 1 1.378 1.378z" data-name="路径 1954" />
                <path transform="translate(-5.614,-5.756)" d="m364.9 372.55a1.377 1.377 0 1 1-1.378-1.378 1.377 1.377 0 0 1 1.378 1.378z" data-name="路径 1955" />
                <path transform="translate(-10.198,-8.648)" d="m374.33 378.5a1.377 1.377 0 1 1-1.378-1.378 1.378 1.378 0 0 1 1.378 1.378z" data-name="路径 1956" />
                <path transform="translate(-12.987,-4.394)" d="m380.07 369.74a1.377 1.377 0 1 1-1.378-1.378 1.377 1.377 0 0 1 1.378 1.378z" data-name="路径 1957" />
                <line transform="translate(363.51 366.5)" x2="1.44" y1="2.196" data-name="直线 920" />
                <line transform="translate(359.06 367.54)" x2="2.529" y2="1.579" data-name="直线 921" />
                <line transform="translate(355.43 367.92)" x2="1.692" y1="2.367" data-name="直线 922" />
            </g>
        </g>
    </SvgIcon>
}