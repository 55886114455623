import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function WeeklyStockIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.26596 0 0 .26596 -7.9468 -7.8755)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <rect x="38.4" y="45.76" width="65.88" height="56.16" />
            <line x1="52.36" x2="52.36" y1="49.79" y2="41.01" />
            <line x1="90.51" x2="90.51" y1="49.52" y2="40.73" />
            <rect x="45.11" y="66.86" width="8.5" height="8.5" />
            <rect x="59.63" y="66.86" width="8.5" height="8.5" />
            <rect x="74.15" y="66.86" width="8.5" height="8.5" />
            <rect x="88.67" y="66.86" width="8.5" height="8.5" />
            <rect x="45.11" y="82.96" width="8.5" height="8.5" />
            <rect x="59.63" y="82.96" width="8.5" height="8.5" />
            <rect x="74.15" y="82.96" width="8.5" height="8.5" />
            <rect x="88.67" y="82.96" width="8.5" height="8.5" />
            <line x1="38.4" x2="104.28" y1="57.7" y2="57.7" />
            <polyline points="104.28 53.11 111.6 53.11 111.6 109.27 45.72 109.27 45.72 102.44" />
        </g>
    </SvgIcon>
}