import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core"
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'

export function Mask() {
    const maskCount = useApplicationSelector(state => state.ui.maskCount)
    const styles = useStyles()
    return <Backdrop open={maskCount > 0} className={styles.root}>
        <CircularProgress color='inherit' />
    </Backdrop>
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.snackbar + 1,
    }
}))