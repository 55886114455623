import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { resetPasswordActions } from "./resetPasswordSlice"

export function ClearOnMountAndUnmount() {
    const [searchParams] = useSearchParams()
    const username = searchParams.get("username") || ''
    const resetPasswordCode = searchParams.get("resetPasswordCode") || ''

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetPasswordActions.init({ username, resetPasswordCode }))
        return () => {
            dispatch(resetPasswordActions.destroy())
        }
    }, [dispatch, username, resetPasswordCode])
    return null
}