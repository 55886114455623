import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function BuyerInvoiceListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.66197 0 0 .66197 -231.77 -233.93)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" data-name="组 4074">
            <path d="m381.49 358.89a1.892 1.892 0 0 0-1.89-1.892h-22.708a1.892 1.892 0 0 0-1.892 1.892v27.12l1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892 1.892-1.892 1.892 1.892z" data-name="路径 1884" />
            <path transform="translate(-2.216,-4.432)" d="m364.78 369h-2.523a1.2615 1.2615 0 1 0 0 2.523h1.261a1.2615 1.2615 0 1 1 0 2.523h-2.522" data-name="路径 1885" />
            <line transform="translate(361,363)" y2="2" data-name="直线 871" />
            <line transform="translate(361,370)" y2="1" data-name="直线 872" />
            <line transform="translate(366,367)" x2="5" data-name="直线 873" />
            <line transform="translate(366,371)" x2="5" data-name="直线 874" />
            <line transform="translate(375,367)" x2="3" data-name="直线 875" />
            <line transform="translate(366,363)" x2="5" data-name="直线 876" />
            <line transform="translate(375,363)" x2="3" data-name="直线 877" />
            <line transform="translate(375,371)" x2="3" data-name="直线 878" />
            <line transform="translate(359,376)" x2="19" data-name="直线 879" />
            <line transform="translate(375,380)" x2="3" data-name="直线 880" />
            <line transform="translate(366,380)" x2="5" data-name="直线 881" />
        </g>
    </SvgIcon>
}