import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { Nav } from '../Nav'
import { AccountingIcon } from "./assets/AccountingIcon"
import { AccountsReceivableIcon } from "./assets/AccountsReceivableIcon"
import { BuyerInvoiceListIcon } from "./assets/BuyerInvoiceListIcon"
import { SellerInvoiceListIcon } from "./assets/SellerInvoiceListIcon"

export interface AccountingNavProps {
    id: string,
    openId: string,
    onOpen: (id: string) => void,
    onClose: (id: string) => void,
}

export function AccountingNav(props: AccountingNavProps) {
    const { id, openId, onOpen, onClose } = props
    const handleOpen = useCallback(() => onOpen(id), [id, onOpen])
    const handleClose = useCallback(() => onClose(id), [id, onClose])
    return <Nav
        open={id === openId}
        onOpen={handleOpen}
        onClose={handleClose}
        icon={<AccountingIcon />}
        iconId={'Accounting_Nav'}
        title={<FormattedMessage id="appbar.accounting" />}
        columns={[{
            items: [{
                access: "ACCT.AIS010.VIEW",
                icon: <BuyerInvoiceListIcon />,
                title: <FormattedMessage id="AIS010" />,
                path: '/gr',
            }, {
                access: "ACCT.AIS020.VIEW",
                icon: <SellerInvoiceListIcon />,
                title: <FormattedMessage id="AIS020" />,
                path: '/gi',
            }, {
                access: "ACCT.AIS060.VIEW",
                icon: <AccountsReceivableIcon />,
                title: <FormattedMessage id="AIS060" />,
                path: '/ar',
            },]
        }]}
    />
}