import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useEffect} from "react"
import {FormattedMessage, useIntl} from 'react-intl'
import {ClearOnMountAndUnmount} from "./ClearOnMountAndUnmount"
import {RedirectToHome} from "./RedirectToHome"
import {RedirectToMfaSetting} from './RedirectToMfaSetting'
import {SignInForm} from './SignInForm'
import {VerifyForm} from './VerifyForm'
import {VerifyMfaForm} from './VerifyMfaForm'
import {VerifyMfaRecoveryForm} from './VerifyMfaRecoveryForm'
import pic from './background.png'
import {useLoginSelector} from './loginSlice'
import logo from './logo.svg'

export default function Login() {

    const intl = useIntl()
    useEffect(() => {
        document.title = intl.formatMessage({id: "login.signIn"}) + " - Brivge"
    }, [intl])

    useEffect(() => {
        const frontLayer = document.querySelector('#webapp-lcbm') as HTMLElement
        frontLayer.style.display = 'none'
        return () => {
            frontLayer.style.display = 'block'
        }
    }, [])

    const isSingIn = useLoginSelector(state => state.step === 'sign-in')
    const isVerify = useLoginSelector(state => state.step === 'verify' || state.step === 'processing')
    const isVerifyMfa = useLoginSelector(state => state.step === 'verifyMfa')
    const isVerifyMfaRecovery = useLoginSelector(state => state.step === 'verifyMfaRecovery')

    const styles = useStyles()
    return <>
        <RedirectToHome />
        <RedirectToMfaSetting />
        <ClearOnMountAndUnmount />
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <img src={pic} alt="login" className={styles.pic} />
                </div>
                <div className={styles.right}>
                    <div className={styles.poweredBy}>
                        <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'ArialLight' }}>
                            <FormattedMessage id="login.poweredBy" />
                        </Typography>
                        <img src={logo} alt="logo" className={styles.logo} />
                    </div>
                    {isSingIn && <SignInForm />}
                    {isVerify && <VerifyForm />}
                    {isVerifyMfa && <VerifyMfaForm />}
                    {isVerifyMfaRecovery && <VerifyMfaRecoveryForm />}
                    <div className={styles.copyright}>
                        <Typography variant="body2" color="textSecondary">
                            <FormattedMessage id="login.copyright" />
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(-60deg, #D6D6EE 10%, #E9EFFF 60%, #D6D6EE 90%)',
        overflow: 'auto',
    },
    content: {
        width: '100%',
        height: 675,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    left: {
        flexBasis: 600,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        flexBasis: 440,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pic: {
        width: 760,
        marginLeft: -40,
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '16%',
        margin: theme.spacing(1.5),
    },
    copyright: {
        marginTop: theme.spacing(3),
    },
}))