import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ChangeCancelRequestListIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g fill="none" stroke="currentColor">
            <g transform="matrix(.57779 0 0 .57779 -264.83 -12.226)" data-name="组 4088">
                <g transform="translate(469.74 37.106)" data-name="组 4087">
                    <g transform="translate(-469.74 -37.106)" fill="none" stroke="currentColor" strokeWidth="1.2" data-name="组 4088">
                        <path transform="translate(-2.552)" d="m490.58 29.49a1.452 1.452 0 0 0-1.45-1.451h-3.732a0.632 0.632 0 0 1-0.626-0.478 3.27 3.27 0 0 0-6.435 0 0.637 0.637 0 0 1-0.634 0.48h-3.72a1.452 1.452 0 0 0-1.45 1.451v3.946h18.043z" strokeLinejoin="round" data-name="路径 1941" />
                        <path transform="translate(0,-3.921)" d="m477.84 62.21h-11.417v-27.944h1.727" strokeLinejoin="round" data-name="路径 1942" />
                        <line transform="translate(478.95 27.995)" x2=".119" strokeLinecap="round" strokeMiterlimit="10" data-name="直线 917" />
                        <g transform="translate(469.74 37.106)" strokeLinejoin="round" data-name="组 4087">
                            <path transform="translate(-474.41,-45.87)" d="m489.97 49.119a7.509 7.509 0 0 0-12.367 0" data-name="路径 1943" />
                            <path transform="translate(-481.8,-46.718)" d="m497.49 47.9-0.12 2.065-2.065-0.12" data-name="路径 1944" />
                            <path transform="translate(-474.5 -53.713)" d="m477.83 67.055a7.508 7.508 0 0 0 12.367 0" data-name="路径 1945" />
                            <path transform="translate(-474.41 -53.713)" d="m477.6 69.119 0.12-2.065 2.064 0.12" data-name="路径 1946" />
                            <rect transform="translate(12.763,4.892)" width="6.384" height="6.09" data-name="矩形 14414" />
                            <rect transform="translate(0,4.95)" width="6.384" height="6.09" data-name="矩形 14415" />
                        </g>
                        <path transform="translate(-7.5,-3.921)" d="m484.39 62.21h14.913v-27.944h-1.727" strokeLinejoin="round" data-name="路径 1949" />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
}