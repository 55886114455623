import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { applicationActions, useApplicationSelector } from '../../layouts/Application/applicationSlice'

export function AuthLoader() {
    const notLoaded = useApplicationSelector(state => Boolean(state.auth.token && !state.auth.user))
    const dispatch = useDispatch()
    useEffect(() => {
        let timeoutId: any = -1
        if (notLoaded) {
            timeoutId = setTimeout(() => {
                dispatch(applicationActions.loadAuth())
            }, 100)
        }
        return () => {
            if (notLoaded) {
                clearTimeout(timeoutId)
            }
        }
    }, [notLoaded, dispatch])
    return null
}