import { Input, makeStyles } from "@material-ui/core"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { loginActions, useLoginSelector } from "./loginSlice"

export function PasswordInput() {
    const dispatch = useDispatch()
    const password = useLoginSelector(state => state.password)
    const autoFocus = useLoginSelector(state => state.username !== '' && state.password === '')
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(loginActions.setPassword(e.target.value))
    }, [dispatch])
    const intl = useIntl()
    const styles = useStyles()
    return <Input type="password"
        id="password"
        autoComplete="password"
        value={password}
        autoFocus={autoFocus}
        placeholder={intl.formatMessage({ id: 'login.placeholder.password' })}
        onChange={handleChange}
        fullWidth
        disableUnderline
        className={styles.input}
    />
}

const useStyles = makeStyles(theme => ({
    input: {
        height: 40,
        padding: theme.spacing(2),
        marginTop: 2,
        marginBottom: theme.spacing(1.5),
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.background.paper,
    },
}))