import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Arrays } from '@rithe/utils'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import { effectiveLanguage, Language } from '../../configs/i18n/Language'
import { messages } from '../../configs/i18n/messages'
import announcementApi from '../../services/announcementApi'
import cbdsApi from '../../services/cbdsApi'
import codeCategoryApi from '../../services/codeCategoryApi'
import favouritePageApi from '../../services/favouritePageApi'
import resourceApi from '../../services/resourceApi'
import roleApi from '../../services/roleApi'
import userApi from '../../services/userApi'
import userSettingApi from "../../services/userSettingApi"
import errorToNotification from '../../utils/axios/errorToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import { Notification } from '../../utils/Notification'

interface User {
    id: string,
    name: String,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAccount,
    cbdss: UserCbds[],
}

interface UserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface Role {
    id: string,
    name: string,
    description: string,
    resourceIds: string[],
}

enum ResourceType {
    QUERY = 'QUERY',
    ACTION = 'ACTION',
}

interface Resource {
    id: string,
    code: string,
    type: ResourceType
    category: string,
    module: string,
    action: string,
}

interface UserCompany {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: string,
    cbdsCode: string,
    cbdsName: string,
}

interface UserSetting {
    daSetting: DaSetting,
}

interface DaSetting {
    ruleBasedRecommendation: boolean,
    aiPageRecommendation: boolean,
}

interface CodeCategory {
    codeCategoryId: number,
    codeCategory: string,
    language: string,
    codeValue: number,
    codeName: string,
}

interface Announcement {
    id: string,
    type: AnnouncementType,
    title: string,
    summary: string,
    contentType: string,
    content: string,
    publishAt: number,
    expireAt: number,
}

interface FavouritePage {
    pageId: number
    pageCode: string
    pageName?: string
    userId: number
    cbdsUid: string
    resourceId: number
    resourceCode: string
    url?: string
    createdDate: Date
    updatedDate: Date
}

enum AnnouncementType {
    NEW = 'NEW',
    IMPROVEMENT = 'IMPROVEMENT',
    MAINTENANCE = 'MAINTENANCE',
}

const MAX_NOTIFICATION_SIZE_PREVENT_OOM = 1000

export interface ApplicationState {
    auth: {
        token: string | null,
        companyUid: string | null,
        accessResources: string[] | null,
        user: User | null,
        roles: Role[] | null,
        resources: Resource[] | null,
        userCompanies: UserCompany[] | null,
        favouritePages: FavouritePage[] | null,
    },
    i18n: {
        language: string,
        timezone: string,
        messages: Record<string, Record<string, string>>,
    }
    ui: {
        theme: string,
        maskCount: number,
        notifications: (Notification & { id: string | number, dismissed?: boolean })[],
        notificationHistories: (Notification & { id: string | number })[],
        announcements: Announcement[],
        notFound: {
            base: boolean,
            home: boolean,
            order: boolean,
            logistics: boolean,
            accounting: boolean,
            mt: boolean,
            dashboard: boolean,
            master: boolean,
            privilege: boolean,
            smt: boolean,
            todo: boolean,
            integration: boolean
        }
    },
    setting: {
        userSetting: UserSetting | null,
    }
    cache: {
        codeCategories: CodeCategory[] | null,
    },
    onlineChat: {
        visible: boolean,
        unreadMessageCount: number,
    },
}

const initialState: ApplicationState = {
    auth: {
        token: window.sharedSessionStorage.getItem(`${appConfig.appFullName}:token`) || null,
        companyUid: window.sharedSessionStorage.getItem(`${appConfig.appFullName}:companyUid`) || null,
        accessResources: null,
        user: null,
        roles: null,
        resources: null,
        userCompanies: null,
        favouritePages: null
    },
    i18n: {
        language: localStorage?.getItem(`${appConfig.appFullName}:language`) || Language.DEFAULT,/*effectiveLanguage(Intl.DateTimeFormat().resolvedOptions().locale) || */
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
        messages: messages,
    },
    ui: {
        theme: localStorage?.getItem(`${appConfig.appFullName}:theme`) || 'default',
        maskCount: 0,
        notifications: [],
        notificationHistories: [],
        announcements: [],
        notFound: {
            base: false,
            home: false,
            order: false,
            logistics: false,
            accounting: false,
            mt: false,
            dashboard: false,
            master: false,
            privilege: false,
            smt: false,
            todo: false,
            integration: false
        }
    },
    setting: {
        userSetting: null,
    },
    cache: {
        codeCategories: null,
    },
    onlineChat: {
        visible: false,
        unreadMessageCount: 0,
    },
}

const loadCache = createAsyncThunk<void, void>('application/loadCache', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const getCodeCategories = codeCategoryApi.getCodeCategories(axiosInstance)
    thunk.dispatch(applicationActions.addMask())
    return getCodeCategories.then(response => {
        thunk.dispatch(applicationActions.setCodeCategories(response.data))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const loadAuth = createAsyncThunk<void, void>('application/loadAuth', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    let user: User | null, roles: Role[] | null, resources: Resource[] | null, companies: UserCompany[] | null
    return userApi.current(axiosInstance).then(userResponse => {
        user = userResponse.data
        thunk.dispatch(applicationActions.setUser(user))
        return Promise.all([
            roleApi.batchGet(axiosInstance, user.cbdss.flatMap(cbds => cbds.roleIds)),
            cbdsApi.getCompany(axiosInstance, user.cbdss.map(cbds => cbds.cbdsUid)),
        ])
    }).then(([rolesResponse, companyResponse]) => {
        roles = rolesResponse.data
        thunk.dispatch(applicationActions.setRoles(roles))
        companies = companyResponse.data
        thunk.dispatch(applicationActions.setUserCompanies(companies))
        return resourceApi.batchGet(axiosInstance, Arrays.distinct([
            ...user!.cbdss.flatMap(cbds => cbds.resourceIds),
            ...roles.flatMap(role => role.resourceIds),
        ]))
    }).then(resourcesResponse => {
        resources = resourcesResponse.data
        thunk.dispatch(applicationActions.setResources(resources))
    }).then(() => {
        thunk.dispatch(applicationActions.setCompanyUidIfAbsent(user!.cbdss.find(cbds => cbds.defaultCbds)?.cbdsUid ?? ''))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const loadFavouritePages = createAsyncThunk<void, void>('application/loadFavouritePages', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    let favouritePages: FavouritePage[] | null
    return favouritePageApi.list(axiosInstance).then(response => {
        favouritePages = response.data
        thunk.dispatch(applicationActions.setFavouritePage(favouritePages))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const loadAnnouncements = createAsyncThunk<void, void>('application/loadAnnouncements', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcementsPromise = announcementApi.list(axiosInstance)
    thunk.dispatch(applicationActions.addMask())
    return announcementsPromise.then(response => {
        thunk.dispatch(applicationActions.setAnnouncements(response.data.data))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const loadSetting = createAsyncThunk<void, void>('application/loadSetting', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const userSettingPromise = userSettingApi.get(axiosInstance)
    thunk.dispatch(applicationActions.addMask())
    return userSettingPromise.then(response => {
        thunk.dispatch(applicationActions.setUserSetting(response.data))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

function pushNotificationInternal(state: ApplicationState, notification: Notification & { id: string | number }) {
    state.ui.notifications.push(notification)
    state.ui.notificationHistories.push(notification)
    if (state.ui.notifications.length > MAX_NOTIFICATION_SIZE_PREVENT_OOM) {
        state.ui.notifications.shift()
    }
    if (state.ui.notificationHistories.length > MAX_NOTIFICATION_SIZE_PREVENT_OOM) {
        state.ui.notificationHistories.shift()
    }
}

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        /* AUTH */
        setToken: (state, { payload: token }: PayloadAction<string | null>) => {
            const oldToken = state.auth.token
            if (!oldToken && token) {
                state.auth.token = token
            } else if (oldToken && !token) {
                state.auth.token = token
            } else if (oldToken && token) {
                const oldPayload = JSON.parse(atob(oldToken.split('.')[1]))
                const payload = JSON.parse(atob(token.split('.')[1]))
                if (oldPayload.usr !== payload.usr || Math.abs(oldPayload.iat - payload.iat) > 5) {
                    state.auth.token = token
                }
            }
        },
        setCompanyUid: (state, { payload: companyUid }: PayloadAction<string | null>) => {
            if (state.auth.companyUid !== companyUid) {
                state.auth.companyUid = companyUid
            }
            if (state.auth.user && state.auth.roles && state.auth.resources) {
                const cbds = state.auth.user.cbdss.find(cbds => cbds.cbdsUid === companyUid)
                const cbdsResources = state.auth.resources.filter(resource => cbds?.resourceIds.includes(resource.id))
                const roleResources = state.auth.resources.filter(resource => state.auth.roles!.filter(role => cbds?.roleIds.includes(role.id)).flatMap(role => role.resourceIds).includes(resource.id))
                state.auth.accessResources = [...cbdsResources, ...roleResources].map(resource => resource.code)
            }
            // TODO set timezone
        },
        setCompanyUidIfAbsent: (state, { payload: companyUid }: PayloadAction<string | null>) => {
            if (!state.auth.companyUid) {
                if (state.auth.companyUid !== companyUid) {
                    state.auth.companyUid = companyUid
                }
            }
            if (state.auth.user) {
                if (!state.auth.user.cbdss.some(cbds => cbds.cbdsUid === state.auth.companyUid)) {
                    state.auth.companyUid = state.auth.user!.cbdss.find(cbds => cbds.defaultCbds)?.cbdsUid ?? ''
                }
            }
            if (state.auth.user && state.auth.roles && state.auth.resources) {
                const cbds = state.auth.user.cbdss.find(cbds => cbds.cbdsUid === state.auth.companyUid)
                const cbdsResources = state.auth.resources.filter(resource => cbds?.resourceIds.includes(resource.id))
                const roleResources = state.auth.resources.filter(resource => state.auth.roles!.filter(role => cbds?.roleIds.includes(role.id)).flatMap(role => role.resourceIds).includes(resource.id))
                state.auth.accessResources = [...cbdsResources, ...roleResources].map(resource => resource.code)
            }
            // TODO set timezone
        },
        setUser: (state, { payload: user }: PayloadAction<User | null>) => {
            state.auth.user = user
        },
        setRoles: (state, { payload: roles }: PayloadAction<Role[] | null>) => {
            state.auth.roles = roles
        },
        setResources: (state, { payload: resources }: PayloadAction<Resource[] | null>) => {
            state.auth.resources = resources
        },
        setUserCompanies: (state, { payload: userCompanies }: PayloadAction<UserCompany[] | null>) => {
            state.auth.userCompanies = userCompanies
        },
        /* Favourite Page */
        setFavouritePage: (state, { payload }: PayloadAction<FavouritePage[] | null>) => {
            state.auth.favouritePages = payload
        },
        /* I18N */
        setLanguage: (state, { payload: language }: PayloadAction<string>) => {
            state.i18n.language = language
        },
        setTimezone: (state, { payload: timezone }: PayloadAction<string>) => {
            state.i18n.timezone = timezone
        },
        loadMessage: (state, { payload: messages }: PayloadAction<Record<string, Record<string, string>>>) => {
            for (const language in messages) {
                state.i18n.messages[language] || (state.i18n.messages[language] = {})
                for (const key in messages[language]) {
                    state.i18n.messages[language][key] = messages[language][key]
                }
            }
        },
        /* UI */
        setTheme: (state, { payload: theme }: PayloadAction<string>) => {
            state.ui.theme = theme
        },
        addMask: (state) => {
            state.ui.maskCount++
        },
        removeMask: (state) => {
            state.ui.maskCount = Math.max(0, state.ui.maskCount - 1)
        },
        pushNotification: (state, { payload: notification }: PayloadAction<Notification>) => {
            // NOT PURE
            pushNotificationInternal(state, { id: Date.now() + Math.random(), ...notification })
        },
        pushInfo: (state, { payload: notification }: PayloadAction<Omit<Notification, 'type'>>) => {
            // NOT PURE
            pushNotificationInternal(state, { id: Date.now() + Math.random(), type: 'info', ...notification })
        },
        pushSuccess: (state, { payload: notification }: PayloadAction<Omit<Notification, 'type'>>) => {
            // NOT PURE
            pushNotificationInternal(state, { id: Date.now() + Math.random(), type: 'success', ...notification })
        },
        pushWarning: (state, { payload: notification }: PayloadAction<Omit<Notification, 'type'>>) => {
            // NOT PURE
            pushNotificationInternal(state, { id: Date.now() + Math.random(), type: 'warning', ...notification })
        },
        pushError: (state, { payload: notification }: PayloadAction<Omit<Notification, 'type'>>) => {
            // NOT PURE
            pushNotificationInternal(state, { id: Date.now() + Math.random(), type: 'error', ...notification })
        },
        closeNotification: (state, { payload: id }: PayloadAction<string | number>) => {
            state.ui.notifications.filter(n => n.id === id).forEach(n => n.dismissed = true)
        },
        removeNotification: (state, { payload: id }: PayloadAction<string | number>) => {
            state.ui.notifications = state.ui.notifications.filter(n => n.id !== id)
        },
        pruningNotificationHistories: (state, { payload: remaining }: PayloadAction<number>) => {
            const length = state.ui.notificationHistories.length
            if (length > remaining) {
                state.ui.notificationHistories.splice(0, length - remaining)
            }
        },
        setAnnouncements: (state, { payload: announcements }: PayloadAction<Announcement[]>) => {
            state.ui.announcements = announcements
        },
        notFound: (state, { payload: { scope, notFound } }: PayloadAction<{ scope: keyof ApplicationState['ui']['notFound'], notFound: boolean }>) => {
            state.ui.notFound[scope] = notFound
        },
        /* Setting */
        setUserSetting: (state, { payload }: PayloadAction<UserSetting>) => {
            state.setting.userSetting = payload
        },
        /* Cache */
        setCodeCategories: (state, { payload: codeCategories }: PayloadAction<CodeCategory[] | null>) => {
            state.cache.codeCategories = codeCategories
            codeCategories?.forEach(({ codeCategory, language, codeValue, codeName }) => {
                if (codeCategory && language && codeValue !== null && codeValue !== undefined && codeName) {
                    const lang = effectiveLanguage(language)
                    if (!state.i18n.messages[lang]) state.i18n.messages[lang] = {}
                    state.i18n.messages[lang][`${codeCategory}_${codeValue}`] = codeName
                }
            })
        },
        /* Online Chat */
        toggleOnlineChat: (state) => {
            state.onlineChat.visible = !state.onlineChat.visible
        },
        setUnreadMessageCount: (state, { payload: unreadMessageCount }: PayloadAction<number>) => {
            state.onlineChat.unreadMessageCount = unreadMessageCount
        },
        /* Other */
        logout: (state) => {
            state.auth.token = null
            state.auth.companyUid = null
            state.auth.accessResources = null
            state.auth.user = null
            state.auth.roles = null
            state.auth.resources = null
            state.auth.userCompanies = null
            state.auth.favouritePages = null
        }
    }
})

export const applicationActions = {
    ...applicationSlice.actions,
    loadAuth,
    loadFavouritePages,
    loadSetting,
    loadAnnouncements,
    loadCache,
}

export function selectApplicationState(state: any): ApplicationState {
    return state[appConfig.baseFullName][applicationSlice.name]
}

export function useApplicationSelector<R>(selector: (state: ApplicationState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectApplicationState(state)), equalityFn)
}