import { Portal } from "@material-ui/core"
import { ReactNode, useRef } from 'react'
import { useApplicationSelector } from '../../Application/applicationSlice'
import { NavButton } from '../NavButton/NavButton'
import { NavMenu } from '../NavMenu/NavMenu'

interface Item {
    category?: ReactNode,
    open?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
    icon?: ReactNode,
    title: ReactNode,
    access?: string | string[],
    power?: boolean,
    path: string,
}

interface Column {
    name?: ReactNode,
    items: Item[],
}

export interface NavProps {
    icon?: ReactNode,
    title: ReactNode,
    columns: Column[],
    open: boolean,
    iconId?: string,
    onOpen: () => void,
    onClose: () => void,
    menuPlacement?: 'bottom-start' | 'bottom' | 'bottom-end',
}

export function Nav(props: NavProps) {
    const {
        icon,
        title,
        columns,
        open,
        onOpen,
        onClose,
        iconId,
        menuPlacement = 'bottom-start',
    } = props

    const hidden = useApplicationSelector(state => {
        const accessResources = state.auth.accessResources ?? []
        const poweruser = state.auth.user?.powerUser ?? false
        return columns.every(({ items }) => items.every(({ access, power }) => {
            return (access && !accessResources.some(acc => typeof access === 'string' ? access === acc : access.includes(acc)))
                || (power && !poweruser)
        }))
    })
    const ref = useRef<HTMLButtonElement>(null)

    return hidden ? null : <>
        <NavButton
            ref={ref}
            icon={icon}
            title={title}
            open={open}
            onOpen={onOpen}
            iconId={iconId}
        />
        <Portal>
            <NavMenu
                anchorEl={ref.current}
                title={title}
                columns={columns}
                open={open}
                onClose={onClose}
                menuPlacement={menuPlacement}
            />
        </Portal>
    </>
}
