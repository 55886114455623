import { Button, makeStyles, Typography } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'

export interface NavButtonProps {
    icon?: ReactNode,
    iconId?: string,
    title: ReactNode,
    open: boolean,
    onOpen: () => void,
    menuPlacement?: 'bottom-start' | 'bottom' | 'bottom-end',
}

export const NavButton = forwardRef<HTMLButtonElement, NavButtonProps>((props, ref) => {
    const {
        icon,
        title,
        open,
        onOpen,
        iconId,
    } = props

    const classes = useStyles()
    return <Button
        ref={ref}
        role="button"
        onClick={onOpen}
        className={clsx(classes.root, open && classes.open)}
    >
        <>
            {icon && <span className={classes.icon}>{icon}</span>}
            <Typography variant="subtitle2" className={classes.label}>{title}</Typography>
            {open ? <ExpandLess className={classes.expand} id={iconId ? "Expand_Less_" + iconId : undefined} /> : <ExpandMore className={classes.expand} id={iconId ? "Expand_More_" + iconId : undefined} />}
        </>
    </Button>
})


const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.light,
        height: 44,
        flex: '0 0 auto',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
        },
    },
    open: {
        color: theme.palette.secondary.light,
    },
    icon: {
        display: 'flex',
        width: 24,
        height: 24,
        transform: 'scale(0.8)',
        fontWeight: 'bold',
    },
    label: {
        textTransform: 'none',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
    },
    expand: {
        transform: 'scale(0.6)',
    },
}))
