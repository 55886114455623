import { SvgIcon, SvgIconProps } from "@material-ui/core"
import React from "react"

export function ManagementToolIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g data-name="组 75" transform="matrix(.95366 0 0 .95366 -145.783 -138.144)" strokeWidth="1.573" fill="none" stroke="currentColor" strokeLinejoin="round">
            <path data-name="路径 14" d="M175.884 158.594v-2.3a.519.519 0 0 0-.518-.518h-1.326a.523.523 0 0 1-.5-.4 8.289 8.289 0 0 0-.908-2.183.52.52 0 0 1 .071-.639l.846-.846a.562.562 0 0 0 .1-.829l-1.63-1.63a.519.519 0 0 0-.733 0l-.94.94a.523.523 0 0 1-.641.072 8.3 8.3 0 0 0-2.183-.907.52.52 0 0 1-.4-.5v-1.194c0-.421-.232-.653-.518-.653h-2.3a.519.519 0 0 0-.518.518v1.326a.523.523 0 0 1-.4.5 8.3 8.3 0 0 0-2.184.907.52.52 0 0 1-.639-.071l-.846-.846a.562.562 0 0 0-.828-.1l-1.63 1.63a.519.519 0 0 0 0 .733l.94.94a.524.524 0 0 1 .072.641 8.3 8.3 0 0 0-.907 2.183.52.52 0 0 1-.5.4h-1.194c-.421 0-.653.232-.653.518v2.3a.519.519 0 0 0 .518.518h1.326a.523.523 0 0 1 .5.4 8.3 8.3 0 0 0 .907 2.184.52.52 0 0 1-.071.639l-.846.846a.562.562 0 0 0-.1.828l1.63 1.63a.519.519 0 0 0 .733 0l.94-.94a.523.523 0 0 1 .64-.072 8.3 8.3 0 0 0 2.184.907.52.52 0 0 1 .4.5v1.292c0 .37.187.555.418.555h2.405a.519.519 0 0 0 .518-.518v-1.315a.523.523 0 0 1 .4-.5 8.3 8.3 0 0 0 2.183-.907.52.52 0 0 1 .639.07l.846.846a.562.562 0 0 0 .829.1l1.63-1.63a.519.519 0 0 0 0-.733l-.94-.94a.523.523 0 0 1-.073-.641 8.29 8.29 0 0 0 .908-2.183.52.52 0 0 1 .5-.4h1.194c.417-.009.649-.242.649-.528z" />
            <circle data-name="椭圆 2" cx="4.603" cy="4.603" r="4.603" transform="translate(160.754 152.861)" strokeLinecap="round" />
        </g>
    </SvgIcon>
}