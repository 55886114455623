import { SvgIcon, SvgIconProps } from "@material-ui/core"

export function CargoTrackingIcon(props: SvgIconProps) {
    return <SvgIcon {...props}>
        <g transform="matrix(.1971 0 0 .19711 -3.3696 -2.7053)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <polygon points="48 34.68 49.63 32.79 51.02 34.68 52.4 32.79 52.4 27.75 41.32 27.75 41.32 32.55 42.7 34.68 44.09 32.79 45.4 34.68 46.86 32.79" />
            <line x1="53.79" x2="62.55" y1="50.61" y2="50.61" />
            <line x1="53.82" x2="62.58" y1="53.22" y2="53.22" />
            <polygon points="80.81 46.76 69.03 46.76 69.03 40.91 76.83 40.91" />
            <line x1="71.24" x2="71.24" y1="47.29" y2="49.92" />
            <polyline points="43.96 57.99 65.56 57.99 65.56 27.49 28.85 27.49 28.85 57.99 33.59 57.99" />
            <path d="m43.96 57.99c-0.01 2.86-2.33 5.17-5.19 5.17s-5.18-2.31-5.19-5.17" />
            <path d="m43.96 57.99v-0.02c0-2.87-2.32-5.19-5.19-5.19s-5.19 2.32-5.19 5.19v0.02" />
            <path d="m41.44 57.99c-0.01 1.46-1.2 2.65-2.67 2.65s-2.65-1.18-2.67-2.65" />
            <path d="m41.44 57.99v-0.02c0-1.47-1.19-2.67-2.67-2.67s-2.67 1.19-2.67 2.67v0.02" />
            <polyline points="79.78 57.99 85.65 57.99 85.65 47.15 78.5 36.59 65.56 36.59 65.56 57.99 69.41 57.99" />
            <path d="m69.41 57.99v0.11c0 2.87 2.32 5.19 5.19 5.19s5.19-2.32 5.19-5.19v-0.11" />
            <path d="m69.41 57.99c0.06-2.81 2.36-5.08 5.18-5.08s5.12 2.26 5.18 5.08" />
            <path d="m71.94 57.99c0.06-1.42 1.22-2.56 2.66-2.56s2.6 1.14 2.66 2.56" />
            <path d="m71.94 57.99s-0.01 0.07-0.01 0.11c0 1.47 1.19 2.67 2.67 2.67s2.67-1.19 2.67-2.67c0-0.04 0-0.07-0.01-0.11" />
            <path d="m45.16 86.43h1.03" />
            <path d="m110.79 86.43c9.63 0 17.43-7.81 17.43-17.43 0-9.63-7.81-17.43-17.43-17.43h-25.12" />
            <path d="m46.18 86.43c-9.63 0-17.43 7.81-17.43 17.43 0 9.63 7.81 17.43 17.43 17.43h65.64" />
            <line x1="110.79" x2="46.18" y1="86.43" y2="86.43" />
            <circle cx="111.18" cy="106.77" r="3.6" />
            <path d="m119.53 106.77c0-4.58-3.71-8.29-8.29-8.29s-8.29 3.71-8.29 8.29c0 1.92 0.66 3.67 1.75 5.08l5.54 9.09c0.46 0.75 1.55 0.75 2.01 0l5.54-9.09c1.09-1.4 1.75-3.16 1.75-5.08z" />
            <line x1="84.5" x2="82" y1="50" y2="50" />
        </g>
    </SvgIcon>
}