import { Checkbox, FormControlLabel, Typography } from "@material-ui/core"
import { useCallback, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { loginActions, useLoginSelector } from "./loginSlice"

export function RememberMe() {
    const dispatch = useDispatch()
    const rememberMe = useLoginSelector(state => state.rememberMe)
    const handleClick = useCallback(() => {
        dispatch(loginActions.toggleRememberMe())
    }, [dispatch])
    useEffect(() => {
        return () => {
            dispatch(loginActions.remember())
        }
    }, [dispatch])

    return <FormControlLabel
        control={<Checkbox
            checked={rememberMe}
            onClick={handleClick}
            style={{ transform: 'scale(0.75)' }}
        />}
        label={<Typography variant="body2" color="textSecondary">
            <FormattedMessage id="login.rememberMe" />
        </Typography>}
        style={{ marginLeft: -2 }}
    />
}